export default {
    name: "medias",
    resources: {
        name: 'Media |||| Medias',
        forcedCaseName: 'Media |||| Medias',
        fields: {
            src: "Url",
            title: "Titolo",
            extension: "Estensione",
            size: "Dimensioni",
            mediaType: "Tipo MIME",
            updatedAt: "Data di creazione"
        },
    },
    global: {
        menu: {
            name: 'Media',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Media "%{title}"',
        },
        action: {
        },
        filters: {
            size: 'Dimensioni',
            size_lt_1_mb: '<= 1MB',
            size_gt_1_mb_lt_5_mb: '> 1MB e <= 5MB',
            size_gt_5_mb: '> 5MB',
            mediaType: "Tipo MIME",
            any: "Qualsiasi",
            application: "Applicazione",
            pdf: "PDF",
            file: "File",
            zip: "ZIP",
            doc: "DOC",
            docx: "DOCX",
            xls: "XLS",
            xlsx: "XLSX",
            ppt: "PPT",
            pptx: "PPTX",
            audio: "Audio",
            audio_mpeg: "Audio MPEG",
            wav: "WAV",
            image: "Immagine",
            gif: "GIF",
            jpeg: "JPEG",
            png: "PNG",
            text: "Testo",
            html: "HTML",
            txt: "TXT",
            video: "Video",
            video_mpeg: "Video MPEG",
            avi: "AVI",
        }
    },
};