import companies from './companies';

export default {
    name: 'companyaddresses',
    resources: {
        name: 'Company Address |||| Company Addresses',
        forcedCaseName: 'Company Address |||| Company Addresses',
        fields: {
            name: 'Name',
            addressLine1: 'Address Line 1',
            addressLine2: 'Address Line 2',
            addressLine3: 'Address Line 3',
            country: 'Country',
            postalCode: 'Postal Code',
            addressLevel1: 'Address Level 1',
            addressLevel2: 'Address Level 2',
            addressLevel3: 'Address Level 3',
            lat: 'Latitude',
            lng: 'Longitude',
            placeId: 'Location',
            updatedAt: 'Updated At',
            company: 'Company',
            companyName: companies.resources.fields.name,
        },
    },
    global: {
        menu: {
            name: 'Company Addresses',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Company Address "%{title}"',
        },
        action: {
        },
    },
};