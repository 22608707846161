import { useEffect, useState } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { I18nProvider } from "ra-core";
import polyglotI18nProvider from 'ra-i18n-polyglot';

// domain translations
import { default as englishDomainMessages } from "./translations/en";
import { default as italianDomainMessages } from "./translations/it";

import {
    DataProviderContext,
    I18nContextProvider,
    StoreContextProvider,
    memoryStore,
    Notification,
} from 'react-admin';

import MainContent from './MainContent';
import { dataProvider } from './providers';
import { lightTheme, darkTheme } from './layout';

const translations: any = {
    it: { ...italianDomainMessages },
    en: { ...englishDomainMessages },
};

const defaultStore = memoryStore();

const App = () => {
    const [i18nProvider, setI18nProvider] = useState<I18nProvider>({} as I18nProvider);
    const [setupCompleted, setSetupCompleted] = useState<boolean>(false);

    const _theme = createTheme(lightTheme);

    useEffect(() => {
        const i18nProvider = polyglotI18nProvider(
            locale => translations[locale] ? translations[locale] : translations.it,
            'it',//resolveBrowserLocale(),
            { allowMissing: true }
        );

        setI18nProvider(i18nProvider);
        setSetupCompleted(true);

        return () => { };
    }, [])

    if (setupCompleted) {
        return (
            <DataProviderContext.Provider value={dataProvider}>
                <StoreContextProvider value={defaultStore}>
                    <I18nContextProvider value={i18nProvider}>
                        <ThemeProvider theme={_theme}>
                            <MainContent />
                            <Notification />
                        </ThemeProvider>
                    </I18nContextProvider>
                </StoreContextProvider>
            </DataProviderContext.Provider>
        )
    }
    else {
        return null
    }
};

export default App;