import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from '@mui/material';

import { Utils } from 'admin/core';

import {
    TextInput,
    NumberInput,
    ArrayInput,
    SimpleFormIterator,
    required,
    FormDataConsumer,
    useTranslate,
    number,
    minValue,
    maxValue,
    FormDataConsumerRenderParams,
    InputProps,
} from 'react-admin';

import { InvoiceSummaryField } from '../fields';
import { Invoice, InvoiceLine } from 'admin/types';

const CustomForm = (props: FormDataConsumerRenderParams) => {
    const { getValues, setValue } = useFormContext();
    const {
        formData, // The whole form data
        scopedFormData, // The data for this item of the ArrayInput
        getSource, // A function to get the valid source inside an ArrayInput
        ...rest
    } = props;

    const translate = useTranslate();
    const getLabel = (field) => translate(`resources.invoicelines.fields.${field}`);

    const record = getValues(getSource("")) as InvoiceLine;

    useEffect(() => {
        if (record) {
            if (record.price && record.price >= 0 && record.quantity && record.quantity >= 0) {
                setValue(getSource("amount"), record.price * record.quantity);
            }

            if (record.amount && record.vatRate && record.vatRate >= 0 && record.vatRate <= 100) {
                setValue(getSource("vatAmount"), record.amount * record.vatRate / 100);
            }
        }
    }, [record.price, record.quantity, record.vatRate])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
                <TextInput {...rest} source={getSource("description")} label={getLabel("description")} validate={[required()]} fullWidth />
            </Grid>
            <Grid item xs={12} sm={1}>
                <NumberInput {...rest} source={getSource("price")} label={getLabel("price")} validate={[required()]} defaultValue={0} fullWidth />
            </Grid>
            <Grid item xs={12} sm={1}>
                <NumberInput {...rest} source={getSource("quantity")} label={getLabel("quantity")} validate={[required(), number(), minValue(1)]} defaultValue={1} min={1} step={1} fullWidth />
            </Grid>
            <Grid item xs={12} sm={1}>
                <NumberInput {...rest} source={getSource("vatRate")} label={getLabel("vatRate")} validate={[required(), number(), minValue(0), maxValue(100)]} defaultValue={0} min={0} max={100} step={1} fullWidth />
            </Grid>
            <Grid item xs={12} sm={1}>
                <NumberInput {...rest} source={getSource("vatAmount")} label={getLabel("vatAmount")} disabled InputProps={{ readOnly: true }} fullWidth />
            </Grid>
            <Grid item xs={12} sm={1}>
                <NumberInput {...rest} source={getSource("amount")} label={getLabel("amount")} disabled InputProps={{ readOnly: true }} fullWidth />
            </Grid>
        </Grid>
    )
};

export const InvoiceLinesInput = (props: InputProps) => {
    const { getValues, setValue, watch } = useFormContext();
    const watchSource = watch(props.source)

    useEffect(() => {
        const invoice = getValues() as Invoice;
        if (!invoice.invoiceLines || invoice.invoiceLines.length === 0) {
            setValue(props.source, [{
                description: "",
                price: 0,
                quantity: 1,
                vatRate: 0,
            }])
        }
    }, [])

    useEffect(() => {
        const invoice = getValues() as Invoice;

        if (!invoice.invoiceLines || invoice.invoiceLines.length === 0) {
            setValue(props.source, [{
                description: "",
                price: 0,
                quantity: 1,
                vatRate: 0,
                vatAmount: 0,
            }])
        }
        else {
            let totAmount = 0;
            const invoiceLines = invoice.invoiceLines.filter(x => !Utils.IsEmpty(x));

            if (invoiceLines && invoiceLines.length > 0) {
                const amount = invoiceLines.reduce((a, b) => +a + +(b.amount || 0), 0);
                const vatAmount = invoiceLines.reduce((a, b) => +a + +(b.vatAmount || 0), 0);
                totAmount = amount + vatAmount;
            }

            totAmount += invoice?.stampAmount && invoice.stampAmount > 0 ? invoice.stampAmount : 0;

            setValue("amount", totAmount)
        }
    }, [JSON.stringify(watchSource)])

    if (!getValues()) return null;

    return (
        <Grid container>
            <Grid item xs={12}>
                <ArrayInput {...props} fullWidth>
                    <SimpleFormIterator inline fullWidth>
                        <FormDataConsumer>
                            {(formDataProps) => <CustomForm {...formDataProps} />}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </Grid>
            <Grid item xs={12}>
                <InvoiceSummaryField invoice={getValues() as Invoice} />
            </Grid>
        </Grid>
    );
};

export default InvoiceLinesInput;