export default {
    name: 'invoicesubmissions',
    resources: {
        name: 'Invio della fattura |||| Invio delle fatture',
        forcedCaseName: 'Invio della fattura |||| Invio delle fatture',
        fields: {
            invoiceId: 'Fattura',
            sendingSequence: 'Sequenza di invio',
            outcome: 'Risultato',
            xmlpath: 'Xml Path',
            sentAt: 'Inviato il',
            updatedAt: 'Aggiornato il',
            invoices: 'Fattura',
        },
    },
    global: {
        menu: {
            name: 'Invio delle fatture',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Invio della fattura "%{title}"',
        },
        action: {
        },
    },
};