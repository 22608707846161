import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { useTranslate } from 'react-admin';

const autocompleteService = { current: null };
const placeService = { current: null };

var marker: any | null = null;

export interface AutoCompleteGMapsProps {
    mapInstance: any;
    mapApi: any;
    onSelectedPlace: (place: google.maps.places.PlaceResult | null) => void;
}

export default function AutoCompleteGMaps(props: AutoCompleteGMapsProps) {
    const {
        mapInstance = null,
        mapApi = null,
        onSelectedPlace = () => { },
        ...rest
    } = props;

    const [mapApiLoaded, setMapApiLoaded] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<google.maps.places.AutocompletePrediction | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<readonly google.maps.places.AutocompletePrediction[]>([]);
    const translate = useTranslate();

    React.useEffect(() => {
        if (mapInstance && mapApi)
            setMapApiLoaded(true)
    }, [mapInstance, mapApi]);

    const fetch = React.useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results?: readonly google.maps.places.AutocompletePrediction[]) => void,
                ) => {
                    (autocompleteService.current as any).getPlacePredictions(
                        request,
                        callback,
                    );
                },
                400,
            ),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (
                window as any
            ).google.maps.places.AutocompleteService();
        }

        if (!placeService.current && (window as any).google) {
            placeService.current = new (window as any).google.maps.places.PlacesService(mapInstance);
        }

        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        if (mapApiLoaded) {
            fetch({ input: inputValue }, (results?: readonly google.maps.places.AutocompletePrediction[]) => {
                if (active) {
                    let newOptions: readonly google.maps.places.AutocompletePrediction[] = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            });
        }

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const onSelected = (newPlace: google.maps.places.AutocompletePrediction | null) => {
        setOptions(newPlace ? [newPlace, ...options] : options);
        setValue(newPlace);
        onSelectAddress(newPlace);
    }

    function onSelectAddress(newPlace: google.maps.places.AutocompletePrediction | null) {
        if (newPlace) {
            (placeService.current as any).getDetails({ placeId: newPlace.place_id }, function (place: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) {
                if (status == google.maps.places.PlacesServiceStatus.OK && place) {
                    if (marker)
                        marker.setMap(null);

                    marker = new mapApi.Marker({
                        position: place?.geometry?.location
                    });

                    marker.setMap(mapInstance);

                    mapInstance.setCenter(place?.geometry?.location);
                    mapInstance.setZoom(16);

                    onSelectedPlace(place);
                }
            });
        }
    }

    return (
        <Autocomplete
            id="google-map-demo"
            fullWidth
            sx={{ marginBottom: theme => theme.spacing(1) }}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText={translate("ra.google.maps.noLocations")}
            onChange={(event: any, newValue: google.maps.places.AutocompletePrediction | null) => onSelected(newValue)}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label={translate("ra.google.maps.typeLocation")} fullWidth />
            )}
            renderOption={(props, option) => {
                const matches =
                    option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [match.offset, match.offset + match.length]),
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography variant="body2" color="text.secondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}