export default {
    name: 'invoices',
    resources: {
        name: 'Invoice |||| Invoices',
        forcedCaseName: 'Invoice |||| Invoices',
        fields: {
            number: 'Number',
            emissionDate: 'Emission Date',
            fiscalYear: 'Fiscal Year',
            amount: 'Amount',
            companyId: 'Company Id',
            invoiceTypeId: 'Invoice Type Id',
            paymentTypeId: 'Payment Type Id',
            bankAccount: 'Bank Account',
            stampAmount: 'Stamp Amount',
            otherDetails: 'Other Details',
            updatedAt: 'Updated At',
            company: 'Company',
            invoiceType: 'Invoice Type',
            paymentType: 'Payment Type',
            invoiceLines: 'Invoice Lines',
            invoiceSubmissions: 'Invoice Submissions',
        },
    },
    global: {
        menu: {
            name: 'Invoices',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Invoice "%{title}"',
        },
        action: {
            copyBankAccount: 'Copia IBAN',
            pdf: {
                label: 'Genera PDF',
                processing: 'Creazione fattura in corso',
                failed: 'Documento non generato o danneggiato'
            },
            xml: {
                label: 'Invia Fattura Elettronica',
                confirm: 'Conferma invio',
                confirmContent: 'Confermi l\'invio della Fattura Elettronica?',
                confirmPending: 'Conferma ricezione',
                confirmPendingContent: 'Confermi la ricezione della Fattura Elettronica?',
                processing: 'Invio Fattura Elettronica in corso',
                processingPending: 'Conferma ricezione in corso',
                success: 'Invio Fattura Elettronica completato',
                failed: 'Invio Fattura Elettronica fallito'
            },
            sendXml: {
                success: 'Fattura Elettronica inviata',
                pending: 'Fattura Elettronica in elaborazione',
                failed: 'Invia Fattura Elettronica',
                null: 'Invia Fattura Elettronica'
            }
        },
    },
};