import companies from './companies';

export default {
    name: 'companyaddresses',
    resources: {
        name: 'Indirizzo |||| Indirizzi',
        forcedCaseName: 'Indirizzo |||| Indirizzi',
        fields: {
            name: 'Nome',
            addressLine1: 'Indirizzo 1',
            addressLine2: 'Indirizzo 2',
            addressLine3: 'indirizzo 3',
            country: 'Stato',
            postalCode: 'Cap',
            addressLevel1: 'Regione',
            addressLevel2: 'Provincia',
            addressLevel3: 'Comune',
            lat: 'Latitudine',
            lng: 'Longitudine',
            placeId: 'Posizione',
            updatedAt: 'Aggiornato il',
            company: 'Azienda',
            companyName: companies.resources.fields.name,
        },
    },
    global: {
        menu: {
            name: 'Indirizzi',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Indirizzo "%{title}"',
        },
        action: {
        },
    },
};