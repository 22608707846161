import { memo } from 'react';

import { useTheme } from '@mui/material/styles';

import {
    Box,
    Link,
    Stack,
    Typography,
} from '@mui/material';

import { useTranslate, useStore } from 'react-admin';

import { AppProps } from './index';
import { AppInfo, AppStatusType } from 'publicapp/types';

export interface AppIconProps extends AppProps {
    iconUrl: string;
    label: string;
    toLink?: string;
    inTaskBar?: boolean;
    mimized?: boolean;
    selected?: boolean;
}

const AppIcon = (props: AppIconProps) => {
    const {
        name,
        iconUrl,
        label,
        toLink,
        inTaskBar = false,
        mimized = false,
        selected = false,
    } = props;

    const [applist, setApplist] = useStore<AppInfo[]>('applist');
    const [app, setApp] = useStore<AppInfo>(`app.${name}`);

    const theme = useTheme();
    const translate = useTranslate();

    const handleClick = () => {
        if (app.status !== AppStatusType.opened) {
            app.status = AppStatusType.opened;
            setApp(app);
            setApplist(applist.map(_app => _app.name === name ? app : _app));
        }
    };

    if (toLink) {
        return (
            <Link href={toLink} target='_blank' underline="none">
                <Stack
                    spacing={.5}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        margin: theme.spacing(1),
                        padding: theme.spacing(1),
                        width: theme.spacing(14),
                        fontSize: '1em',
                        transition: 'all ease-in-out .2s',
                        borderRadius: theme.spacing(1),
                        '&:hover': {
                            backgroundColor: 'rgba(255,255,255,.12)',
                        }
                    }}>
                    <Box display='flex' justifyContent="center" alignItems="center" >
                        <img src={iconUrl} width={theme.spacing(6)} />
                    </Box>
                    <Typography variant="body1" gutterBottom color={theme => theme.palette.common.white} textAlign='center'>
                        {translate(label)}
                    </Typography>
                </Stack>
            </Link>
        )
    }
    else {
        return (
            <Stack
                id={`${name}_ico`}
                spacing={.5}
                justifyContent="center"
                alignItems="center"
                onClick={handleClick}
                sx={{
                    margin: inTaskBar ? `0 ${theme.spacing(1)}` : theme.spacing(1),
                    padding: inTaskBar ? theme.spacing(.5) : theme.spacing(1),
                    width: inTaskBar ? null : theme.spacing(14),
                    fontSize: '1em',
                    transition: 'all ease-in-out .2s',
                    cursor: 'pointer',
                    borderRadius: theme.spacing(1),
                    backgroundColor: inTaskBar && selected ? 'rgba(255, 255, 255, .67)' : null,
                    '&:hover': {
                        backgroundColor: inTaskBar ? 'rgba(255, 255, 255, .67)' : 'rgba(255,255,255,.12)',
                    },
                    '&:after': {
                        content: '""',
                        position: 'absolute',
                        display: 'block',
                        bottom: 3,
                        width: theme.spacing(1.25),
                        height: theme.spacing(.4),
                        borderRadius: theme.spacing(1),
                        background: '#858585',
                        transition: 'all .2s ease-in -out',
                    },
                }}>
                <Box display='flex' justifyContent="center" alignItems="center" >
                    <img src={iconUrl} width={theme.spacing(inTaskBar ? 3.5 : 6)} />
                </Box>
                {
                    !inTaskBar &&
                    <Typography variant="body1" gutterBottom color={theme => theme.palette.common.white} textAlign='center'>
                        {translate(label)}
                    </Typography>
                }
            </Stack>
        )
    }
}

export default AppIcon;