import { Children, ReactNode } from 'react';
import PropTypes from 'prop-types';

import { Box, ButtonProps } from '@mui/material';

import { Utils } from 'admin/core';

export interface ListRowActionsProps {
    children: ReactNode | ReactNode[];
}

const ListRowActions = (props: ListRowActionsProps) => {
    const {
        children = [],
    } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                borderRadius: 1,
            }}
        >
            {Children.map(children, (child: any, index) => {
                const commonProps = {
                    key: index,
                    context: 'button'
                }

                const commonStyle = { padding: 0, minWidth: theme => theme.spacing(4) } as ButtonProps;
                return Utils.CreateOrCloneElement(child, { ...commonProps, ...child.props, sx: { ...commonStyle } })
            })}
        </Box>
    )
};

ListRowActions.propTypes = {
    children: PropTypes.node.isRequired,
};

ListRowActions.displayName = 'ListRowActions';

export default ListRowActions;