import { ReactNode } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeStyles } from "@mui/styles";
import { Typography } from '@mui/material';
import { Variant } from "@mui/material/styles/createTypography";

import {
    useTranslate,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { Utils } from 'admin/core';

const useStyles = makeStyles({
    root: {},
    title: {
        backgroundColor: 'rgba(248, 246, 249, 1)',
        border: '1px solid rgba(204, 204, 204, 1)',
        borderLeft: 'none',
        borderRight: 'none',
        fontSize: 18,
        height: 60,
        position: 'relative',
        left: -45,
        width: 'calc(100% + 90px)',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 15,
        fontWeight: '400',
        "&.disabled": {
            opacity: .7,
        },
        "&.inTab": {
            left: -31,
            width: 'calc(100% + 62px)',
        }
    }
});

export interface GroupedLayoutProps {
    children?: ReactNode | ReactNode[] | null;
    disabled?: boolean;
    title?: string;
    className?: string;
    variant?: Variant;
    inTab?: boolean;
}

const GroupedLayout = (props: GroupedLayoutProps) => {
    const {
        title = "",
        variant = "body1",
        className,
        disabled,
        inTab,
        children
    } = props;

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <>
            <div className={classnames(classes.title, className, disabled ? 'disabled' : '', inTab ? 'inTab' : '')}>
                <Typography component={"span"} variant={variant}>
                    {translate(title)}
                </Typography>
            </div>
            {Utils.CreateOrCloneElement(children)}
        </>
    );
};

GroupedLayout.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.string,
    inTab: PropTypes.string,
};

export default GroupedLayout;