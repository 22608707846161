import PropTypes from 'prop-types';

import { makeStyles } from "@mui/styles";
import classnames from 'classnames';
import { CircularProgress, Fab, Theme, Typography, } from '@mui/material';
import { Variant } from "@mui/material/styles/createTypography";
import ExtensionIcon from '@mui/icons-material/Extension';
import { green } from '@mui/material/colors';

import {
    useTranslate,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

const useStyles = makeStyles<Theme>(theme => (
    {
        message: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0.8,
            margin: '0 1em',
            color: theme.palette.mode === 'light' ? 'inherit' : theme.palette.text.primary,
        },
        wrapper: {
            width: theme.spacing(7),
            margin: theme.spacing(4),
            position: 'relative',
        },
        fabProgress: {
            color: green[600],
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                height: '100%',
            },
            [theme.breakpoints.down('lg')]: {
                height: '100vh',
                marginTop: '-3em',
            },
        },
        fullscreen: {
            '& $message': {
                backgroundColor: '#fff',
                minWidth: '20vw',
                margin: 'auto',
                opacity: 1,
                borderRadius: '0.5em',
            },
            position: 'fixed',
            zIndex: 999999999,
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: '#0000008c',
        },
        icon: {
            width: '9em',
            height: '9em',
        },
    }
));

export interface LoadingPageProps {
    className?: string;
    loadingPrimary?: string;
    loadingSecondary?: string;
    icon?: JSX.Element;
    iconColor?: 'inherit' | 'primary' | 'secondary' | 'default' | 'success' | 'error' | 'info' | 'warning';
    variantPrimary?: Variant;
    variantSecondary?: Variant;
    fullscreen?: boolean;
}

const LoadingPage = (props: LoadingPageProps) => {
    const {
        className,
        loadingPrimary = 'ra.page.loading',
        loadingSecondary = 'ra.message.loading',
        icon = defaultIcon,
        iconColor = "primary",
        variantPrimary = "h4",
        variantSecondary = "body1",
        fullscreen = false,
    } = props;

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classnames(classes.container, className, fullscreen ? classes.fullscreen : '')}>
            <div className={classes.message}>
                <div className={classes.wrapper}>
                    <Fab aria-label={translate(loadingPrimary)} color={iconColor}>
                        {icon}
                    </Fab>
                    <CircularProgress size={68} className={classnames(classes.fabProgress, classes.icon)} />
                </div>
                <Typography paragraph variant={variantPrimary} >
                    {translate(loadingPrimary)}
                </Typography>
                {
                    loadingSecondary &&
                    <Typography paragraph variant={variantSecondary} >
                        {translate(loadingSecondary)}
                    </Typography>
                }
            </div>
        </div>
    );
};

const defaultIcon = <ExtensionIcon />;

LoadingPage.propTypes = {
    className: PropTypes.string,
    loadingPrimary: PropTypes.string,
    loadingSecondary: PropTypes.string,
    icon: PropTypes.element,
    iconColor: PropTypes.string,
    variantPrimary: PropTypes.string,
    variantSecondary: PropTypes.string,
    fullscreen: PropTypes.bool,
};

LoadingPage.displayName = 'LoadingPage';

export default LoadingPage;