import { useState, useCallback } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import {
    Box,
    Divider,
    Theme,
} from '@mui/material';

import MuiGrid from '@mui/material/Grid';

import { styled } from "@mui/styles";

import {
    required,
    TextInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
} from 'react-admin';

import { CompanyAddress } from 'admin/types';
import { CreateDialogButton } from 'admin/components/layout';
import GMapsInput from './GMapsInput';

const Grid = styled(MuiGrid)<Theme>(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& [role="separator"]': {
        margin: theme.spacing(0, 2),
    },
}));

interface CompanyAddressQuickCreateButtonProps {
    onSuccess?: (data: CompanyAddress) => void;
}

function CompanyAddressQuickCreateButton(props: CompanyAddressQuickCreateButtonProps) {
    const {
        onSuccess = () => { },
    } = props;

    const [companyAddress, setCompanyAddress] = useState<CompanyAddress>({} as CompanyAddress);

    const onSelectedPlace = (data: CompanyAddress, setValue: (name: string, value: any) => void) => {
        setCompanyAddress(data);
    }

    return (
        <CreateDialogButton onSuccess={onSuccess} resource="companyaddresses" dialogProps={{ preventClose: true }} >
            <SimpleForm defaultValues={companyAddress} sx={{ width: '30vw' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextInput source="name" placeholder='Sede Legale' validate={required()} fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextInput source="addressLine1" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput source="addressLine2" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput source="addressLine3" fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextInput source="country" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput source="postalCode" fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextInput source="addressLevel1" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput source="addressLevel2" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput source="addressLevel3" fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider orientation='vertical' variant="middle" flexItem />
                    <Grid item xs>
                        <GMapsInput onSelected={onSelectedPlace} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </CreateDialogButton>
    );
}

const CompanyAddressReferenceInput = props => {
    const { setValue } = useFormContext();
    const [version, setVersion] = useState(0);
    const companyAddressId = useWatch({ name: 'companyAddressId' }) || null;
    const onSuccess = useCallback((data: CompanyAddress) => {
        if (data && data.id !== companyAddressId) {
            setValue('companyAddressId', data.id);
            setVersion(version + 1)
        }
    }, [version]);


    return (
        <Box display="flex" alignItems="center">
            <ReferenceInput key={version} {...props} sort={{ field: 'name', order: 'ASC' }} filter={{ company_eq: null }}>
                <SelectInput optionText={(choice: CompanyAddress) => `[${choice.name}] ${choice.addressLine1} - ${choice.addressLevel3} (${choice.addressLevel2})`} fullWidth {...props} />
            </ReferenceInput>
            <CompanyAddressQuickCreateButton onSuccess={onSuccess} />
        </Box>
    );
};

export default CompanyAddressReferenceInput;
