export default {
    name: 'settingtypes',
    resources: {
        name: 'Setting Type |||| Setting Types',
        forcedCaseName: 'Setting Type |||| Setting Types',
        fields: {
            value: 'Value',
            updatedAt: 'Updated At',
            settings: 'Settings',
        },
    },
    global: {
        menu: {
            name: 'Setting Types',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Setting Type "%{title}"',
        },
        action: {
        },
    },
};