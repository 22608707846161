import { ReactElement } from 'react';
import { Utils } from "admin/core"

export enum SkillCategory {
    OS,
    Office,
    Language,
    Markup,
    Scripting,
    Frameworks,
    Database,
    WebServer,
    IDE,
    CMS,
    Versioning,
    Tools,
    Other,
}

export enum SkillLevel {
    Novice = 20,
    AdvancedBeginner = 40,
    Competent = 60,
    Proficient = 80,
    Expert = 100,
}

export type SkillType = {
    name: string;
    category: SkillCategory;
    allVersion?: string[];
    versions?: any;
    icon?: ReactElement;
    level?: number;
}

export const skills = {
    //OS
    Windows: {
        name: "Windows",
        category: SkillCategory.OS,
        level: SkillLevel.Competent
    } as SkillType,
    WindowsServer: {
        name: "Windows Server",
        category: SkillCategory.OS,
        level: SkillLevel.Competent
    } as SkillType,
    Linux: {
        name: "Linux",
        category: SkillCategory.OS,
        level: SkillLevel.AdvancedBeginner
    } as SkillType,

    //Pacchetto Office (2003 -> 2021)
    Excel: {
        name: "Excel",
        category: SkillCategory.Office,
        level: SkillLevel.Competent
    } as SkillType,
    Word: {
        name: "Word",
        category: SkillCategory.Office,
        level: SkillLevel.Competent
    } as SkillType,
    Outlook: {
        name: "Outlook",
        category: SkillCategory.Office,
        level: SkillLevel.Competent
    } as SkillType,
    PowerPoint: {
        name: "PowerPoint",
        category: SkillCategory.Office,
        level: SkillLevel.Competent
    } as SkillType,
    Access: {
        name: "Access",
        category: SkillCategory.Office,
        level: SkillLevel.Competent
    } as SkillType,

    //Linguaggi di Programmazione
    CSharp: {
        name: "C#.NET",
        category: SkillCategory.Language,
        level: SkillLevel.Expert
    } as SkillType,
    ASPNETCore: {
        name: "ASP.NET Core",
        category: SkillCategory.Language,
        level: SkillLevel.Proficient
    } as SkillType,
    ASPNET: {
        name: "ASP.NET",
        category: SkillCategory.Language,
        level: SkillLevel.Proficient
    } as SkillType,
    VB: {
        name: "VB.NET",
        category: SkillCategory.Language,
        level: SkillLevel.Competent
    } as SkillType,
    ASP: {
        name: "ASP",
        category: SkillCategory.Language,
        level: SkillLevel.Competent
    } as SkillType,
    JAVA: {
        name: "JAVA",
        category: SkillCategory.Language,
        level: SkillLevel.Competent
    } as SkillType,

    //Linguaggi di Markup
    XHTML: {
        name: "XHTML",
        category: SkillCategory.Markup,
        level: SkillLevel.Proficient
    } as SkillType,
    HTML5: {
        name: "HTML5",
        category: SkillCategory.Markup,
        level: SkillLevel.Proficient
    } as SkillType,
    CSS_CSS3: {
        name: "CSS/CSS3",
        category: SkillCategory.Markup,
        level: SkillLevel.Proficient
    } as SkillType,
    XML: {
        name: "XML",
        category: SkillCategory.Markup,
        level: SkillLevel.Competent
    } as SkillType,

    //Linguaggi di Scripting
    Javascript: {
        name: "Javascript",
        category: SkillCategory.Scripting,
        level: SkillLevel.Proficient
    } as SkillType,
    Typescript: {
        name: "Typescript",
        category: SkillCategory.Scripting,
        level: SkillLevel.Proficient
    } as SkillType,
    VBA: {
        name: "VBA",
        category: SkillCategory.Scripting,
        level: SkillLevel.Competent
    } as SkillType,
    PHP: {
        name: "PHP",
        category: SkillCategory.Scripting,
        level: SkillLevel.AdvancedBeginner
    } as SkillType,
    Batch: {
        name: "Batch",
        category: SkillCategory.Scripting
    } as SkillType,
    PowerShell: {
        name: "PowerShell",
        category: SkillCategory.Scripting
    } as SkillType,

    //Frameworks
    AzureFunctions: {
        name: 'Azure Functions',
        category: SkillCategory.Frameworks
    } as SkillType,
    AzureBlobStorage: {
        name: 'Azure Blob Storage',
        category: SkillCategory.Frameworks
    } as SkillType,
    AzureEventGrid: {
        name: 'Azure Event Grid',
        category: SkillCategory.Frameworks
    } as SkillType,
    AzureEventHub: {
        name: 'Azure Event Hub',
        category: SkillCategory.Frameworks
    } as SkillType,
    AzureDevOps: {
        name: 'Azure DevOps',
        category: SkillCategory.Frameworks
    } as SkillType,
    dotNET_Framework: {
        name: '.NET Framework',
        allVersion: ['2.x', '3.x', '4.x'],
        versions: {
            dotNET_Core_2: {
                name: '.NET Framework 2.x',
                category: SkillCategory.Frameworks
            } as SkillType,
            dotNET_Core_3: {
                name: '.NET Framework 3.x',
                category: SkillCategory.Frameworks
            } as SkillType,
            dotNET_Core_4: {
                name: '.NET Framework 4.x',
                category: SkillCategory.Frameworks
            } as SkillType,
        },
        category: SkillCategory.Frameworks,
        level: SkillLevel.Proficient
    } as SkillType,
    dotNET_Core: {
        name: '.NET Core',
        allVersion: ['1.x', '2.x', '3.x'],
        versions: {
            dotNET_Core_1: {
                name: '.NET Core 1.x',
                category: SkillCategory.Frameworks
            } as SkillType,
            dotNET_Core_2: {
                name: '.NET Core 2.x',
                category: SkillCategory.Frameworks
            } as SkillType,
            dotNET_Core_3: {
                name: '.NET Core 3.x',
                category: SkillCategory.Frameworks
            } as SkillType,
        },
        category: SkillCategory.Frameworks,
        level: SkillLevel.Proficient
    } as SkillType,
    dotNET: {
        name: '.NET',
        allVersion: ['5.0', '6.0', '7', '8'],
        versions: {
            dotNET_5: {
                name: '.NET 5',
                category: SkillCategory.Frameworks
            } as SkillType,
            dotNET_6: {
                name: '.NET 6',
                category: SkillCategory.Frameworks
            } as SkillType,
            dotNET_7: {
                name: '.NET 7',
                category: SkillCategory.Frameworks
            } as SkillType,
            dotNET_8: {
                name: '.NET 8',
                category: SkillCategory.Frameworks
            } as SkillType,
        },
        category: SkillCategory.Frameworks,
        level: SkillLevel.Proficient
    } as SkillType,
    EFCore: {
        name: 'Entity Framework Core',
        allVersion: ['6', '7', '8'],
        versions: {
            EFCore_6: {
                name: 'Entity Framework Core 6',
                category: SkillCategory.Frameworks
            } as SkillType,
            EFCore_7: {
                name: 'Entity Framework Core 7',
                category: SkillCategory.Frameworks
            } as SkillType,
            EFCore_8: {
                name: 'Entity Framework Core 8',
                category: SkillCategory.Frameworks
            } as SkillType,
        },
        category: SkillCategory.Frameworks,
        level: SkillLevel.Proficient
    } as SkillType,
    EF: {
        name: 'Entity Framework',
        allVersion: ['6'],
        versions: {
            EF_6: {
                name: 'Entity Framework 6',
                category: SkillCategory.Frameworks
            } as SkillType,
        },
        category: SkillCategory.Frameworks,
        level: SkillLevel.Proficient
    } as SkillType,
    WebApi: {
        name: 'WebApi',
        category: SkillCategory.Frameworks,
        level: SkillLevel.Proficient
    } as SkillType,
    Laravel5: {
        name: 'Laravel5',
        category: SkillCategory.Frameworks
    } as SkillType,
    Blazor: {
        name: 'ASP.NET Core Blazor',
        allVersion: ['WASM', 'Server'],
        versions: {
            Blazor_WASM: {
                name: 'ASP.NET Core Blazor WASM',
                category: SkillCategory.Frameworks
            } as SkillType,
            Blazor_Server: {
                name: 'ASP.NET Core Blazor Server',
                category: SkillCategory.Frameworks
            } as SkillType,
        },
        category: SkillCategory.Frameworks,
        level: SkillLevel.Proficient
    } as SkillType,
    RadzenBlazor: {
        name: 'Radzen Blazor Components',
        category: SkillCategory.Frameworks,
        level: SkillLevel.Proficient
    } as SkillType,
    ReactJS: {
        name: 'ReactJS',
        allVersion: ['17.x'],
        versions: {
            ReactJS_17: {
                name: 'ReactJS 17.x',
                category: SkillCategory.Frameworks
            } as SkillType,
        },
        category: SkillCategory.Frameworks,
        level: SkillLevel.Proficient
    } as SkillType,
    OData: {
        name: 'OData',
        category: SkillCategory.Frameworks,
        level: SkillLevel.Proficient
    } as SkillType,
    MicrosoftGraph: {
        name: 'MicrosoftGraph',
        category: SkillCategory.Frameworks,
        level: SkillLevel.Competent
    } as SkillType,
    JQuery: {
        name: 'JQuery',
        allVersion: ['Core', 'UI', 'Mobile'],
        versions: {
            JQuery_Core: {
                name: 'jQuery Core',
                category: SkillCategory.Frameworks
            } as SkillType,
            JQuery_UI: {
                name: 'jQuery UI',
                category: SkillCategory.Frameworks
            } as SkillType,
            JQuery_Mobile: {
                name: 'jQuery Mobile',
                category: SkillCategory.Frameworks
            } as SkillType,
        },
        category: SkillCategory.Frameworks,
        level: SkillLevel.Competent
    } as SkillType,
    Bootstrap: {
        name: 'Bootstrap',
        allVersion: ['3.x', '4.x', '5.x'],
        versions: {
            Bootstrap_3: {
                name: 'Bootstrap 3.x',
                category: SkillCategory.Frameworks
            } as SkillType,
            Bootstrap_4: {
                name: 'Bootstrap 4.x',
                category: SkillCategory.Frameworks
            } as SkillType,
            Bootstrap_5: {
                name: 'Bootstrap 5.x',
                category: SkillCategory.Frameworks
            } as SkillType,
        },
        category: SkillCategory.Frameworks,
        level: SkillLevel.Competent
    } as SkillType,
    Tizen: {
        name: 'Tizen',
        allVersion: ['3.x', '4.x'],
        versions: {
            Tizen_3: {
                name: 'Tizen 3.x',
                category: SkillCategory.Frameworks
            } as SkillType,
            Tizen_4: {
                name: 'Tizen 4.x',
                category: SkillCategory.Frameworks
            } as SkillType,
        },
        category: SkillCategory.Frameworks,
        level: SkillLevel.Competent
    } as SkillType,
    AngularJS: {
        name: 'AngularJ',
        category: SkillCategory.Frameworks,
        level: SkillLevel.AdvancedBeginner
    } as SkillType,
    EXT_JS: {
        name: 'EXT-JS',
        category: SkillCategory.Frameworks,
        level: SkillLevel.AdvancedBeginner
    } as SkillType,
    Ionic: {
        name: 'Ionic',
        category: SkillCategory.Frameworks,
        level: SkillLevel.AdvancedBeginner
    } as SkillType,
    WPF: {
        name: 'WPF',
        category: SkillCategory.Frameworks
    } as SkillType,

    //Other
    LINQ: {
        name: "LINQ",
        category: SkillCategory.Other,
        level: SkillLevel.Proficient
    } as SkillType,
    MVC: {
        name: "MVC",
        category: SkillCategory.Other,
        level: SkillLevel.Proficient
    } as SkillType,
    CQRS: {
        name: "CQRS",
        category: SkillCategory.Other,
        level: SkillLevel.Proficient
    } as SkillType,

    //Database
    SQLite: {
        name: "SQLite",
        category: SkillCategory.Database,
        level: SkillLevel.Competent
    } as SkillType,
    MySQL: {
        name: "MySQL",
        category: SkillCategory.Database,
        level: SkillLevel.Competent
    } as SkillType,
    MSSQL: {
        name: "MSSQL",
        category: SkillCategory.Database,
        level: SkillLevel.Proficient
    } as SkillType,
    PostgreSQL: {
        name: "PostgreSQL",
        category: SkillCategory.Database,
        level: SkillLevel.Competent
    } as SkillType,

    //WebServer
    IIS: {
        name: "IIS",
        category: SkillCategory.WebServer
    } as SkillType,

    //IDE
    VisualStudio: {
        name: 'Visual Studio',
        allVersion: ['2010', '2013', '2015', '2017', '2019', '2022'],
        versions: {
            VisualStudio_2010: {
                name: 'Visual Studio 2010',
                category: SkillCategory.Frameworks
            } as SkillType,
            VisualStudio_2013: {
                name: 'Visual Studio 2013',
                category: SkillCategory.Frameworks
            } as SkillType,
            VisualStudio_2015: {
                name: 'Visual Studio 2015',
                category: SkillCategory.Frameworks
            } as SkillType,
            VisualStudio_2017: {
                name: 'Visual Studio 2017',
                category: SkillCategory.Frameworks
            } as SkillType,
            VisualStudio_2019: {
                name: 'Visual Studio 2019',
                category: SkillCategory.Frameworks
            } as SkillType,
            VisualStudio_2022: {
                name: 'Visual Studio 2022',
                category: SkillCategory.Frameworks
            } as SkillType,
        },
        category: SkillCategory.IDE,
        level: SkillLevel.Proficient
    } as SkillType,
    VisualStudioCode: {
        name: 'Visual Studio Code',
        category: SkillCategory.IDE,
        level: SkillLevel.Proficient
    } as SkillType,
    TizenStudio: {
        name: 'Tizen Studio',
        category: SkillCategory.IDE,
        level: SkillLevel.Competent
    } as SkillType,
    AndroidStudio: {
        name: 'Android Studio',
        category: SkillCategory.IDE,
        level: SkillLevel.Competent
    } as SkillType,
    Eclipse: {
        name: 'Eclipse',
        category: SkillCategory.IDE,
        level: SkillLevel.Competent
    } as SkillType,
    Dreamweaver: {
        name: 'Dreamweaver',
        category: SkillCategory.IDE
    } as SkillType,
    
    //CMS
    Sharepoint: {
        name: 'Sharepoint',
        category: SkillCategory.CMS,
        level: SkillLevel.AdvancedBeginner
    } as SkillType,
    WordPress: {
        name: 'WordPress',
        category: SkillCategory.CMS,
        level: SkillLevel.Competent
    } as SkillType,
    PrestaShop: {
        name: 'PrestaShop',
        category: SkillCategory.CMS
    } as SkillType,
    Joomla: {
        name: 'Joomla',
        category: SkillCategory.CMS,
        level: SkillLevel.AdvancedBeginner
    } as SkillType,

    //Versioning
    GIT: {
        name: 'GIT',
        category: SkillCategory.Versioning,
        level: SkillLevel.Proficient
    } as SkillType,
    GitHub: {
        name: 'GitHub',
        category: SkillCategory.Versioning,
        level: SkillLevel.Proficient
    } as SkillType,
    SVN: {
        name: 'SVN',
        category: SkillCategory.Versioning,
        level: SkillLevel.Proficient
    } as SkillType,

    //Tools
    Kofax: {
        name: 'Kofax',
        category: SkillCategory.Tools,
        level: SkillLevel.Competent
    } as SkillType,
    NotePadPlusPlus: {
        name: 'NotePad++',
        category: SkillCategory.Tools,
        level: SkillLevel.Proficient
    } as SkillType,
    MicrosoftTeams: {
        name: 'Microsoft Teams',
        category: SkillCategory.Tools,
        level: SkillLevel.Proficient
    } as SkillType,
    Tortoise_SVN: {
        name: 'Tortoise SVN',
        category: SkillCategory.Tools
    } as SkillType,
    Tortoise_GIT: {
        name: 'Tortoise GIT',
        category: SkillCategory.Tools
    } as SkillType,
    LightRoom: {
        name: 'LightRoom',
        category: SkillCategory.Tools,
        level: SkillLevel.Competent
    } as SkillType,
    FileZilla: {
        name: 'FileZilla',
        category: SkillCategory.Tools,
        level: SkillLevel.Proficient
    }
}

export const skillsGrouped = Utils.GroupBy(Object.values(skills), 'category')
export const getSkillsByCategory = (category: SkillCategory) => Object.values<SkillType>(skills).filter(x => x.category === category)