import settingsIcon from '@mui/icons-material/Settings';
import workIcon from '@mui/icons-material/Work';
import systemIcon from '@mui/icons-material/Settings';

const groups = {
    settings: { id: "settings", name: "pos.menu.settings", icon: settingsIcon },
    work: { id: "work", name: "pos.menu.work", icon: workIcon },
    system: { id: "system", name: "pos.menu.system", icon: systemIcon }
}

export default groups;
