import { EditGuesser, ShowGuesser } from 'react-admin';

import Icon from '@mui/icons-material/PermMedia';
import CustomList from './CustomList';

import { grey } from '@mui/material/colors';

export default {
    list: CustomList,
    edit: EditGuesser,
    show: ShowGuesser,
    icon: Icon,
    iconColor: grey[800],
};