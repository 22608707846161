export default {
    name: 'invoicelines',
    resources: {
        name: 'Riga Fattura |||| Righe Fattura',
        forcedCaseName: 'Riga Fattura |||| Righe Fattura',
        fields: {
            invoiceId: 'Fattura',
            description: 'Descrizione',
            price: 'Imp. Unitario',
            quantity: "Quantità",
            vatRate: "IVA %",
            vatAmount: "IVA €",
            amount: "Imp. Totale",
            updatedAt: 'Aggiornato il',
            invoice: 'Fattura',
        }
    },
    global: {
        menu: {
            name: 'Righe Fattura',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Riga Fattura "%{title}"',
        },
        action: {
        },
        others: {
            title: 'Riepilogo',
            net: 'Imponibile',
            vat: 'IVA %{vatRate}% su %{vatAmount}',
            totalVat: 'Totale IVA',
            stamp: 'Bollo',
            totalInvoice: 'Totale Fattura'
        }
    },
};