import { Grid } from '@mui/material';

import {
    Show,
    SimpleShowLayout,
    Labeled,
    TextField,
    BooleanField,
} from 'react-admin';

import { ShowActions } from 'admin/components';

import CustomTitle from './CustomTitle';

const CustomShow = () => {
    return (
        <Show actions={<ShowActions />} title={<CustomTitle />}>
            <SimpleShowLayout>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={2}>
                        <Labeled source="code">
                            <TextField source="code" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <Labeled source="label">
                            <TextField source="label" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Labeled source="bankAccount">
                            <BooleanField source="bankAccount" />
                        </Labeled>
                    </Grid>

                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShow;
