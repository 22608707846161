import { Route } from 'react-router-dom';
import { CustomRoutes } from 'react-admin';

import UserProfile from './Tables/System/UserProfile/UserProfile';

//import Configurations from './Configurations';

export default <CustomRoutes>
    <Route path="/profile" element={<UserProfile />} />,
    {/* <Route exact path="/settings" element={Configurations.page} /> */}
</CustomRoutes>