export default {
    name: "settings",
    resources: {
        name: 'Impostazione |||| Impostazioni',
        forcedCaseName: 'Impostazione |||| Impostazioni',
        fields: {
            key: "Chiave",
            value: "Valore"
        },
    },
    global: {
        menu: {
            name: 'Impostazioni',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Impostazione "%{title}"',
        },
        action: {
        },
    },
};