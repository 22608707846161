import { AppInfo, SkillCategory, SkillType, getSkillsByCategory } from 'publicapp/types';

import {
    Box,
    Divider,
    Grid,
    Typography,
} from '@mui/material';

import { AppWindow, SkillBar } from './base';

import iconImg from '../img/apps/skills.png';
import { useTranslate } from 'react-admin';

const appName = "myskills";
const label = "apps.my_skills";

interface CategorySkillsProps {
    category: SkillCategory;
}

const CategorySkills = (props: CategorySkillsProps) => {
    const {
        category,
    } = props;

    const translate = useTranslate();

    const skills: SkillType[] = getSkillsByCategory(category).filter(x => x.level);

    if (!skills || skills.length === 0) return null;

    return (
        <Box>
            <Typography component="legend" variant="h6" >
                {translate(`my_skills.${SkillCategory[category]}`)}
            </Typography>
            <Divider flexItem />
            {
                skills.map((skill, idx) => <SkillBar key={idx} type={skill} />)
            }
        </Box>
    )
}

const Component = () => {
    return (
        <AppWindow
            name={appName}
            iconUrl={iconImg}
            title={label}
            width='70vw'
            height='80%'
            top='10%'
            left='15vw'
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <CategorySkills category={SkillCategory.Frameworks} />
                    <CategorySkills category={SkillCategory.Database} />
                    <CategorySkills category={SkillCategory.WebServer} />
                    <CategorySkills category={SkillCategory.IDE} />
                    <CategorySkills category={SkillCategory.Versioning} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CategorySkills category={SkillCategory.Language} />
                    <CategorySkills category={SkillCategory.Scripting} />
                    <CategorySkills category={SkillCategory.Markup} />
                    <CategorySkills category={SkillCategory.Other} />
                    <CategorySkills category={SkillCategory.CMS} />
                    <CategorySkills category={SkillCategory.Tools} />
                </Grid>
            </Grid>
        </AppWindow>
    )
}

export const appInfo: AppInfo =
{
    name: appName,
    iconUrl: iconImg,
    label: label,
    component: Component
}