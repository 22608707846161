import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

import {
    Menu,
    DashboardMenuItem,
    useBasename,
    useSidebarState,
    useTranslate,
    MenuProps
} from 'react-admin';

import { Utils } from '../core';

import _ from 'underscore';

import SubMenu from './SubMenu';

import views from "../views";
import { ActionRBAC, useCanAccess } from 'admin/components';

let activeGroup: string | null = null;

export interface MenuIconProps {
    view?: any;
    isGroup?: boolean;
};

const MenuIcon = ({ view, isGroup }: MenuIconProps) => {
    const translate = useTranslate();

    if (view.icon)
        return <view.icon style={{ color: view.iconColor ? view.iconColor : 'inherit' }} />;
    else {
        const label = isGroup ? translate(view.name) : translate(view.menuName ? `${view.menuName}` : `${view.name}.menu.name`, { smart_count: 2, });
        const avatarLabel = label.split(" ").slice(0, 2).map(x => x.charAt(0)).join("");
        return <Avatar sx={{
            color: theme => theme.palette.getContrastText(view.avatarColor),
            backgroundColor: view.avatarColor,
            width: theme => theme.spacing(3),
            height: theme => theme.spacing(3),
            fontSize: theme => theme.spacing(1.5)
        }}>
            {avatarLabel.toUpperCase()}
        </Avatar>;
    }
}

const MyMenu = (props: MenuProps) => {
    const [open] = useSidebarState();
    const basename = useBasename();

    const location = useLocation();
    const translate = useTranslate();
    const { loaded, canAccess } = useCanAccess();

    const theme = useTheme();
    const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));

    let groupedViews = _.groupBy(views, function (view) { return view.group && view.group.id; });

    const [state, setState] = useState({});

    const getAlias = (view) => {
        if (view.alias) return translate(`${view.name}.menu.alias`);
        return null
    };

    const getLabel = (view) => {
        if (view.menuName) return translate(view.menuName);
        return translate(`${view.name}.menu.name`, { smart_count: 2, })
    };

    const getTitle = (view) => {
        if (view.title) return translate(view.title);
        return getLabel(view)
    };

    useEffect(() => {
        var grouped = _.filter(groupedViews, function (gv, key) { return gv !== null && key !== "undefined" && gv.length > 0; });
        if (grouped.length > 0) {
            const defState = {};
            grouped.forEach(group => {
                var isActive = Utils.FindNested(group, "name", location.pathname.replaceAll(basename, "").substr(1).split("/")[0]).length > 0;
                defState[`menu${group[0].group.id}`] = isActive;
                if (isActive) {
                    activeGroup = `menu${group[0].group.id}`;
                }
            });
            setState(state => ({ ...state, ...defState }));
        }
    }, [location.pathname])

    if (!loaded)
        return null;

    const closeAllMenu = (except = null) => {
        const _state = state;
        Object.keys(_state).forEach(function (k) {
            _state[k] = except ? (_state[except] ? false : k === except) : false;
        });

        setState(state => ({ ...state, ..._state }));
    };

    const handleToggle = (menu) => {
        closeAllMenu(menu);
    };

    const sidebarMenu = _.map(groupedViews, (viewList, groupId) => {
        if (viewList.length === 0) {
            return null;
        }

        if (groupId === "undefined") {
            return viewList.map(({ permission: viewPermission, noInSidebar, ...view }, key) => {
                return canAccess(view.name, ActionRBAC.List) && !noInSidebar && <Menu.Item
                    key={key}
                    to={`${basename}/${view.name}`}
                    primaryText={getAlias(view) || getLabel(view) || getTitle(view)}
                    leftIcon={<MenuIcon view={view} />}
                    onClick={() => { closeAllMenu(); props.onMenuClick != null && props.onMenuClick(); }}
                    sidebarIsOpen={open}
                    dense={props.dense}
                />
            }).filter(x => x);
        }

        const group = viewList[0].group;

        var menuItemLinks = viewList.map(({ permission: viewPermission, noCustomerKey, noInSidebar, publicAreaOnly, ...view }, key) => {
            if (key === "group") { return null; }
            return canAccess(view.name, ActionRBAC.List) && !noInSidebar && <Menu.Item
                key={key}
                to={`${basename}/${view.name}`}
                primaryText={getAlias(view) || getLabel(view) || getTitle(view)}
                leftIcon={<MenuIcon view={view} />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
                dense={props.dense}
            />
        }).filter(x => x);

        if (menuItemLinks.filter(mil => mil).length > 0) {
            return (
                <SubMenu
                    key={groupId}
                    handleToggle={() => handleToggle(`menu${groupId}`)}
                    isOpen={state[`menu${groupId}`]}
                    sidebarIsOpen={open}
                    name={group.name}
                    icon={<MenuIcon view={group} isGroup />}
                    dense={props.dense}
                >
                    {
                        menuItemLinks.filter(mil => mil !== null)
                    }
                </SubMenu>
            )
        }
    });

    return (
        <Box mt={1}>
            {' '}
            <DashboardMenuItem onClick={() => { closeAllMenu(); props.onMenuClick != null && props.onMenuClick(); }} sidebarIsOpen={open} />
            {sidebarMenu}
            {isXSmall && props.logout}
        </Box>
    );
};

export default MyMenu;