export default {
    name: 'invoicesubmissions',
    resources: {
        name: 'Invoice Submission |||| Invoice Submissions',
        forcedCaseName: 'Invoice Submission |||| Invoice Submissions',
        fields: {
            invoicesId: 'Invoices Id',
            sendingSequence: 'Sending Sequence',
            outcome: 'Outcome',
            xmlpath: 'Xmlpath',
            sentAt: 'Sent At',
            updatedAt: 'Updated At',
            invoices: 'Invoices',
        },
    },
    global: {
        menu: {
            name: 'Invoice Submissions',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Invoice Submission "%{title}"',
        },
        action: {
        }
    },
};