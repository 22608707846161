import PropTypes from 'prop-types';

import MapIcon from '@mui/icons-material/LocationOn';

import {
    Box,
    IconButton,
    Tooltip,
} from '@mui/material';

export interface MarkerProps {
    text?: string;
    onClick?: () => void;
    lat: number;
    lng: number;
}

const Marker = (props: MarkerProps) => {
    const {
        text,
        onClick = () => { }
    } = props;
    return (
        <Box>
            <Tooltip title={text}>
                <IconButton onClick={onClick}>
                    <MapIcon fontSize='large' color='error' />
                </IconButton>
            </Tooltip>
        </Box>
    )
};

Marker.defaultProps = {
    onClick: null,
};

Marker.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
};

export default Marker;