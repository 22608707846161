import { Children, ReactNode } from 'react';
import PropTypes from 'prop-types';

import {
    SaveButton,
    DeleteButton,
    Toolbar,
    useEditContext,
    useNotify,
    usePermissions
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { Utils } from 'admin/core';

export interface EditToolbarProps {
    children?: ReactNode | ReactNode[] | null;
    disabled?: boolean;
    hasDelete?: boolean;
}

const EditToolbar = (props: EditToolbarProps) => {
    const {
        disabled = false,
        hasDelete = false,
        children = [],
    } = props;

    const notify = useNotify();
    const { permissions } = usePermissions();
    const editProps = useEditContext();

    const { isLoading } = editProps;

    const onSuccess = (response) => {
        notify('ra.notification.deleted', {
            type: 'info',
            messageArgs: { smart_count: 1 },
            undoable: true,
        });
    };

    return (
        <Toolbar sx={{ justifyContent: 'end' }}>
            <SaveButton />
            {hasDelete && <DeleteButton mutationOptions={{ onSuccess }} disabled={isLoading} />}
            {Children.map(children, (child: any, index) => {
                const { disabled: disabledChild, ...childProps } = child.props

                const commonProps = {
                    key: index,
                    context: 'button',
                    disabled: disabled ||
                        isLoading ||
                        (disabledChild !== null && Utils.IsFunction(disabledChild) && disabledChild(isLoading, permissions)) ||
                        (Utils.IsBoolean(disabledChild) && disabledChild)
                }

                return Utils.CreateOrCloneElement(child, { ...commonProps, ...childProps })
            })}
        </Toolbar>
    )
};

EditToolbar.propTypes = {
    disabled: PropTypes.bool,
    hasDelete: PropTypes.bool,
    children: PropTypes.node,
};

EditToolbar.displayName = 'EditToolbar';

export default EditToolbar;