export default {
    name: 'settingtypes',
    resources: {
        name: 'Tipo di impostazione |||| Tipi di impostazione',
        forcedCaseName: 'Tipo di impostazione |||| Tipi di impostazione',
        fields: {
            value: 'Valore',
            updatedAt: 'Aggiornato il',
            settings: 'Impostazioni',
        },
    },
    global: {
        menu: {
            name: 'Tipi di impostazione',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Tipo di impostazione "%{title}"',
        },
        action: {
        },
    },
};