import { useContext } from "react";
import { CreateContext, EditContext } from "react-admin";
import { ActionRBAC } from "./useCanAccess";

export const useIsCreateOrEdit = () => {
    const create = useContext(CreateContext);
    const edit = useContext(EditContext);

    if (!!create.resource) {
        return {
            action: ActionRBAC.Create,
        };
    }

    if (!!edit.resource) {
        return {
            action: ActionRBAC.Edit,
        };
    }

    return {
        action: null,
    };
};