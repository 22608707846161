import { ReactElement, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Tooltip } from '@mui/material';

import {
    Button,
    useDataProvider,
    useRecordContext,
    useNotify,
    RaRecord,
    ButtonProps,
    useTranslate,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import Icon from '@mui/icons-material/PictureAsPdf';
import { blue } from '@mui/material/colors';

import { Utils } from 'admin/core';
import { LoadingPage } from 'admin/components';

interface Props<RecordType extends RaRecord = any> {
    record?: RecordType;
    resource?: string;
}

export type GenerateInvoicePdfButtonProps<RecordType extends RaRecord = any> = Props<
    RecordType
> &
    ButtonProps;

export const GenerateInvoicePdfButton = <RecordType extends RaRecord = any>(
    props: GenerateInvoicePdfButtonProps<RecordType>
) => {
    const {
        label = 'invoices.action.pdf.label',
        ...rest
    } = props;

    const dataProvider = useDataProvider();
    const record = useRecordContext(props);
    const notify = useNotify();
    const translate = useTranslate();

    const [loading, setLoading] = useState(false);

    const onClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading(true);

        dataProvider.getFile(`api/invoices/generatepdf/${record.id}`).then((blob) => {
            if (blob) {
                Utils.DownloadFile(blob, `Fattura_${record.fiscalYear}_${record.number}.pdf`)
            }
            else {
                notify('invoices.action.pdf.failed', { type: "warning" })
            }
        }).catch(error => {
            console.log(error);
            notify('Error', { type: 'warning' })
        }).finally(() => {
            setLoading(false);
        });
    };

    if (loading) return <LoadingPage loadingPrimary='invoices.action.pdf.processing' loadingSecondary='' fullscreen />

    return (
        <Tooltip title={translate(label)}>
            <Box>
                <Button
                    label=""
                    onClick={onClick}
                    {...rest}
                >
                    <Icon sx={{ color: blue[500] }} />
                </Button>
            </Box>
        </Tooltip>
    )
}

GenerateInvoicePdfButton.propTypes = {
    record: PropTypes.any,
};