import { makeStyles } from "@mui/styles";

import { Utils } from "admin/core";

const useStyles = makeStyles(theme => ({
    tooltip: {
        margin: 0,
        padding: 5,
        backgroundColor: 'rgb(255, 255, 255)',
        border: '1px solid rgb(204, 204, 204)',
        whiteSpace: 'nowrap'
    },
    tooltipLabel: {
        margin: 0,
        padding: 0,
    },
}));

const CustomizedTooltip = ({ active, payload, isCurrency, label }: any) => {
    const classes = useStyles();
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        if (!Utils.IsEmpty(data.other)) {
            let _globalLabel = data.xAxisLabel;
            let _globalValue = data.globalValue;

            if (isCurrency) {
                var formatter = new Intl.NumberFormat('it-IT', {
                    style: 'currency',
                    currency: 'EUR',
                });
                _globalValue = formatter.format(parseFloat(_globalValue.toFixed(2)));
            }

            return (
                <div className={classes.tooltip}>
                    <p className={classes.tooltipLabel}>
                        <strong>{_globalLabel}</strong>{`: ${_globalValue}`}
                    </p>
                    {
                        data.otherNames.map((otherName, index) => {
                            let _label = data.otherLabels[index];
                            let _value = data.other[otherName];
                            let color = otherName;

                            if (isCurrency) {
                                var formatter = new Intl.NumberFormat('it-IT', {
                                    style: 'currency',
                                    currency: 'EUR',
                                });
                                _value = formatter.format(parseFloat(_value.toFixed(2)));
                            }

                            return (
                                <p key={index} className={classes.tooltipLabel} style={{ color }}>
                                    <strong>{_label}</strong>{`: ${_value}`}
                                </p>
                            )
                        })
                    }
                </div>
            );
        }
        else {
            let _label = data.xAxisLabel;
            let _value = data.globalValue;

            if (isCurrency) {
                var formatter = new Intl.NumberFormat('it-IT', {
                    style: 'currency',
                    currency: 'EUR',
                });
                _value = formatter.format(parseFloat(_value.toFixed(2)));
            }

            return (
                <div className={classes.tooltip}>
                    <p className={classes.tooltipLabel}>
                        <strong>{_label}</strong>{`: ${_value}`}
                    </p>
                </div>
            );
        }
    }

    return null;
};

export default CustomizedTooltip;