import { AppInfo, AppStatusType } from 'publicapp/types';

import Home from './Home';

import { appInfo as MyInfo } from './MyInfo';
import { appInfo as GitHub } from './GitHub';
import { appInfo as LinkedIn } from './LinkedIn';
import { appInfo as MySkills } from './MySkills';
import { appInfo as MyExperience } from './MyExperience';

export const apps: AppInfo[] = [
    { ...MyInfo },
    { ...MyExperience },
    { ...MySkills },
    { ...GitHub },
    { ...LinkedIn },
].map((x, idx) => {
    x.order = idx;
    x.status = AppStatusType.closed;
    x.active = false;
    return x;
})

export {
    Home
}