export default {
    name: 'companies',
    resources: {
        name: 'Company |||| Companies',
        forcedCaseName: 'Company |||| Companies',
        fields: {
            name: 'Name',
            firstName: 'First Name',
            lastName: 'Last Name',
            vatNumber: 'Vat Number',
            fiscalCode: 'Fiscal Code',
            companyAddressId: 'Company Address Id',
            telephone: 'Telephone',
            email: 'Email',
            pec: 'Pec',
            bankAccount: 'Bank Account',
            destinationCode: 'Destination Code',
            userId: 'User Id',
            vatRegimeId: 'Vat Regime Id',
            updatedAt: 'Updated At',
            companyAddress: 'Company Address',
            vatRegime: 'Vat Regime',
        },
    },
    global: {
        menu: {
            name: 'Companies',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Company "%{title}"',
        },
        action: {
        },
    },
};