import { useEffect, useState } from 'react';
import {
    Admin,
    Loading,
    Resource,
} from 'react-admin';

import { QueryClient } from 'react-query';

import { I18nProvider } from "ra-core";
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { dataProvider, authProvider, jwtProvider } from './providers';
import { englishMessages, italianMessages, Utils, lightTheme, darkTheme } from './core';

// domain translations
import { default as englishDomainMessages } from "./translations/en";
import { default as italianDomainMessages } from "./translations/it";

import { Layout, LoginPage, NotFound } from './layout';
import Dashboard from './views/Dashboard/Dashboard';

import views from "./views";
import customRoutes from "./views/customRoutes";
import { ResourceRBAC } from './components/rbac/ResourceRBAC';

const translations: any = {
    it: { ...italianMessages, ...italianDomainMessages },
    en: { ...englishMessages, ...englishDomainMessages },
};

const fetchResources = permissions => {
    let appResources = views.map(({ permission: viewPermission, ...view }, key) => {
        return <ResourceRBAC key={key} {...view} />
    });

    let allowedViews = [
        ...appResources,
        <Resource name="api/enums" />,
        <Resource name="roles" />,
    ]

    allowedViews.push(customRoutes);

    return allowedViews;
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

const App = () => {
    const [setupCompleted, setSetupCompleted] = useState<boolean>(false);
    const [i18nProvider, setI18nProvider] = useState<I18nProvider>();

    useEffect(() => {
        const decodedToken: any = jwtProvider.getToken(true);
        let currentCulture: string = decodedToken && decodedToken.culture ? decodedToken.culture : "it-IT";

        var lang = currentCulture.split('-')[0];

        const i18nProvider = polyglotI18nProvider(
            locale => translations[locale] ? translations[locale] : translations.it,
            lang,//resolveBrowserLocale(),
            { allowMissing: true }
        );

        setI18nProvider(i18nProvider);
        setSetupCompleted(true);

        return () => { };
    }, [])

    useEffect(() => {
        if (setupCompleted) {
            console.log(`YOU ARE IN '${Utils.GetNodeEnv()}' ENV`);
        }
    }, [setupCompleted])

    if (!setupCompleted)
        <Loading loadingPrimary={englishMessages.ra.page.loading} loadingSecondary={englishMessages.ra.message.loading} />

    return (
        <Admin
            requireAuth
            basename="/admin"
            dashboard={Dashboard}
            loginPage={LoginPage}
            layout={Layout}
            catchAll={NotFound}
            theme={lightTheme}
            darkTheme={darkTheme}
            dataProvider={dataProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            queryClient={queryClient}>
            {fetchResources}
        </Admin>
    );
};

export default App;