import { Grid } from '@mui/material';

import {
    Show,
    SimpleShowLayout,
    Labeled,
    TextField,
    WithRecord,
} from 'react-admin';

import { ShowActions } from 'admin/components';

import CustomTitle from './CustomTitle';
import { GMapsField } from 'admin/views/Common/fields';

const CustomShow = () => {
    return (
        <Show actions={<ShowActions />} title={<CustomTitle />}>
            <SimpleShowLayout>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={2}>
                        <Labeled source="name">
                            <TextField source="name" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Labeled source="companyName">
                            <TextField source="companyName" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="country">
                            <TextField source="country" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="postalCode">
                            <TextField source="postalCode" />
                        </Labeled>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Labeled source="addressLevel1">
                            <TextField source="addressLevel1" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Labeled source="addressLevel2">
                            <TextField source="addressLevel2" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Labeled source="addressLevel3">
                            <TextField source="addressLevel3" />
                        </Labeled>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Labeled source="addressLine1">
                            <TextField source="addressLine1" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Labeled source="addressLine2">
                            <TextField source="addressLine2" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Labeled source="addressLine3">
                            <TextField source="addressLine3" />
                        </Labeled>
                    </Grid>

                    <WithRecord label="" render={record => record.placeId &&
                        <Grid item xs={12}>
                            <GMapsField sourceLat="lat" sourceLng="lng" />
                        </Grid>
                    } />

                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShow;
