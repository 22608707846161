export default {
    name: 'vatrates',
    resources: {
        name: 'Importo Iva |||| Importi Iva',
        forcedCaseName: 'Importo Iva |||| Importi Iva',
        fields: {
            label: "Testo",
            code: "Codice",
            value: 'Valore %',
            order: "Ordine",
            updatedAt: 'Aggiornato il',
        },
    },
    global: {
        menu: {
            name: 'Importi Iva',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Importo Iva "%{title}"',
        },
        action: {
        },
    },
};