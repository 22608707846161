import { useMediaQuery, Theme } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AccountIcon from '@mui/icons-material/VpnKey';

import { Link, Tooltip } from '@mui/material';

import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    SearchInput,
    FunctionField,
} from 'react-admin';

import {
    EditButton,
    DeleteWithConfirmButton,
    ShowButton,
    ListActions,
    ListBulkActions,
    ListRowActions
} from 'admin/components';

const filters = [
    <SearchInput source="q" alwaysOn />,
    // <BooleanInput source="xxx" />,
    // <ReferenceInput source="xxxId" reference="xxx" label="resources.xxx.fields.xxx">
    //     <SelectInput source="xxx" />
    // </ReferenceInput>,
    //<NumberInput source="xxx" />,
];

const CustomList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            actions={<ListActions />}
            filters={filters}
            filterDefaultValues={{}}
            sort={{ field: 'name', order: 'ASC' }}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${record.name})`}
                    secondaryText={record => `${record.dob}`}
                    tertiaryText={record => record.enabled}
                    linkType="edit"
                />
            ) : (
                <Datagrid bulkActionButtons={<ListBulkActions hasDeleteWithConfirm={false} />} optimized >
                    <TextField source="name" />
                    <TextField source="firstName" />
                    <TextField source="lastName" />
                    <TextField source="vatNumber" />
                    <TextField source="fiscalCode" />
                    <FunctionField source="telephone" textAlign='center' render={record => record.telephone && <Tooltip title={record.telephone}><Link href={`tel:${record.telephone}`}><PhoneIcon /></Link></Tooltip>} />
                    <FunctionField source="email" textAlign='center' render={record => record.email && <Tooltip title={record.email}><Link href={`mailto:${record.email}`}><EmailIcon /></Link></Tooltip>} />
                    <FunctionField source="pec" textAlign='center' render={record => record.pec && <Tooltip title={record.pec}><Link href={`mailto:${record.pec}`}><EmailIcon /></Link></Tooltip>} />
                    <TextField source="destinationCode" />
                    <FunctionField source="userId" textAlign='center' render={record => record.userId && <AccountIcon />} />
                    <ListRowActions>
                        <EditButton />
                        <DeleteWithConfirmButton disabledFn={record => record.userId != null} />
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )}
        </List>
    );
};

export default CustomList;
