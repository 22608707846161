import { GMaps } from 'admin/components/google';
import { MarkerProps } from 'admin/components/google/maps/Marker';
import { Coords } from 'google-map-react';
import { useRecordContext } from 'react-admin';

interface GMapsFieldProps {
    sourceLat: string;
    sourceLng: string;
}

const GMapsField = (props: GMapsFieldProps) => {
    const record = useRecordContext();
    const {
        sourceLat,
        sourceLng,
    } = props;

    if (!record) return null;

    const lat = parseFloat(record[sourceLat]);
    const lng = parseFloat(record[sourceLng]);

    if (!lat || !lng) return null;

    const center: Coords = {
        lat,
        lng
    };

    const marker: MarkerProps = {
        lat,
        lng
    };

    return (
        <GMaps
            sx={{ height: 300 }}
            defaultCenter={center}
            markers={[marker]}
        />
    )
}

export default GMapsField;