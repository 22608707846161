import { Utils } from 'admin/core';

//import __Base__ from './__Base__';

import * as system from './System';
import * as work from './Work';

export default {
    // Base: {
    //     name: "base", permission: "*", ...__Base__
    // },

    // GROUPED RESOURCES
    //...Utils.BuildResources(Polizze, true, 'tablesPolizze'),
    ...Utils.BuildResources(work, false, 'work'),
    ...Utils.BuildResources(system, false, 'system'),
};