import { Box, useMediaQuery, Theme } from '@mui/material';

import {
    FilterButton,
    FilterForm,
    FilterContext,
    ListBase,
    Pagination,
    ReferenceInput,
    SearchInput,
    SelectInput,
    SortButton,
    Title,
    TopToolbar,
    useGetResourceLabel,
    useRefresh,
} from 'react-admin';

import { MediaUploaderButton } from "admin/components";

import ImageList from './GridList';
import Aside from './Aside';

const CustomList = () => {
    const getResourceLabel = useGetResourceLabel();
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <ListBase perPage={24} sort={{ field: 'title', order: 'ASC' }}>
            <Title defaultTitle={getResourceLabel('medias', 2)} />
            <FilterContext.Provider value={listFilters}>
                <ListActions isSmall={isSmall} />
                {isSmall && (
                    <Box m={1}>
                        <FilterForm />
                    </Box>
                )}
            </FilterContext.Provider>
            <Box display="flex">
                <Aside />
                <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>
                    <ImageList />
                    <Pagination rowsPerPageOptions={[12, 24, 48, 72]} />
                </Box>
            </Box>
        </ListBase>
    );
};

export const listFilters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput source="contentType" reference="api/enums/mimeTypes">
        <SelectInput optionText="value" />
    </ReferenceInput>,
];

const ListActions = ({ isSmall }) => {
    const refresh = useRefresh();

    return (
        <TopToolbar sx={{ minHeight: { sm: 56 } }}>
            {isSmall && <FilterButton />}
            <SortButton fields={['size', 'title']} />
            <MediaUploaderButton multiple={true} onUploaded={() => refresh()} />
        </TopToolbar>
    )
};

export default CustomList;