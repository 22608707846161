import { memo } from 'react';

import {
    Box
} from '@mui/material';

import { CommonProps } from './index'

import { useStore } from 'react-admin';

import AppIcon from 'publicapp/apps/base/AppIcon';
import { Utils } from 'admin/core';
import { AppInfo } from 'publicapp/types';
import { CompareValues } from 'admin/core/utils';

interface DesktopProps extends CommonProps {
}

export const Desktop = memo((props: DesktopProps) => {
    const [applist, setApplist] = useStore<AppInfo[]>('applist');
    const { isSmall } = props;

    return (
        <>
            <Box
                id="desktop"
                sx={{
                    height: 'calc(100vh - 48px)',
                    width: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap'
                }}
            >
                {
                    applist?.sort(CompareValues("order")).map((app, index) => {
                        return (
                            <AppIcon
                                key={index}
                                name={app.name}
                                iconUrl={app.iconUrl}
                                label={app.label}
                                toLink={app.toLink}
                            />
                        )
                    })
                }
            </Box>
            {
                applist?.filter(x => x.component).map((app, index) => Utils.CreateOrCloneElement(app.component, { key: index, isSmall: isSmall }))
            }
        </>
    )
})