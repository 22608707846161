import isEqual from 'lodash/isEqual';
import { Layout, LayoutProps, LoadingPage, useAuthState, useDataProvider, useGetIdentity, useStore } from 'react-admin';
import Header from "./Header";
import Menu from './Menu';
import { ApiException } from 'admin/types';
import { useEffect, useState } from 'react';

export type LayoutStylesProps = {
    /**
     * Styles applied to root container
     */
    root?: any;
    /**
     * Styles applied to second container
     */
    container?: any;
    /**
     * Styles applied to appBar
     */
    appBar?: any;
    /**
     * Styles applied to sidebar when is open
     */
    sidebarWhenOpen?: any;
    /**
     * Styles applied to sidebar when is closed
     */
    sidebarWhenClosed?: any;
    /**
     * Styles applied to menu list items
     */
    listItem?: any;
    /**
     * Styles applied to user profile menu link
     */
    menuLink?: any;
    /**
     * Styles applied to logout button
     */
    logoutButton?: any;
    /**
     * Styles applied to user profile avatar
     */
    avatar?: any
};

export interface LayoutExtProps extends LayoutProps {
    views?: any[];
};

const LayoutExt = (props: LayoutExtProps) => {
    const { isLoading, authenticated } = useAuthState();
    const dataProvider = useDataProvider();
    const { identity } = useGetIdentity();
    const [userInfo, setUserInfo] = useStore<any>('userinfo', null);
    const [value, setValue] = useState<any>(null);

    useEffect(() => {
        if (authenticated) {
            if (!isEqual(value, userInfo)) {
                dataProvider
                    .get('api/users/me')
                    .then(response => {
                        const { data } = response || { data: null };
                        if (data) {
                            setUserInfo(data)
                            setValue(data);
                        }
                    })
                    .catch((apiException: ApiException) => {
                        console.error(apiException?.errors[0].code);
                        setUserInfo(identity)
                        setValue(identity);
                    });
            }
        }
    }, [isLoading, authenticated, userInfo])

    if (isLoading) return <LoadingPage />;

    return (
        <Layout
            {...props}
            appBar={Header}
            //sidebar={CustomSidebar}
            menu={Menu}
        />
    )
};

export default LayoutExt;