import buildRestProvider from 'admin/providers/ra-data-simple-rest';
import { Utils } from 'admin/core';

import { fetchUtils} from "ra-core";

const apiEndpoint = Utils.GetENV('API_ENDPOINT');

const dataProvider = buildRestProvider(apiEndpoint, (url: string, options: fetchUtils.Options = {}) => {
    const customHeaders = (options.headers || new Headers({ Accept: 'application/json' })) as Headers;

    customHeaders.set('Accept-Language', 'it');

    options.headers = customHeaders;
    return fetchUtils.fetchJson(url, options);
});

export {
    dataProvider
}
