import {
    usePermissions,
} from 'react-admin';

export type CanAccessFunction = (
    permissions: string[],
    resource: ResourceRBAC | string,
    action: ActionRBAC
) => boolean;

export type ReducedCanAccessFunction = (
    resource: ResourceRBAC | string,
    action: ActionRBAC
) => boolean;

export type UseCanAccessReturn = {
    canAccess: ReducedCanAccessFunction;
    loaded: boolean;
};

const canAccess: CanAccessFunction = (
    permissions: string[],
    resource: ResourceRBAC | string,
    action: ActionRBAC
) => {
    if (permissions && permissions.length > 0) {
        const result = permissions.map(permission => {
            const deniedRolePermission: PermRBAC[] = deniedRolePermissions[permission];

            if (deniedRolePermission && deniedRolePermission.length > 0) {
                const roleResult = deniedRolePermission.map(x => {
                    let resourceFound = x.action.includes(ActionRBAC.Any) || x.action.includes(action);

                    if (!resourceFound)
                        return true;
                    else {
                        let actionFound = x.resource.includes(ResourceRBAC.Any) || x.resource === `${resource}.${ResourceRBAC.Any}` || x.resource === resource;

                        if (!actionFound)
                            return true;
                        else
                            return false;
                    }
                });
                return roleResult.every(x => x === true);
            }
            else {
                return true;
            }
        });
        return result.some(x => x === true);
    }
    return true;
};

export const useCanAccess = (): UseCanAccessReturn => {
    const { isLoading, permissions } = usePermissions();

    return {
        canAccess: !!permissions
            ? (resource: ResourceRBAC | string, action: ActionRBAC) =>
                canAccess(permissions as string[], resource, action)
            : () => true,
        loaded: !isLoading && !!permissions,
    };
};

export type PermRBAC = {
    action: ActionRBAC[];
    resource: string;
};

export enum ActionRBAC {
    Any = "*",
    List = "list",
    Create = "create",
    Edit = "edit",
    Export = "export",
    Show = "show",
    Read = "read",
    Write = 'write'
}

export enum ResourceRBAC {
    Any = "*",
}

const deniedRolePermissions = {
    user: [
        { action: [ActionRBAC.Any], resource: 'users' },
        { action: [ActionRBAC.Any], resource: 'medias' },
        { action: [ActionRBAC.Any], resource: 'planningitemtypes' },
    ] as PermRBAC[],
    projectmanager: [
        { action: [ActionRBAC.Any], resource: 'users' },
        { action: [ActionRBAC.Any], resource: 'medias' },
        { action: [ActionRBAC.Any], resource: 'planningitemtypes' },
    ] as PermRBAC[],
    sale: [
        { action: [ActionRBAC.Any], resource: 'users' },
        { action: [ActionRBAC.Any], resource: 'medias' },
        { action: [ActionRBAC.Any], resource: 'planningitemtypes' },
        { action: [ActionRBAC.Write], resource: 'drawingrevs' },
    ] as PermRBAC[],
    admin: [
    ] as PermRBAC[],
};
