import {  Card, CardContent, Stack, Typography } from '@mui/material';
import HotTub from '@mui/icons-material/HotTub';
import { Title, useAuthenticated, useTranslate } from 'react-admin';
import { BackButton } from 'admin/components';

export default () => {
    const translate = useTranslate();
    useAuthenticated({ params: { logoutOnFailure: true } }); // redirects to login if not authenticated
    return (
        <Card sx={{mt: 2}}>
            <Title title="404" />
            <CardContent>
                <Stack justifyContent={"center"} alignItems={"center"} sx={{ p: 5 }} spacing={2}>
                    <HotTub sx={{ fontSize: theme => theme.spacing(10), color: theme => theme.palette.secondary.main }} />
                    <Typography variant="h5" textAlign="center" >
                        {translate('pos.page_not_found')}
                    </Typography>
                    <BackButton withText />
                </Stack>
            </CardContent>
        </Card>
    )
};