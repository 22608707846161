export default {
    name: "users",
    resources: {
        name: 'Utente |||| Utenti',
        forcedCaseName: 'Utente |||| Utenti',
        fields: {
            identityId: "IdentityId",
            firstName: "Nome",
            lastName: "Cognome",
            userName: "Username",
            email: "Email",
            providerId: "Provider Id",
            providerType: "Provider",
            profilePhotoUrl: "Foto Profilo",
            roles: "Ruoli"
        },
    },
    global: {
        menu: {
            name: 'Utenti',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Utente "%{title}"',
        },
        action: {
        },
    },
};