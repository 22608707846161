import { ReactElement, memo, useState } from 'react';

import { AppInfo, SkillType } from 'publicapp/types';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoteIcon from '@mui/icons-material/Lan';

import { AppWindow, Skills } from './base';

import iconImg from '../img/apps/experiences.png';

import { useTranslate, useStore } from 'react-admin';

const appName = "myexperiences";
const label = "apps.my_experiences";

interface ExperienceProps {
    id: string;
    icon: ReactElement;
    dateFrom: string;
    dateTo?: string;
    location: string;
    role: string;
    company: string;
    skills?: SkillType[];
    activities?: string[];
    preComponent?: ReactElement;
    postComponent?: ReactElement;
    remote?: boolean;
}

const Experience = memo((props: ExperienceProps) => {
    const {
        id,
        icon,
        dateFrom,
        dateTo = 'my_experience.now',
        location,
        role,
        company,
        skills = [],
        activities = [],
        preComponent,
        postComponent,
        remote = false,
    } = props;

    const translate = useTranslate();
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleChange}>
            <AccordionSummary
                id={id}
                aria-controls={id}
                expandIcon={<ExpandMoreIcon />}
                sx={{ background: '#f3f3f3 radial-gradient(#eff4f9 75%, #f3f3f3 100%) no-repeat fixed', }}
            >
                <Stack justifyContent='center' alignItems='center' sx={{ pr: 2 }}>
                    {icon}
                </Stack>
                <Stack sx={{ height: '100%', width: '100%' }}>
                    <Typography sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center' }}>
                        {translate(dateFrom)} – {translate(dateTo)} | {location}
                        {
                            remote &&
                            <Tooltip
                                title={translate('my_experience.remote')}
                                placement="top"
                                sx={{ ml: 1, fontSize: 18 }}
                            >
                                <RemoteIcon />
                            </Tooltip>
                        }
                    </Typography>
                    <Typography>
                        <strong>{role}</strong> | <em>{company}</em>
                    </Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    p: 1,
                    backgroundColor: '#fff',
                    borderTop: '1px solid rgba(0, 0, 0, .125)',
                }}
            >
                <Stack spacing={.5}>
                    <Skills data={skills} />

                    <Divider flexItem />

                    {preComponent}

                    {
                        activities && activities.length > 0 &&
                        <Typography variant="h5">
                            {translate('my_experience.activities')}
                        </Typography>
                    }

                    {
                        activities.map((activity, idx) =>
                            <Typography key={idx} variant="body1">
                                {activity}
                            </Typography>
                        )
                    }

                    {postComponent}
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
})

const Component = () => {
    const [experienceslist] = useStore('experiences', [] as ExperienceProps[]);
    return (
        <AppWindow
            name={appName}
            iconUrl={iconImg}
            title={label}
        >
            {experienceslist.map((experience, idx) => <Experience key={idx} {...experience} />)}
        </AppWindow>
    )
}

export const appInfo: AppInfo =
{
    name: appName,
    iconUrl: iconImg,
    label: label,
    component: Component
}