import { ReactElement, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '@mui/icons-material/PermMedia';

import {
    Button,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import MediaGallery from '../layout/MediaGallery';

interface MediaGalleryButtonProps {
    icon?: ReactElement;
    label: string,
    onSelected: (src: string, selectedMedia: any) => void,
    accept: string | string[];
    multiple?: boolean;
}

const MediaGalleryButton = (props: MediaGalleryButtonProps) => {
    const {
        icon = defaultIcon,
        onSelected = () => { },
        accept = 'image/*',
        label = 'pos.media_manager.title',
        multiple = false,
        ...rest
    } = props;

    const [openMediaGallery, setOpenMediaGallery] = useState(false);

    const handleOpenMediaGallery = e => {
        e.stopPropagation();
        setOpenMediaGallery(true);
    };

    return (
        <>
            <Button onClick={handleOpenMediaGallery} {...rest}>
                {icon}
            </Button>
            <MediaGallery open={openMediaGallery} setOpen={setOpenMediaGallery} onSelected={onSelected} accept={accept} multiple={multiple} />
        </>
    );
};

const defaultIcon = <Icon />;

MediaGalleryButton.propTypes = {
    icon: PropTypes.element,
    onSelected: PropTypes.func,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    multiple: PropTypes.bool,
};

export default MediaGalleryButton;
