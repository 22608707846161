import React, { PropsWithChildren } from "react";
import { MenuItemLink, useBasename, useGetIdentity, useUserMenu } from "react-admin";

import Avatar from "@mui/material/Avatar";

interface UserProfileProps extends PropsWithChildren<any> {
    layoutStyles?: any;
    loadingLabel?: string;
    loadingIcon?: React.ReactElement;
    redirectTo?: string;
}

const defaultProps: UserProfileProps = {
    layoutStyles: {
        menuLink: '',
        avatar: ''
    },
    loadingLabel: 'pos.menu.profile',
}

export const UserProfile: React.FC<UserProfileProps> = (props) => {
    const basename = useBasename();
    const { onClose } = useUserMenu();
    const { data: user, isLoading, error } = useGetIdentity();

    let profileRoute = `${basename}${props.redirectTo || `/profile`}`;

    if (!isLoading && !!user) {
        const firstLetters = user?.fullName
            ?.split(" ")
            .map((parts: any) => parts[0])
            .join("")
            .toUpperCase();

        return (
            <MenuItemLink
                sx={{
                    fontWeight: "bold",
                    textTransform: "none",
                    borderLeft: 'none',
                    minWidth: 'auto',
                    '& .RaMenuItemLink-icon': {
                        minWidth: theme => theme.spacing(4.5)
                    },
                    ...props.layoutStyles.menuLink
                }}
                leftIcon={
                    <div style={{ display: "flex" }}>
                        {(!!user) && (
                            <Avatar
                                sx={{
                                    backgroundColor: theme => theme.palette.secondary.main,
                                    width: theme => theme.spacing(2.5),
                                    height: theme => theme.spacing(2.5),
                                    fontSize: "0.75rem",
                                    ...props.layoutStyles.avatar
                                }}
                                alt={user?.fullName} >
                                {firstLetters}
                            </Avatar>
                        )}
                    </div>
                }
                primaryText={'pos.menu.profile'}
                to={profileRoute}
                onClick={onClose}
            />
        );
    }

    return (
        <MenuItemLink
            sx={{
                fontWeight: "bold",
                textTransform: "none",
                borderLeft: 'none',
                ...props.layoutStyles.menuLink
            }}
            to={profileRoute}
            primaryText={props.loadingLabel}
            leftIcon={props.loadingIcon}
            sidebarIsOpen
        />
    );
};

UserProfile.defaultProps = defaultProps;
UserProfile.displayName = "UserProfile";