import React from 'react';
import classnames from 'classnames';

import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    FileInput,
    FileField,
    FormDataConsumer,
    required,
    email,
    number,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

// import { makeStyles } from '@mui/styles';
// import { Theme } from '@mui/material';

import CustomTitle from './CustomTitle';
import { transformData } from './transformData';

import { EditActions, EditToolbar, FileUploadPlaceholder, RichTextInput } from 'admin/components';

// const useStyles = makeStyles<Theme>(theme => ({
//     dropZone: {
//         width: '100%',
//         borderRadius: 10,
//         border: '6px dashed #bcbcbc',
//         cursor: 'pointer',
//         padding: theme.spacing(3),
//         textAlign: 'center',
//         background: '#e4e4e4',
//         color: theme.palette.getContrastText('#e4e4e4'),
//         '& p': {
//             margin: 0,
//         },
//         '&.withfiles': {
//             borderColor: '#33cc01',
//         }
//     },
// }));

const CustomEdit = () => {
    // const classes = useStyles();
    return (
        <Edit title={<CustomTitle />} actions={<EditActions />} transform={transformData}>
            <SimpleForm toolbar={<EditToolbar hasDelete={false} />} >
                <TextInput source="key" InputProps={{ readOnly: true }} fullWidth formClassName="col-12 col-md-4 offset-right-md-8" />
                <FormDataConsumer childFieldAsFormClassName="className" fullWidth formClassName="col-12">
                    {({ formData, ...rest }) => {
                        let fieldType = formData.settingTypes.testo;

                        switch (fieldType) {
                            // case 'image-multi':
                            //     return (
                            //         <FileInput source="value" validate={required()} placeholder={<FileUploadPlaceholder />} multiple={true} accept={'image/*'} dropZoneClassName={classes.dropZone} fullWidth formClassName="col-12" {...rest} >
                            //             <FileField source="src" title="title" target="_blank" onClick={getFile} />
                            //         </FileInput>
                            //     );
                            // case 'file-multi':
                            //     return (
                            //         <FileInput source="value" validate={required()} placeholder={<FileUploadPlaceholder />} multiple={true} accept={'application/pdf'} dropZoneClassName={classes.dropZone} fullWidth formClassName="col-12" {...rest} >
                            //             <FileField source="src" title="title" target="_blank" onClick={getFile} />
                            //         </FileInput>
                            //     );
                            case 'file':
                                return (
                                    <FileInput source="fileValue" validate={required()} placeholder={<FileUploadPlaceholder />} multiple={false} accept={'application/pdf'} fullWidth formClassName="col-12" {...rest} >
                                        <FileField source="src" title="title" target="_blank" />
                                    </FileInput>
                                );
                            case 'image':
                                return (
                                    <FileInput source="fileValue" validate={required()} placeholder={<FileUploadPlaceholder />} multiple={false} accept={'image/*'} fullWidth formClassName="col-12" {...rest} >
                                        <FileField source="src" title="title" target="_blank" />
                                    </FileInput>
                                );
                            case 'text':
                            default:
                                return <TextInput source="value" validate={required()} fullWidth formClassName="col-12 col-md-4 offset-right-md-8" {...rest} />;
                            case 'html':
                                return <RichTextInput source="value" validate={required()} fullWidth {...rest} />
                            case 'email':
                                return <TextInput source="value" type="email" validate={[required(), email()]} fullWidth formClassName="col-12 col-md-4 offset-right-md-8" {...rest} />;
                            case 'number':
                                return <NumberInput source="value" validate={[required(), number()]} fullWidth formClassName="col-12 col-md-4 offset-right-md-8" {...rest} />;
                            case 'text-multi':
                                return <TextInput multiline source="value" validate={required()} fullWidth formClassName="col-12 col-md-4 offset-right-md-8" {...rest} />;
                        }
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    )
};

export default CustomEdit;
