export default {
    name: 'companies',
    resources: {
        name: "Azienda |||| Aziende",
        forcedCaseName: 'Azienda |||| Aziende',
        fields: {
            name: 'Ragione Sociale',
            firstName: 'Nome',
            lastName: 'Cognome',
            vatNumber: "Partita IVA",
            fiscalCode: 'Codice fiscale',
            companyAddressId: 'Company Address Id',
            telephone: 'Telefono',
            email: 'Email',
            pec: 'PEC',
            bankAccount: 'BankAccount',
            destinationCode: 'Codice di destinazione',
            userId: 'User',
            vatRegimeId: 'Regime IVA Id',
            updatedAt: 'Aggiornato il',
            companyAddress: 'Indirizzo Sede Legale',
            vatRegime: 'Regime IVA',
        },
    },
    global: {
        menu: {
            name: 'Aziende',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Azienda "%{title}"',
        },
        action: {
        },
    },
};