import { memo, useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';

import {
    Stack,
    Typography,
} from '@mui/material';

import { CommonProps } from './index'
import { Utils } from 'admin/core';

interface ClockProps extends CommonProps {
}

export const Clock = memo((props: ClockProps) => {
    const theme = useTheme();
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");

    useEffect(() => {
        setDateTime();
        let secTimer = setInterval(setDateTime, 1000)
        return () => clearInterval(secTimer);
    }, []);

    const setDateTime = () => {
        var dateParts = Utils.GetDateParts(new Date(), "it", true)
        setDate(`${dateParts?.day}/${dateParts?.month}/${dateParts?.year}`)
        setTime(`${dateParts?.hour}:${dateParts?.minute}:${dateParts?.second}`)
    }

    return (
        <Stack
            id="clock"
            spacing={0}
            justifyContent="center"
            alignItems="center"
            sx={{
                padding: `${theme.spacing(.5)} ${theme.spacing(1)}`,
                borderRadius: theme.spacing(1),
                '&:hover': {
                    transition: 'all .2s ease-in-out',
                    backgroundColor: 'rgba(255,255,255,.8)',
                }
            }}
        >
            <Typography variant="body1" color={theme => theme.palette.common.black} fontSize={11} textAlign='center'>
                {time}
            </Typography>

            <Typography variant="body1" color={theme => theme.palette.common.black} fontSize={11} textAlign='center'>
                {date}
            </Typography>
        </Stack>
    )
})