import lodashMemoize from 'lodash/memoize';
import { regex } from 'react-admin';

/* eslint-disable no-underscore-dangle */
/* @link http://stackoverflow.com/questions/46155/validate-email-address-in-javascript */
const TEL_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.\\\/]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/;
const IBAN_REGEX = /^(?:(?:IT|SM)[0-9]{2}[A-Za-z][0-9]{10}[0-9A-Za-z]{12}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/;

type Memoize = <T extends (...args: any[]) => any>(
    func: T,
    resolver?: (...args: any[]) => any
) => T;

// If we define validation functions directly in JSX, it will
// result in a new function at every render, and then trigger infinite re-render.
// Hence, we memoize every built-in validator to prevent a "Maximum call stack" error.
const memoize: Memoize = (fn: any) =>
    lodashMemoize(fn, (...args) => JSON.stringify(args));

/**
 * Telephone validator
 *
 * Returns an error if the value is not a valid telephone number
 *
 * @param {string|Function} message
 *
 * @example
 *
 * const telValidators = [tel('Must be an tel')];
 * <TextInput name="tel" validate={telValidators} />
 */
export const tel = memoize((message = 'ra.validation.tel') =>
    regex(TEL_REGEX, message)
);

/**
 * IBAN validator
 *
 * Returns an error if the value is not a valid IBAN string
 *
 * @param {string|Function} message
 *
 * @example
 *
 * const telValidators = [tel('Must be an tel')];
 * <TextInput name="tel" validate={telValidators} />
 */
export const iban = memoize((message = 'ra.validation.iban') =>
    regex(IBAN_REGEX, message)
);