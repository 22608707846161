import { PureComponent } from "react";

class CustomizedAxisTick extends PureComponent {
    render() {
        const { x, y, stroke, payload, color, data }: any = this.props;
        const { index = 0 } = payload;
        const textColor = data && color ? color : '#666';

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill={textColor} transform="rotate(-20) scale(.75)">
                    {payload.value}
                </text>
            </g>
        );
    }
}

export default CustomizedAxisTick;