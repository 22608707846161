export default {
    name: 'settings',
    resources: {
        name: 'Impostazione |||| Impostazioni',
        forcedCaseName: 'Impostazione |||| Impostazioni',
        fields: {
            key: "Chiave",
            value: "Valore",
            fileValue: 'File',
            settingTypesId: 'Tipi di impostazione Id',
            updatedAt: 'Aggiornato il',
            settingTypes: 'Tipi di impostazione',
        },
    },
    global: {
        menu: {
            name: 'Impostazioni',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Impostazione "%{title}"',
        },
        action: {
        },
    },
};