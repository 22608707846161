import React, { Children, ReactNode } from 'react';
import PropTypes from 'prop-types';

import {
    TopToolbar,
    BulkDeleteWithConfirmButton,
    BulkExportButton,
    MutationMode,
    useListContext,
    usePermissions,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { Utils } from 'admin/core';

export interface ListBulkActionsProps {
    children?: ReactNode | ReactNode[] | null;
    disabled?: boolean;
    hasDeleteWithConfirm?: boolean;
    hasExport?: boolean;
    mutationMode?: MutationMode;
}

const ListBulkActions = (props: ListBulkActionsProps) => {
    const {
        disabled = false,
        hasDeleteWithConfirm = true,
        hasExport = true,
        children = [],
        mutationMode = 'pessimistic',
        ...rest
    } = props;

    const { permissions } = usePermissions();
    const listProps = useListContext();

    const { isLoading, total } = listProps;

    return (
        <TopToolbar>
            {hasDeleteWithConfirm && <BulkDeleteWithConfirmButton {...rest} mutationMode={mutationMode} disabled={isLoading || total === 0} />}
            {hasExport && <BulkExportButton {...rest} disabled={isLoading || total === 0} />}
            {Children.map(children, (child: any, index) => {
                const { disabled: disabledChild, ...childProps } = child.props

                const commonProps = {
                    key: index,
                    context: 'button',
                    disabled: disabled ||
                        isLoading ||
                        (disabledChild !== null && Utils.IsFunction(disabledChild) && disabledChild(total, isLoading, permissions)) ||
                        (Utils.IsBoolean(disabledChild) && disabledChild)
                }

                return Utils.CreateOrCloneElement(child, { ...commonProps, ...childProps })
            })}
        </TopToolbar>
    )
};

ListBulkActions.propTypes = {
    disabled: PropTypes.bool,
    hasDeleteWithConfirm: PropTypes.bool,
    hasExport: PropTypes.bool,
    children: PropTypes.node,
    mutationMode: PropTypes.oneOf(['pessimistic', 'optimistic', 'undoable']),
};

ListBulkActions.displayName = 'ListBulkActions';

export default ListBulkActions;