import { useMediaQuery, Theme } from '@mui/material';

import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    SearchInput,
    BooleanField,
} from 'react-admin';

import {
    ShowButton,
    ListActions,
    ListBulkActions,
    ListRowActions
} from 'admin/components';

const filters = [
    <SearchInput source="q" alwaysOn />,
    // <BooleanInput source="xxx" />,
    // <ReferenceInput source="xxxId" reference="xxx" label="resources.xxx.fields.xxx">
    //     <SelectInput source="xxx" />
    // </ReferenceInput>,
    //<NumberInput source="xxx" />,
];

const CustomList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            actions={<ListActions hasCreate={false} />}
            filters={filters}
            filterDefaultValues={{}}
            sort={{ field: 'order', order: 'ASC' }}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${record.code})`}
                    secondaryText={record => `${record.label}`}
                    linkType="edit"
                />
            ) : (
                <Datagrid bulkActionButtons={<ListBulkActions hasDeleteWithConfirm={false} />} optimized>
                    <TextField source="label" />
                    <TextField source="code" />
                    <BooleanField source="bankAccount" />
                    <ListRowActions>
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )}
        </List>
    );
};

export default CustomList;
