export default {
    name: 'vatregimes',
    resources: {
        name: 'Vat Regime |||| Vat Regimes',
        forcedCaseName: 'Vat Regime |||| Vat Regimes',
        fields: {
            label: 'Label',
            code: 'Code',
            vat: 'Vat',
            order: 'Order',
            updatedAt: 'Updated At',
            companies: 'Companies',
        },
    },
    global: {
        menu: {
            name: 'Vat Regimes',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Vat Regime "%{title}"',
        },
        action: {
        },
    },
};