export default {
    name: 'settings',
    resources: {
        name: 'Setting |||| Settings',
        forcedCaseName: 'Setting |||| Settings',
        fields: {
            key: 'Key',
            value: 'Value',
            fileValue: 'File Value',
            settingTypesId: 'Setting Types Id',
            updatedAt: 'Updated At',
            settingTypes: 'Setting Types',
        },
    },
    global: {
        menu: {
            name: 'Settings',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Setting "%{title}"',
        },
        action: {
        },
    },
};