import { Utils } from '../../core';

import * as tables from './Tables';
import * as settings from "./Settings";
import * as userprofile from "./userprofile";

export default {

    resources: {
        ...Utils.BuildTranslations(tables, "resources"),
        ...Utils.BuildTranslations(settings, "resources"),
        ...Utils.BuildTranslations(userprofile, "resources"),
    },

    ...Utils.BuildTranslations(tables, "global"),
    ...Utils.BuildTranslations(settings, "global"),
    ...Utils.BuildTranslations(userprofile, "global"),

    pos: {
        language: 'Lingua',
        languages: {
            it: 'Italiano',
            en: 'Inglese',
        },
        configurations: {
            title: 'Configurazione',
            updated: 'Configurazione aggiornata'
        },
        theme: {
            name: 'Tema',
            light: 'Chiaro',
            dark: 'Scuro',
        },
        auth: {
            login_failure: 'Autenticazione fallita, riprovare.',
            not_allowed: 'Permessi insufficienti per accedere',
            reset_password_required: 'Richiesto reset password. Usare il link ricevuto per e-mail.',
            password_not_resetted: 'Non è stato possibile resettare la password',
            mail_not_found: 'La mail non risulta inserita a sistema',
            reset_password_failure: 'Reset della password fallito, riprovare',
        },
        menu: {
            tables: "Tabelle",
            profile: "Profilo",
            settings: "Impostazioni",
            configurations: 'Configurazione',
            system: 'Sistema',
            work: 'Lavoro'
        },
        media_manager: {
            title: 'Media Gallery',
            tab1: 'Seleziona',
            tab2: 'Carica',
            title_field: 'Seleziona file',
            saved: "Media salvato",
            saved_many: "Media salvati",
            not_saved: 'Media non salvato nel Db. Verrà utilizzato il base64'
        },
        page_not_found: 'La pagina non esiste',
        dashboard: {
            revenues: {
                title: 'Fatturato',
                dialogTitle: 'Dettaglio del %{fiscalYear}'
            }
        },
        fileupload: {
            errors: {
                ["file-invalid-type"]: "Il tipo di file deve essere uno tra: %{type}",
                ["file-too-large"]: "Il file è più grande di %{size}"
            }
        },
        enums: {
            InvoiceSubmissionOutcomeType: {
                Pending: "In corso",
                Failed: "Fallito",
                Success: "Completato"
            },
            RoleType: {
                admin: "Admin",
                user: "User",
            }
        },
        common: {
            isDeleted: 'Includi Cancellati'
        },
        aside: {
            infos: {
                title: "Informazioni",
                createdAt: 'Creato il',
                createdBy: 'Creato da',
                updatedAt: 'Aggiornato il',
                updatedBy: 'Aggiornato da',
                deletedAt: 'Rimosso il',
                deletedBy: 'Rimosso da',
            }
        }
    },
}