import { useMediaQuery, Theme } from '@mui/material';

import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    SearchInput,
    RichTextField,
    FileField,
    EmailField,
    NumberField,
    useRecordContext,
} from 'react-admin';

import {
    EditButton,
    DeleteWithConfirmButton,
    ShowButton, 
    ListActions, 
    ListBulkActions, 
    ListRowActions 
} from 'admin/components';

import { Sample } from 'admin/types';

const filters = [
    <SearchInput source="q" alwaysOn />,
    // <BooleanInput source="xxx" />,
    // <ReferenceInput source="xxxId" reference="xxx" label="resources.xxx.fields.xxx">
    //     <SelectInput source="xxx" />
    // </ReferenceInput>,
    //<NumberInput source="xxx" />,
];

const CustomField = () => {
    const record = useRecordContext<Sample>();

    switch (record.settingTypes.testo) {
        // case 'image-multi':
        //     return <span>IMAGE-MULTI</span>;
        // case 'file-multi':
        //     return <span>FILE-MULTI</span>;
        case 'image':
        case 'file':
            return <FileField source="value" />;
        case 'html':
            return <RichTextField source="value" />;
        case 'email':
            return <EmailField source="value" />;
        case 'number':
            return <NumberField source="value" />
        case 'text':
        case 'text-multi':
        default:
            return <TextField source="value" />
    }
}

const CustomList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            actions={<ListActions />}
            filters={filters}
            filterDefaultValues={{}}
            sort={{ field: 'name', order: 'ASC' }}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${record.name})`}
                    secondaryText={record => `${record.dob}`}
                    tertiaryText={record => record.enabled}
                    linkType="edit"
                />
            ) : (
                <Datagrid bulkActionButtons={<ListBulkActions />} optimized>
                    <TextField source="key" />
                    <CustomField />
                    <ListRowActions>
                        <EditButton />
                        <DeleteWithConfirmButton />
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )}
        </List>
    );
};

export default CustomList;
