import { useMediaQuery, Theme } from '@mui/material';

import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    SearchInput,
    NumberField,
    DateField,
    ReferenceField,
    NumberInput,
} from 'react-admin';

import {
    DeleteWithConfirmButton,
    EditButton,
    ShowButton,
    ListActions,
    ListBulkActions,
    ListRowActions
} from 'admin/components';

import CustomShowExpand from './CustomShowExpand';
import { GenerateInvoicePdfButton, SendInvoiceButton } from 'admin/views/Common';
import { InvoiceSubmissionOutcomeType } from 'admin/types';

const filters = [
    <SearchInput source="q" alwaysOn />,
    <NumberInput source="fiscalYear" alwaysOn />
    // <BooleanInput source="xxx" />,
    // <ReferenceInput source="xxxId" reference="xxx" label="resources.xxx.fields.xxx">
    //     <SelectInput source="xxx" />
    // </ReferenceInput>,
    //<NumberInput source="xxx" />,
];

const CustomList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            actions={<ListActions />}
            filters={filters}
            filterDefaultValues={{ fiscalYear: new Date().getFullYear() }}
            sort={{ field: 'number', order: 'DESC' }}
            perPage={30}
            queryOptions={{ meta: { expand: 'currentInvoiceSubmission' } }}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${record.name})`}
                    secondaryText={record => `${record.dob}`}
                    tertiaryText={record => record.enabled}
                    linkType="edit"
                />
            ) : (
                <Datagrid bulkActionButtons={<ListBulkActions />} optimized expand={<CustomShowExpand />} isRowExpandable={() => true} expandSingle>
                    <NumberField source="number" />
                    <DateField source="emissionDate" />
                    <TextField source="fiscalYear" />
                    <ReferenceField source="companyId" reference="companies" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <NumberField source="amount" locales="it-IT" options={{ style: 'currency', currency: 'EUR' }} />
                    <ReferenceField source="invoiceTypeId" reference="invoicetypes" link={false}>
                        <TextField source="label" />
                    </ReferenceField>
                    <NumberField source="stampAmount" locales="it-IT" options={{ style: 'currency', currency: 'EUR' }} />
                    <ReferenceField source="paymentTypeId" reference="paymenttypes" link={false}>
                        <TextField source="label" />
                    </ReferenceField>
                    <ListRowActions>
                        <GenerateInvoicePdfButton />
                        <SendInvoiceButton />
                        <EditButton disabledFn={record => record.currentInvoiceSubmission?.outcome === InvoiceSubmissionOutcomeType.Pending || record.currentInvoiceSubmission?.outcome === InvoiceSubmissionOutcomeType.Success} />
                        <DeleteWithConfirmButton disabledFn={record => record.currentInvoiceSubmission?.outcome === InvoiceSubmissionOutcomeType.Pending || record.currentInvoiceSubmission?.outcome === InvoiceSubmissionOutcomeType.Success} />
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )}
        </List>
    );
};

export default CustomList;
