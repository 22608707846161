import groups from './groups';

import * as tables from './Tables';
import * as settings from './Settings';

var tablesResources = Object.keys(tables.default).map(function (table) {
    const result = { group: {} };
    if (tables.default[table].group) {
        result.group = groups[tables.default[table].group]
        delete tables.default[table].group;
    }
    return { ...result, ...tables.default[table] }
});

var settingsResources = Object.keys(settings.default).map(function (setting) {
    return { group: groups.settings, ...settings.default[setting] }
});

const resources = [
    ...tablesResources,
    //...settingsResources,
]

export default resources;
