export enum Roles {
    Admin = "admin",
    User = "user",
} 

export enum RoleGroups {
    Administrators = "administrators",
    Users = "users",
}

export enum UserStatusType {
    Active = "Active",
    NotActive = "NotActive",
}

export enum ProviderType {
    Local = "Local",
    Microsoft = "Microsoft",
    Google = "Google",
    Twitter = "Twitter",
    Instagram = "Instagram",
    Facebook = "Facebook",
    Unknown = "Unknown",
}

export enum MediaType {
    Generic = "Generic",
    DefaultProfilePicture = "DefaultProfilePicture",
}

export enum InvoiceSubmissionOutcomeType {
    NotSent = "NotSent",
    Pending = "Pending",
    Failed = "Failed",
    Success = "Success"
}