import { ReactNode } from 'react';

import { DialogProps, IconButton } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';

import { withStyles } from "@mui/styles";

interface Props {
    open?: boolean;
    title?: string | ReactNode;
    actions?: ReactNode[] | null;
    onOpen?: () => void;
    onClosed?: () => void;
    children?: ReactNode | null;
    minWidth?: string | number | undefined;
    hideClose?: boolean;
    preventClose?: boolean;
}

export type FormDialogProps = Props & Omit<DialogProps, "open">;

export const FormDialogTitle = (props) => {
    const { children, onClose, hideClose, ...other } = props;
    return (
        <MuiDialogTitle
            sx={{
                margin: 0,
                padding: theme => theme.spacing(2),
                backgroundColor: theme => theme.palette.secondary.main,
                color: theme => theme.palette.getContrastText(theme.palette.secondary.main),
            }}
            {...other}
        >
            {children}
            {!hideClose ? (
                <IconButton
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: theme => theme.spacing(1),
                        top: theme => theme.spacing(2),
                        color: theme => theme.palette.getContrastText(theme.palette.secondary.main),
                    }}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
}

export const FormDialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        borderTop: 'none',
    },
}))(MuiDialogContent)

export const FormDialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions)