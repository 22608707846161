import { useRef, useState, useEffect, ReactElement } from 'react';

import {
    Box,
    LinearProgress,
    Tooltip,
    Typography,
} from '@mui/material';

import Icon from '@mui/icons-material/InfoOutlined';

import { linearProgressClasses } from '@mui/material/LinearProgress';
import { SkillType } from 'publicapp/types';
import { useTranslate } from 'react-admin';

interface SkillBarProps {
    /** The type of the skill. */
    type: SkillType;
    /** The name of the skill. */
    name?: string;
    /** The skill level. */
    level?: number;
    /** The background color. */
    color?: string;
    /** The component height. */
    height?: number;
    /** The label min width. */
    labelMinWidth?: number | string;
    /** User defined function that takes a skill parameter and returns a React component to display instead of the default label. */
    customLabel?: ReactElement;
}

/**
 * Displays a animated skill bar
 */
export default function SkillBar(props: SkillBarProps) {
    const {
        name = null,
        type,
        level: levelProp = -1,
        color: colorProp = null,
        height = 15,
        labelMinWidth = 150,
        customLabel = null
    } = props;

    const translate = useTranslate();
    const [intervalId, setIntervalId] = useState<any>(null);
    const [progress, setProgress] = useState(0);

    let level = levelProp;

    if ((!level || level === -1) && type.level)
        level = type.level;

    if (level < 0)
        level = 0;

    useEffect(() => {
        const _intervalId = setInterval(() => {
            setProgress((prevProgress) => prevProgress < level ? prevProgress + 5 : level);
        }, 100);

        setIntervalId(_intervalId)

        return () => {
            clearInterval(_intervalId);
        };
    }, []);

    useEffect(() => {
        if (intervalId && progress >= level)
            clearInterval(intervalId);
    }, [progress]);

    let color = colorProp;

    if (!color) {
        if (level <= 20)
            color = "#EE8238";
        else if (level > 20 && level <= 40)
            color = "#F19A56";
        else if (level > 40 && level <= 60)
            color = "#F4D94B";
        else if (level > 60 && level <= 80)
            color = "#5FB5C1";
        else
            color = "#3F8FB2";
    }

    return (
        <Box id={name || type.name} sx={{ width: '100%', py: .5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ minWidth: labelMinWidth, display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    {
                        customLabel ||
                        <Typography variant="body2" color="text.secondary">
                            {translate(type.name)}
                        </Typography>
                    }
                    {
                        type?.allVersion && type?.allVersion.length > 0 &&
                        <Tooltip
                            title={type?.allVersion.join(' | ')}
                            placement="top"
                        >
                            <Icon sx={{ ml: 1, fontSize: 16 }} />
                        </Tooltip>
                    }
                </Box>
                <Box sx={{ width: '100%', ml: 1 }}>
                    <LinearProgress
                        sx={{
                            height: height,
                            borderRadius: 5,
                            [`&.${linearProgressClasses.colorPrimary}`]: {
                                backgroundColor: theme => theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
                            },
                            [`& .${linearProgressClasses.bar}`]: {
                                borderRadius: 5,
                                backgroundColor: color,
                            },
                        }}
                        variant="determinate"
                        value={progress}
                    />
                </Box>
            </Box>
        </Box>
    );
}