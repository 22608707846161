import { ListGuesser, EditGuesser, ShowGuesser } from 'react-admin';

import Icon from '@mui/icons-material/Receipt';
import CustomCreate from './CustomCreate'
import CustomEdit from './CustomEdit';
import CustomList from './CustomList';
import CustomShow from './CustomShow';

import { grey } from '@mui/material/colors';

export default {
    list: CustomList,
    create: CustomCreate,
    edit: CustomEdit,
    show: CustomShow,
    icon: Icon,
    iconColor: grey[800],
};