export default {
    name: "users",
    resources: {
        name: 'User |||| Users',
        forcedCaseName: 'User |||| Users',
        fields: {
            identityId: "Identity Id",
            firstName: "First Name",
            lastName: "Last Name",
            userName: "User Name",
            email: "Email",
            providerId: "Provider Id",
            providerType: "Provider",
            profilePhotoUrl: "Profile Photo",
            roles: "Roles"
        },
    },
    global: {
        menu: {
            name: 'Users',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'User "%{title}"',
        },
        action: {
        },
    },
};