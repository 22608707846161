import { Grid } from '@mui/material';

import {
    Edit,
    SimpleForm,
    required,
    TextInput,
    ReferenceInput,
    SelectInput,
    email,
    FormDataConsumer,
} from 'react-admin';

import { EditActions, EditToolbar } from 'admin/components';

import CustomTitle from './CustomTitle';
import { transformData } from './transformData';

const CustomEdit = () => (
    <Edit redirect="list" actions={<EditActions />} title={<CustomTitle />} transform={transformData}>
        <SimpleForm toolbar={<EditToolbar />}>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={4}>
                    <TextInput source="name" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextInput source="firstName" fullWidth />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextInput source="lastName" fullWidth />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextInput source="vatNumber" fullWidth />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextInput source="fiscalCode" fullWidth />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <ReferenceInput source="companyAddressId" reference="companyAddresses">
                        <SelectInput optionText={record => `[${record.name}] ${record.addressLine1} - ${record.addressLevel3} (${record.addressLevel2})`} fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextInput source="telephone" fullWidth />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextInput source="email" validate={email()} fullWidth />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextInput source="pec" validate={email()} fullWidth />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextInput source="destinationCode" fullWidth />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <ReferenceInput source="userId" reference="users">
                        <SelectInput optionText="displayName" fullWidth />
                    </ReferenceInput>
                </Grid>

                <FormDataConsumer>
                    {({ formData }) => formData.userId &&
                        <>
                            <Grid item xs={12} sm={6}>
                                <ReferenceInput source="vatRegimeId" reference="vatRegimes">
                                    <SelectInput optionText={record => `${record.code} - ${record.label}`} fullWidth />
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextInput source="bankAccount" fullWidth />
                            </Grid>
                        </>
                    }
                </FormDataConsumer>

            </Grid>
        </SimpleForm>
    </Edit>
);

export default CustomEdit;
