import decodeJwt from 'jwt-decode';

const identitySchema = {
    Role: "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
    EmailAddress: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
    GivenName: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname",
    Name: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
    NameIdentifier: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier",
    Surname: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname",
}

export interface AccessToken {
    token: string;
    expiresIn: number;
};

export interface DecodedToken {
    fullName: string;
    name_identifier: string;
    family_name: string;
    email: string;
    given_name: string;
    rol: string[];
    [key: string]: any;
};

const jwtProviderManager = () => {
    let isRefreshing: Promise<boolean | Response> | null = null;
    let logoutEventName: string = 'ra-logout';
    let refreshEndpoint: string = 'refresh-token';
    let refreshTimeOutId: number;

    const tokenCookie = "token";
    const refreshTokenCookie = "refresh_token";

    const setLogoutEventName = name => logoutEventName = name;
    const setRefreshTokenEndpoint = endpoint => refreshEndpoint = endpoint;

    const abortRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const waitForTokenRefresh = async () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }
        await isRefreshing;
        isRefreshing = null;
        return true;
    }

    // The method make a call to the refresh-token endpoint
    // If there is a valid cookie, the endpoint will set a fresh jwt in memory.
    const getRefreshedToken = () => {
        const request = new Request(refreshEndpoint, {
            method: 'POST',
            body: JSON.stringify({ accessToken: getToken(), refreshToken: getRefreshToken() }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        if (isRefreshing === null) {
            isRefreshing = fetch(request)
                .then(response =>
                    response.json().then(text => ({
                        status: response.status,
                        statusText: response.statusText,
                        headers: response.headers,
                        body: text,
                    }))
                ).then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        ereaseToken();
                        global.console.log('Token renewal failure');
                        return { token: null };
                    }
                    return response.body;
                })
                .then(({ accessToken, refreshToken }) => {
                    if (accessToken) {
                        setToken(accessToken, refreshToken);
                        return true;
                    }
                    ereaseToken();
                    return false;
                });
        }

        return isRefreshing;
    };

    const getToken = (decoded: boolean = false) => {
        let token = localStorage.getItem(tokenCookie);

        if (token && decoded) {
            let decodedToken: DecodedToken = decodeJwt(token);

            decodedToken.fullName = decodedToken[identitySchema.Name];
            decodedToken.name_identifier = decodedToken[identitySchema.NameIdentifier];
            decodedToken.family_name = decodedToken[identitySchema.Surname];
            decodedToken.email = decodedToken[identitySchema.EmailAddress];
            decodedToken.given_name = decodedToken[identitySchema.GivenName];

            let role = decodedToken[identitySchema.Role] || '';

            decodedToken.rol = Array.isArray(role) ? role.map(x => x.toLowerCase()) : [role.toLowerCase()];

            let additionalInfos: any = {};

            if (decodedToken.additional_infos) {
                additionalInfos = JSON.parse(decodedToken.additional_infos);
            }

            return decodedToken;
        }
        else {
            return token;
        }
    };

    const getRefreshToken = () => localStorage.getItem(refreshTokenCookie);

    const setToken = (accessToken: AccessToken, refreshToken: string) => {
        const { token } = accessToken;
        localStorage.setItem(tokenCookie, token);
        localStorage.setItem(refreshTokenCookie, refreshToken);
        return true;
    };

    const ereaseToken = () => {
        localStorage.removeItem(tokenCookie);
        localStorage.removeItem(refreshTokenCookie);
        abortRefreshToken();
        window.localStorage.setItem(logoutEventName, Date.now().toString());
        return true;
    }

    // // This listener will allow to disconnect a session of ra started in another tab
    // window.addEventListener('storage', (event) => {
    //     if (event.key === logoutEventName) {
    //         jwtProvider = null;
    //     }
    // });

    return {
        ereaseToken,
        getRefreshedToken,
        getToken,
        setLogoutEventName,
        setRefreshTokenEndpoint,
        setToken,
        waitForTokenRefresh,
    }
};

export default jwtProviderManager();