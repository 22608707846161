import { useMediaQuery, Theme } from '@mui/material';

import MapIcon from '@mui/icons-material/LocationOn';

import { Tooltip } from '@mui/material';

import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    SearchInput,
    FunctionField,
} from 'react-admin';

import {
    EditButton,
    DeleteWithConfirmButton,
    ShowButton,
    ListActions,
    ListBulkActions,
    ListRowActions
} from 'admin/components';

const filters = [
    <SearchInput source="q" alwaysOn />,
    // <BooleanInput source="xxx" />,
    // <ReferenceInput source="xxxId" reference="xxx" label="resources.xxx.fields.xxx">
    //     <SelectInput source="xxx" />
    // </ReferenceInput>,
    //<NumberInput source="xxx" />,
];

const CustomList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            actions={<ListActions />}
            filters={filters}
            filterDefaultValues={{}}
            sort={{ field: 'name', order: 'ASC' }}
            storeKey={false}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${record.name})`}
                    secondaryText={record => `${record.dob}`}
                    tertiaryText={record => record.enabled}
                    linkType="edit"
                />
            ) : (
                <Datagrid bulkActionButtons={<ListBulkActions hasDeleteWithConfirm={false} />} optimized >
                    <TextField source="name" />
                    <TextField source="companyName" />
                    <TextField source="addressLine1" />
                    <TextField source="addressLine2" />
                    <TextField source="addressLine3" />
                    <TextField source="country" />
                    <TextField source="postalCode" />
                    <TextField source="addressLevel1" />
                    <TextField source="addressLevel2" />
                    <TextField source="addressLevel3" />
                    <FunctionField source="placeId" textAlign='center' render={record => record.placeId && <Tooltip title={`${record.lat};${record.lng}`}><MapIcon /></Tooltip>} />
                    <ListRowActions>
                        <EditButton />
                        <DeleteWithConfirmButton disabledFn={record => record.company != null} />
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )}
        </List>
    );
};

export default CustomList;
