import { useEffect, useState } from 'react';

import { Box, Paper, alpha } from '@mui/material';

import { useDataProvider, useNotify, useTranslate } from 'react-admin';

import { DashboardCard, Dialog } from 'admin/components';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { CustomizedAxisTick, CustomizedTooltip, NoData, formatAsCurrency, mapData } from 'admin/components/layout/Dashboard/ChartsUtils';
import { useTheme } from '@mui/material';

export interface RevenuesCardProps {
    mainLoading?: boolean;
    reloadDashboard?: () => void;
}

export const RevenuesCard = (props: RevenuesCardProps) => {
    const {
        mainLoading = false,
        reloadDashboard = () => { },
    } = props;

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [revenues, setRevenues] = useState<any>([]);
    const [revenueDetails, setRevenueDetails] = useState<any>([]);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState<any>(null);

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const translate = useTranslate();

    useEffect(() => {
        setIsLoading(mainLoading);

        dataProvider.get(`api/dashboard/revenues`).then((response) => {
            const { data } = response || { data: [] };
            if (data)
                setRevenues(mapData(data));
        }).catch(error => {
            notify('Error', { type: 'warning' })
            setRevenues([]);
        }).finally(() => {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        });
    }, [mainLoading]);

    const handleDialogClose = () => {
        setSelectedFiscalYear(null);
        setOpen(false);
    };

    const handleClick = (data, colorIndex) => {
        setIsLoading(true);

        setSelectedFiscalYear(data.xAxisLabel);

        dataProvider.get(`api/dashboard/revenues/${data.xAxisLabel}`).then((response) => {
            const { data } = response || { data: [] };

            if (data)
                setRevenueDetails(mapData(data));

            setOpen(true);
        }).catch(error => {
            notify('Error', { type: 'warning' })
            setRevenues([]);
        }).finally(() => {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        });
    };

    return (
        <Paper sx={{ padding: theme => theme.spacing(2), backgroundColor: alpha(theme.palette.primary.light, 0.5) }}>
            <DashboardCard
                title={translate('pos.dashboard.revenues.title')}
                titleTypographyProps={{ align: 'center' }}
                useChildrenAsContent
                loading={isLoading}
            >
                <Box sx={{ width: '100%', height: 500 }} display="flex" justifyContent="center">
                    {
                        revenues && revenues.filter(x => x.globalValue > 0).length > 0 ?
                            <ResponsiveContainer>
                                <BarChart
                                    data={revenues}
                                    margin={{
                                        top: 30,
                                        right: 30,
                                        left: 5,
                                        bottom: 30,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="xAxisLabel" interval={0} tick={<CustomizedAxisTick />} />
                                    <YAxis interval={0} allowDecimals={false} domain={[0, 'dataMax']} width={110} tickFormatter={formatAsCurrency} />
                                    <Tooltip content={<CustomizedTooltip isCurrency />} cursor={{ fill: theme.palette.primary.light }} />
                                    <Bar
                                        dataKey="globalValue"
                                        type="number"
                                        fill={theme.palette.secondary.main}
                                        stackId={0}
                                        maxBarSize={50}
                                        onClick={(data, idx) => handleClick(data, idx)}
                                    />
                                </BarChart>
                            </ResponsiveContainer> :
                            <NoData />
                    }
                </Box>
            </DashboardCard>
            <Dialog open={open} onClosed={handleDialogClose} title={translate('pos.dashboard.revenues.dialogTitle', { fiscalYear: selectedFiscalYear })}>
                <Box sx={{ width: '75vw', height: '65vh' }} display="flex" justifyContent="center">
                    {
                        revenueDetails && revenueDetails.filter(x => x.globalValue > 0).length > 0 ?
                            <ResponsiveContainer>
                                <BarChart
                                    data={revenueDetails}
                                    margin={{
                                        top: 30,
                                        right: 30,
                                        left: 5,
                                        bottom: 30,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="xAxisLabel" interval={0} tick={<CustomizedAxisTick />} />
                                    <YAxis interval={0} allowDecimals={false} domain={[0, 'dataMax']} width={110} tickFormatter={formatAsCurrency} />
                                    <Tooltip content={<CustomizedTooltip isCurrency />} cursor={{ fill: theme.palette.primary.light }} />
                                    <Bar
                                        dataKey="globalValue"
                                        type="number"
                                        fill={theme.palette.secondary.main}
                                        stackId={0}
                                        maxBarSize={50}
                                    />
                                </BarChart>
                            </ResponsiveContainer> :
                            <NoData />
                    }
                </Box>
            </Dialog>
        </Paper>
    )
}