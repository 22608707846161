import { Utils } from 'admin/core';
import { Sector } from 'recharts';

export const renderCustomizedLabel = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, rotationAngle = 180, ...rest } = props;

    const RADIAN = Math.PI / rotationAngle;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (percent === 0) return null;
    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export const renderColorfulLegendText = (dataKey, entry, index, data) => {
    const { color, payload } = entry;
    const { xAxisLabel, globalValue, stackId } = payload;

    if (data && data.length > 0 && data[stackId].otherLabels && data[stackId].otherLabels.length > 0) {
        var label = data[stackId].otherLabels[index];
        var value = data.map(x => Utils.DeepFind(x, dataKey) || 0).reduce((a, b) => a + b, 0);
        return <span style={{ color }}>{`${label} (${value || 0})`}</span>;
    }
    else {
        return <span style={{ color }}>{`${xAxisLabel} (${globalValue || 0})`}</span>;
    }
};

export const renderActiveShape = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, rotationAngle = 180, ...rest } = props;

    const RADIAN = Math.PI / rotationAngle;
    const verticalLineLenght = 15;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 5) * cos;
    const sy = cy + (outerRadius + 5) * sin;
    const mx = cx + (outerRadius + verticalLineLenght) * cos;
    const my = cy + (outerRadius + verticalLineLenght) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    var formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
    });

    var valueFormatted = formatter.format(parseFloat(value).toFixed(2));

    return (
        <g>
            <text x={cx} y={cy - 10} dy={8} textAnchor="middle" fill={fill}>
                {payload.xAxisLabel}
            </text>
            <text x={cx} y={cy + 10} dy={8} fontSize=".9em" textAnchor="middle" fill="#999">
                {`${(percent * 100).toFixed(2)}%`}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${valueFormatted}`}</text>
            {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`${(percent * 100).toFixed(2)}%`}
            </text> */}
        </g>
    );
};

export const mapData = (data) => {
    let finalData = [];
    if (data.length > 0 && data.filter(x => x.hasOwnProperty("values") && !Utils.IsEmpty(x.values)).length > 0) {
        finalData = data.map(x => {
            var xAxisLabel = x.label;
            var globalValue = x.value;
            var otherLabels = [];
            var otherNames = [];
            var otherData = {};
            
            if (x.values && x.values.length > 0) {
                otherLabels = x.values.filter(x => x !== null).map(x => {
                    otherData[Utils.Camelize(x.name)] = x.value;
                    otherNames.push(Utils.Camelize(x.name));
                    return x.label;
                }) || [];
            }

            return {
                xAxisLabel,
                globalValue,
                other: {
                    ...otherData
                },
                otherLabels,
                otherNames
            }
        });
    }
    else {
        finalData = data.map(x => ({ xAxisLabel: x.label, globalValue: x.value }));
    }

    return finalData;
}

export const formatAsCurrency = (value) => {
    if (value && Utils.IsNumber(value)) {
        var formatter = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR',
        });
        value = formatter.format(parseFloat(value).toFixed(2));
    }
    return value;
}