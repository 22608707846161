import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PublicApp from "./publicapp";
import AdminApp from "./admin";

const App = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/*" element={<PublicApp />} />
            <Route path="/admin/*" element={<AdminApp />} />
        </Routes>
    </BrowserRouter>
);

export default App;
