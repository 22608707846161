import { AppInfo } from 'publicapp/types';

import iconImg from '../img/apps/linkedin.png';

const appName = "linkedin";
const label = "apps.linkedin";

export const appInfo: AppInfo =
{
    name: appName,
    iconUrl: iconImg,
    label: label,
    toLink: 'https://www.linkedin.com/in/alessio-filippucci-17b94563/'
}