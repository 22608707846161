import React, { useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import classnames from 'classnames';
import FileUploadIcon from '@mui/icons-material/Publish';
import FileUploadedIcon from '@mui/icons-material/Check';
import { Typography } from '@mui/material';

import {
    FileInput,
    FileField,
    ImageInput,
    ImageField,
    useDataProvider,
    useNotify,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { Utils } from 'admin/core';

const useStyles = makeStyles(theme => ({
    dropZone: {
        width: '100%',
        borderRadius: 10,
        border: '3px dashed #bcbcbc',
        cursor: 'pointer',
        padding: theme.spacing(3),
        textAlign: 'center',
        background: '#e4e4e4',
        color: theme.palette.getContrastText('#e4e4e4'),
        '& p': {
            margin: 0,
        },
        '&.withfiles': {
            borderColor: '#33cc01',
        }
    },
    placeholderIcon: {
        fontSize: '3em',
        position: 'absolute',
        color: '#aeaeae',
        [theme.breakpoints.up('md')]: { left: '.5em' },
        [theme.breakpoints.down('md')]: { left: '.5em' },
        '&.uploaded': {
            color: '#33cc01',
        }
    },
    root: {
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    notExpired: {
        color: 'var(--gray)',
    },
    expired: {
        color: 'var(--black)',
    },
    expiring: {
        color: 'var(--danger)',
    },
}));

const Placeholder = ({ hasFiles, isMultiple }) => {
    const classes = useStyles();
    let label = !isMultiple ? "Carica o trascina documento" : "Carica o trascina documenti";
    let icon = <FileUploadIcon className={classes.placeholderIcon} />;

    if (hasFiles) {
        label = !isMultiple ? "Documento caricato" : "Documenti caricati";
        icon = <FileUploadedIcon className={classnames(classes.placeholderIcon, 'uploaded')} />;
    }

    return (
        <div className="d-flex justify-content-center align-items-center">
            {icon}
            <Typography variant="body1" display="inline">
                {label}
            </Typography>
        </div>
    )
}

export const CustomReferenceInput = props => {
    const classes = useStyles();
    const notify = useNotify();
    const acceptedDocs = props.acceptedDocs || 'image/*';
    const dataProvider = useDataProvider();

    const getFile = (src) => {
        let array = src.split("\\");
        if (array.length > 0) {
            dataProvider
                .getOne('getFile', { id: array[array.length - 1].split('.').slice(0, -1).join('.'), type: "clienti" })
                .then(response => {
                    if (response.data) {
                        Utils.ShowFile(response.data)
                    }
                    else {
                        notify("Documento non trovato o danneggiato", "warning")
                    }
                })
        }
    }

    if (props.onlyImage) {
        return (
            <ImageInput {...props} placeholder={<Placeholder />} accept={'image/png, image/jpeg'} dropZoneClassName={classes.dropZone}>
                <ImageField source="src" title="title" target="_blank" onClick={getFile} imageByUrlType={null} />
            </ImageInput>
        );
    }
    else {
        return (
            <FileInput {...props} placeholder={<Placeholder />} accept={acceptedDocs} dropZoneClassName={classes.dropZone}>
                <FileField source="src" title="title" target="_blank" onClick={getFile} />
            </FileInput>
        );
    }
};
