export default {
    name: "userprofile",
    resources: {
        name: 'User Profile',
        forcedCaseName: 'User Profile',
        fields: {
            firstName: "First Name",
            lastName: "Last Name",
            oldPassword: "Old Password",
            newPassword: "New Password",
            repeatNewPassword: "Repeat New Password",
        },
    },
    global: {
        menu: {
            name: 'User Profile',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'My Profile',
        },
        sections: {
            profile: 'Profile',
            changepassword: 'Change Password',
        },
        validation: {
            newPassword: 'Must not match the old password',
            repeatPassword: 'Must match new password',
        },
        action: {
            updated: 'Password updated',
            not_updated: 'Password not updated',
            wrong: 'Old password wrong',
            request_error: 'Failed request',
        },
    },
};