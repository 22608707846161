import { useListFilterContext, useResourceContext, useTranslateLabel } from 'react-admin';
import { Box, Switch, FormControlLabel } from '@mui/material';

interface Props {
    alwaysOn?: boolean;
    source: string;
    label?: string;
}

export const IncludeIsDeleted = (props: Props) => {
    const {
        source,
        label = "pos.common.isDeleted"
    } = props;

    const {
        filterValues,
        displayedFilters,
        setFilters,
    } = useListFilterContext();

    const resource = useResourceContext();
    const translateLabel = useTranslateLabel();

    const handleChange = () => {
        const newFilterValues = { ...filterValues };
        if (filterValues[source] === undefined) {
            newFilterValues[source] = false;
        } else {
            delete newFilterValues[source];
        }
        setFilters(newFilterValues, displayedFilters);
    };

    return (
        <Box>
            <FormControlLabel
                control={
                    <Switch
                        checked={filterValues[source] === undefined}
                        onChange={handleChange}
                        color="primary"
                        name="checkedC"
                    />
                }
                label={translateLabel({ source, label, resource })}
            />
        </Box>
    );
};