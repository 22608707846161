import {
    Toolbar,
    SaveButton,
    Edit,
    SimpleForm,
    PasswordInput,
    required,
    useDataProvider,
    useNotify,
    useLogout,
} from 'react-admin';

import { Box, Grid } from '@mui/material';
import { ApiException } from 'admin/types';

const EditToolbar = (props) => {
    const leftGroup = <></>;
    const rightGroup = <>
        <SaveButton />
    </>;

    return (
        <Toolbar {...props}>
            <Grid container>
                <Grid item xs={6}>
                    {leftGroup}
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                        {rightGroup}
                    </Box>
                </Grid>
            </Grid>
        </Toolbar>
    )
};

const newPasswordValidation = (value, allValues) => {
    const { oldPassword } = allValues;

    if (value === oldPassword) {
        return 'userprofile.validation.newPassword';
    }

    return undefined;
};

const repeatPasswordValidation = (value, allValues) => {
    const { newPassword } = allValues;

    if (value !== newPassword) {
        return 'userprofile.validation.repeatPassword';
    }

    return undefined;
};

const validateNewPassword = [required(), newPasswordValidation];
const validateRepeatPassword = [required(), repeatPasswordValidation];

const ChangePassword = ({ user }) => {
    const dataProvider = useDataProvider();
    const logout = useLogout();
    const notify = useNotify();

    const handleSubmit = (data) => {
        const { id, repeatNewPassword, newPassword, oldPassword } = data;

        dataProvider
            .post('api/users/changepassword', { id, repeatNewPassword, newPassword, oldPassword })
            .then(response => {
                const { data } = response || { data: {} };
                if (data) {
                    notify('userprofile.action.updated');
                    setTimeout(logout, 1500);
                }
                else
                    notify('userprofile.action.not_updated', { type: 'warning' })
            })
            .catch((apiException: ApiException) => {
                notify(`ra.apiError.${apiException.errors[0].code}`, { type: 'warning' });
            });
    };

    return (
        <Edit
            id={user.id}
            resource="users"
            redirect='/admin/profile'
            title=" "
            actions={false}
        >
            <SimpleForm toolbar={<EditToolbar />} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <PasswordInput label="resources.userprofile.fields.oldPassword" source="oldPassword" validate={required()} fullWidth inputProps={{ autoComplete: 'current-password' }} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <PasswordInput label="resources.userprofile.fields.newPassword" source="newPassword" validate={validateNewPassword} fullWidth inputProps={{ autoComplete: 'current-password' }} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <PasswordInput label="resources.userprofile.fields.repeatNewPassword" source="repeatNewPassword" validate={validateRepeatPassword} fullWidth inputProps={{ autoComplete: 'current-password' }} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};

export default ChangePassword;