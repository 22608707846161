import { Grid, Divider } from '@mui/material';

import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    required,
    email,
} from 'react-admin';

import { EditActions, EditToolbar, FileInput } from 'admin/components';

import CustomTitle from './CustomTitle';

import { transformData } from './transformData';

const CustomEdit = () => (
    <Edit redirect="list" actions={<EditActions />} title={<CustomTitle />} transform={transformData}>
        <SimpleForm toolbar={<EditToolbar />} >
            <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
                <Grid item xs={12} sm={3} display="flex" justifyContent="center" alignItems="center" sx={{ padding: theme => theme.spacing(2), }}>
                    <FileInput source="profilePhotoUrl" useMediaGallery />
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextInput source="firstName" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput source="lastName" validate={required()} fullWidth />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextInput source="userName" validate={required()} fullWidth />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="email" type="email" validate={[required(), email()]} fullWidth />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="phoneNumber" fullWidth />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <SelectInput source="defaultCulture" validate={required()} fullWidth choices={[
                        { id: 'it-IT', name: 'pos.languages.it' },
                        { id: 'en-US', name: 'pos.languages.en' }
                    ]} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <ReferenceInput source="status" reference="api/enums/userStatus" >
                        <SelectInput optionText="value" validate={required()} fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={4}>
                </Grid>

                <Grid item xs={12}>
                    <Divider flexItem />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <ReferenceArrayInput source="rolesIds" reference="roles">
                        <SelectArrayInput optionText={record => `pos.enums.RoleType.${record.name}`} translateChoice validate={required()} fullWidth />
                    </ReferenceArrayInput>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

export default CustomEdit;
