import Medias from './Medias';
import Users from './Users';

export default {
    Medias: {
        name: "medias", permission: "admin", ...Medias
    },
    Users: {
        name: "users", permission: "admin", ...Users
    },
};