import { AppInfo } from 'publicapp/types';

import iconImg from '../img/apps/github.png';

const appName = "github";
const label = "apps.github";

export const appInfo: AppInfo =
{
    name: appName,
    iconUrl: iconImg,
    label: label,
    toLink: 'https://github.com/alessiofilippucci'
}