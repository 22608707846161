import { memo, useState } from 'react';

import {
    Avatar,
    Box,
    ClickAwayListener,
    DialogContent,
    Paper,
    Stack,
    Typography,
} from '@mui/material';

import LoginIcon from '@mui/icons-material/Login';

import logoImg from '../img/logo.png';

import WinIcon from 'publicapp/layout/WinIcon';

export interface StartMenuProps {
}

const StartMenu = (props: StartMenuProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = () => {
        setOpen(!open)
    };

    const redirectTologin = () => {
        window.open('/admin', '_blank');
    }

    return <>
        <Box onClick={handleClick} >
            <WinIcon />
        </Box>
        {open &&
            <ClickAwayListener onClickAway={handleClick}>
                <Paper
                    id={`startmenu`}
                    className="window-container"
                    sx={{
                        display: 'flex',
                        position: 'fixed',
                        bottom: '120px',
                        width: '240px',
                        height: 'min(100% - 50px, 720px)',
                        borderRadius: theme => theme.spacing(1),
                    }}
                    style={{
                        zIndex: 9999999999,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <DialogContent
                            className='win11Scroll'
                            sx={{
                                margin: 0,
                                background: 'rgb(242 242 242)',
                                color: '#222',
                                height: '60px',
                                padding: '10px 20px!important',
                                borderRadius: theme => theme.spacing(1),
                            }}
                        >
                            <Stack
                                spacing={.5}
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box sx={{
                                    padding: '0.6em 0.8em',
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderRadius: '4px',
                                    '&:hover': {
                                        background: 'rgba(255, 255, 255, .9)'
                                    }
                                }}>
                                    <Box sx={{
                                        position: 'relative',
                                        display: 'grid',
                                        placeItems: 'center'
                                    }}>
                                        <Avatar alt="Administration" src={logoImg} sx={{ width: 24, height: 24 }} />
                                    </Box>
                                    <Typography variant="body1" textAlign='center' sx={{ ml: '12px', fontSize: '.9em', color: '#222' }}>
                                        Administration
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        height: '2.5rem',
                                        width: '2.5rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '4px',
                                        '&:hover': {
                                            background: 'rgba(255, 255, 255, .9)',
                                            cursor: 'pointer'
                                        }
                                    }}>
                                    <LoginIcon onClick={redirectTologin} />
                                </Box>
                            </Stack>
                        </DialogContent>
                    </Box>
                </Paper>
            </ClickAwayListener>
        }
    </>
}

export default StartMenu;