import { Divider, Grid, Typography } from '@mui/material';

import {
    Show,
    SimpleShowLayout,
    Labeled,
    TextField,
    NumberField,
    DateField,
    ReferenceField,
    useGetResourceLabel,
    WithRecord,
} from 'react-admin';

import { ShowActions } from 'admin/components';

import CustomTitle from './CustomTitle';
import { InvoiceLinesField } from 'admin/views/Common/fields';
import { InvoiceSubmissionOutcomeType } from 'admin/types';

const CustomShow = () => {
    const getResourceLabel = useGetResourceLabel();

    const hasEdit = (record) => record.currentInvoiceSubmission?.outcome !== InvoiceSubmissionOutcomeType.Pending &&
        record.currentInvoiceSubmission?.outcome !== InvoiceSubmissionOutcomeType.Success;

    return (
        <Show actions={<ShowActions hasEditFunc={hasEdit} />} title={<CustomTitle />} queryOptions={{ meta: { expand: ["invoiceLines", "currentInvoiceSubmission"] } }}>
            <SimpleShowLayout>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={7}>
                        <Labeled source="companyId">
                            <ReferenceField source="companyId" reference="companies">
                                <TextField source="name" />
                            </ReferenceField>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Labeled source="invoiceTypeId">
                            <ReferenceField source="invoiceTypeId" reference="invoiceTypes" >
                                <TextField source="label" />
                            </ReferenceField>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <Labeled source="number">
                            <TextField source="number" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <Labeled source="emissionDate">
                            <DateField source="emissionDate" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <Labeled source="fiscalYear">
                            <TextField source="fiscalYear" />
                        </Labeled>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider flexItem textAlign='center'>
                            <Typography variant="button">
                                {getResourceLabel("invoicelines", 2)}
                            </Typography>
                        </Divider>
                    </Grid>

                    <Grid item xs={12}>
                        <InvoiceLinesField source="invoiceLines" label={false} />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider flexItem />
                    </Grid>

                    <Grid item xs={12} sm={1}>
                        <Labeled source="stampAmount">
                            <NumberField source="stampAmount" locales="it-IT" options={{ style: 'currency', currency: 'EUR' }} />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="paymentTypeId">
                            <ReferenceField source="paymentTypeId" reference="paymentTypes" >
                                <TextField source="label" />
                            </ReferenceField>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <WithRecord label="" render={record => record.bankAccount &&
                            <Labeled source="bankAccount">
                                <TextField source="bankAccount" />
                            </Labeled>
                        } />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                    </Grid>

                    <Grid item xs={12}>
                        <Labeled source="otherDetails">
                            <TextField source="otherDetails" />
                        </Labeled>
                    </Grid>

                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShow;
