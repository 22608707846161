import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AddressComponentType } from 'admin/components/google/maps/types';
import { GMaps } from 'admin/components/google';
import { CompanyAddress } from 'admin/types';

interface GMapsInputProps {
    onSelected?: (data: CompanyAddress, setValue: (name: string, value: any) => void) => void;
}

const GMapsInput = (props: GMapsInputProps) => {
    const {
        onSelected = (data: CompanyAddress, setValue = (name: string, value: any) => { }) => { },
    } = props;

    const { setValue } = useFormContext();
    const [companyAddress, setCompanyAddress] = useState<CompanyAddress>({} as CompanyAddress);

    const getAddressComponent = (type: AddressComponentType, address_components?: google.maps.GeocoderAddressComponent[]) => {
        if (address_components) {
            var addressComponent = address_components.map(x => x.types.filter(y => y === type.toString()).length > 0 ? x : null).filter(x => x !== null);
            if (addressComponent.length > 0)
                return addressComponent[0];
        }

        return { "long_name": "", "short_name": "" };
    }

    useEffect(() => {
        onSelected(companyAddress, setValue);
    }, [companyAddress])

    const onSelectedPlace = (place: google.maps.places.PlaceResult | null) => {
        const street_number = getAddressComponent(AddressComponentType.street_number, place?.address_components);
        const route = getAddressComponent(AddressComponentType.route, place?.address_components);
        const country = getAddressComponent(AddressComponentType.country, place?.address_components);
        const postalCode = getAddressComponent(AddressComponentType.postal_code, place?.address_components);
        const addressLevel1 = getAddressComponent(AddressComponentType.administrative_area_level_1, place?.address_components);
        const addressLevel2 = getAddressComponent(AddressComponentType.administrative_area_level_2, place?.address_components);
        const addressLevel3 = getAddressComponent(AddressComponentType.administrative_area_level_3, place?.address_components);
        const lat = place?.geometry?.location?.lat().toString();
        const lng = place?.geometry?.location?.lng().toString();

        setCompanyAddress({
            ...companyAddress,
            addressLine1: [route?.short_name, street_number?.short_name].join(', '),
            addressLine2: "",
            addressLine3: "",
            country: country?.long_name,
            postalCode: postalCode?.short_name,
            addressLevel1: addressLevel1?.short_name,
            addressLevel2: addressLevel2?.short_name,
            addressLevel3: addressLevel3?.short_name,
            lat: lat,
            lng: lng,
            placeId: place?.place_id,
        });
    }

    return (
        <GMaps
            hasAutoComplete
            onSelectedPlace={onSelectedPlace}
        />
    )
}

export default GMapsInput;