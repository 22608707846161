import * as React from 'react';
import inflection from 'inflection';
import { Card, CardContent } from '@mui/material';

import SizeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import MediaIcon from '@mui/icons-material/PermMedia';

import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    useGetList,
} from 'react-admin';

const Aside = () => {
    const { data } = useGetList('api/enums/mimeTypes', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
    });

    return (
        <Card
            sx={{
                display: { xs: 'none', md: 'block' },
                order: -1,
                width: '15em',
                mr: 2,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch />

                <FilterList
                    label="medias.filters.size"
                    icon={<SizeIcon />}
                >
                    <FilterListItem
                        label="medias.filters.size_lt_1_mb"
                        value={{
                            size_gt: 0,
                            size_lte: 1024000
                        }}
                    />
                    <FilterListItem
                        label="medias.filters.size_gt_1_mb_lt_5_mb"
                        value={{
                            size_gt: 1024000,
                            size_lte: 5120000
                        }}
                    />
                    <FilterListItem
                        label="medias.filters.size_gt_5_mb"
                        value={{
                            size_gt: 5120000
                        }}
                    />
                </FilterList>

                <FilterList
                    label="medias.filters.mediaType"
                    icon={<MediaIcon />}
                >
                    {data &&
                        data.map((record) => (
                            <FilterListItem
                                label={inflection.humanize(record.value)}
                                key={record.id}
                                value={{ contentType: record.id }}
                            />
                        ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default Aside;