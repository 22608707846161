import { Grid } from '@mui/material';

import {
    Create,
    FormDataConsumer,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    email,
    required,
} from 'react-admin';

import { CreateActions, CreateToolbar } from 'admin/components';
import { transformData } from './transformData';
import { CompanyAddressReferenceInput } from 'admin/views/Common';

const CustomCreate = () => {
    const defaultValues = () => ({});
    return (
        <Create redirect="list" actions={<CreateActions />} transform={transformData}>
            <SimpleForm toolbar={<CreateToolbar />} defaultValues={defaultValues} >
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={4}>
                        <TextInput source="name" validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="firstName" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="lastName" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="vatNumber" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="fiscalCode" fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <CompanyAddressReferenceInput source="companyAddressId" reference="companyAddresses" />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="telephone" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="email" validate={email()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="pec" validate={email()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="destinationCode" fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <ReferenceInput source="userId" reference="users">
                            <SelectInput optionText="displayName" fullWidth />
                        </ReferenceInput>
                    </Grid>

                    <FormDataConsumer>
                        {({ formData }) => formData.userId &&
                            <>
                                <Grid item xs={12} sm={6}>
                                    <ReferenceInput source="vatRegimeId" reference="vatRegimes">
                                        <SelectInput optionText={record => `${record.code} - ${record.label}`} fullWidth />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <TextInput source="bankAccount" fullWidth />
                                </Grid>
                            </>
                        }
                    </FormDataConsumer>

                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default CustomCreate;
