import { useEffect } from 'react';

import {
    CssBaseline,
    Box,
    useMediaQuery,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import mainBgImg from './img/mainbg.jpg';

import { Desktop, Taskbar } from './layout';
import { useStore, useStoreContext } from 'react-admin';

import { apps } from './apps';
import { AppInfo } from './types';
import { ExperienceProps, experiences } from './apps/experiences';

interface BaseProps {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

interface Props {
}

export type MainContentProps = BaseProps & Props;

export default function MainContent(props: MainContentProps) {
    useStore('help', false);
    const { setItem } = useStoreContext();

    const [applist, setApplist] = useStore('applist', [] as AppInfo[]);
    const [experienceslist, setExperienceslist] = useStore('experiences', [] as ExperienceProps[]);

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (apps) {
            setApplist(apps)

            apps.forEach(app => {
                setItem(`app.${app.name}`, app);
            });
            
            setExperienceslist(experiences)
        }
        return () => { };
    }, [apps])

    return (
        <Box sx={{
            height: '100vh',
            width: '100vw',
            backgroundImage: `url(${mainBgImg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}>
            <CssBaseline />
            <Desktop isSmall={isSmall} />
            <Taskbar isSmall={isSmall} />
        </Box>
    )
}