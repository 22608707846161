import { Grid } from '@mui/material';

import {
    TextField,
    InputProps,
    ArrayField,
    NumberField,
    useTranslate,
    Datagrid,
    WithRecord,
} from 'react-admin';
import InvoiceSummaryField from './InvoiceSummaryField';

export const InvoiceLinesField = (props: InputProps) => {
    const translate = useTranslate();
    const getLabel = (field) => translate(`resources.invoicelines.fields.${field}`);

    return (
        <Grid container>
            <Grid item xs={12}>
                <ArrayField {...props} fullWidth>
                    <Datagrid optimized bulkActionButtons={false}>
                        <TextField source="description" label={getLabel("description")} fullWidth sortable={false} />
                        <NumberField source="price" label={getLabel("price")} fullWidth sortable={false} locales="it-IT" options={{ style: 'currency', currency: 'EUR' }} />
                        <NumberField source="quantity" label={getLabel("quantity")} fullWidth sortable={false} />
                        <NumberField source="vatRate" label={getLabel("vatRate")} fullWidth sortable={false} />
                        <NumberField source="vatAmount" label={getLabel("vatAmount")} fullWidth sortable={false} locales="it-IT" options={{ style: 'currency', currency: 'EUR' }} />
                        <NumberField source="amount" label={getLabel("amount")} fullWidth sortable={false} locales="it-IT" options={{ style: 'currency', currency: 'EUR' }} />
                    </Datagrid>
                </ArrayField >
            </Grid>
            <Grid item xs={12}>
                <WithRecord label="" render={record => <InvoiceSummaryField invoice={record} />} />
            </Grid>
        </Grid>
    );
};

export default InvoiceLinesField;