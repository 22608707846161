import { VatRegime } from 'admin/types';
import { useRecordContext, useResourceContext, useTranslate } from 'react-admin';

export default () => {
    const record = useRecordContext<VatRegime>();
    const resource = useResourceContext();
    const translate = useTranslate();
    return (
        <span>
            {record ? translate(`${resource}.edit.title`, { title: `${record.code} - ${record.label}` }) : ''}
        </span>
    );
};
