import React from 'react';
import {
    Toolbar,
    Loading,
    SaveButton,
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    email,
    required,
    useAuthState,
    useGetIdentity,
    useTranslate
} from 'react-admin';

import { Box, Grid, Divider } from '@mui/material';

import { FileInput } from 'admin/components';

import { transformData } from '../Users/transformData';
import ChangePassword from './ChangePassword';

const EditToolbar = (props: any) => {
    const leftGroup = <></>;
    const rightGroup = <>
        <SaveButton />
    </>;

    return (
        <Toolbar {...props}>
            <Grid container>
                <Grid item xs={6}>
                    {leftGroup}
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                        {rightGroup}
                    </Box>
                </Grid>
            </Grid>
        </Toolbar>
    )
};

const UserProfile = () => {
    const { isLoading, authenticated } = useAuthState();
    const { data: user, isLoading: identityLoading, error } = useGetIdentity();
    const translate = useTranslate();

    if (isLoading || identityLoading) {
        return <Loading />;
    }

    if (authenticated && user) {
        return (
            <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <Edit
                        id={user.id}
                        resource="users"
                        redirect='/admin'
                        title="userprofile.edit.title"
                        transform={transformData}
                        actions={false}
                    >
                        <SimpleForm toolbar={<EditToolbar />}>
                            <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} sm={3} display="flex" justifyContent="center" alignItems="center" sx={{ padding: theme => theme.spacing(2), }}>
                                    <FileInput source="profilePhotoUrl" useMediaGallery />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextInput source="firstName" validate={required()} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput source="lastName" validate={required()} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput source="email" type="email" validate={[required(), email()]} fullWidth />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextInput source="phoneNumber" fullWidth />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <SelectInput source="defaultCulture" validate={required()} fullWidth choices={[
                                        { id: 'it-IT', name: 'pos.languages.it' },
                                        { id: 'en-US', name: 'pos.languages.en' }
                                    ]} />
                                </Grid>
                            </Grid>
                        </SimpleForm>
                    </Edit>
                </Grid>
                <Grid item xs={12}>
                    <Divider flexItem textAlign='center'  >
                        {translate("userprofile.sections.changepassword")}
                    </Divider>
                </Grid>
                <Grid item xs={12}>
                    <ChangePassword user={user} />
                </Grid>
            </Grid>

        );
    }
    else {
        return null
    }
};

export default UserProfile;
