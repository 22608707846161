export default {
    name: 'vatregimes',
    resources: {
        name: 'Regime Iva |||| Regimi Iva',
        forcedCaseName: 'Regime Iva |||| Regimi Iva',
        fields: {
            label: "Testo",
            code: "Codice",
            vat: 'IVA inclusa',
            order: "Ordine",
            updatedAt: 'Aggiornato il',
            companies: 'Aziende',
        },
    },
    global: {
        menu: {
            name: 'Regimi Iva',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Regime Iva "%{title}"',
        },
        action: {
        },
    },
};