import { ComponentType, ReactElement, isValidElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import { isValidElementType } from 'react-is';
import { ResourceContextProvider, ResourceProps } from 'react-admin';
import { ActionRBAC, useCanAccess } from './useCanAccess';
import { Utils } from 'admin/core';
import AccessDenied from './AccessDenied';
import { NotFound } from 'admin/layout';

export const ResourceRBAC = (props: ResourceProps) => {
    const { create, edit, list, name, show } = props;
    const { loaded, canAccess } = useCanAccess();

    if (!loaded && !canAccess) {
        return null;
    }

    return (
        <ResourceContextProvider value={name}>
            <Routes>
                {create ? (canAccess(name, ActionRBAC.Create) && canAccess(name, ActionRBAC.Write) ? <Route path="create/*" element={getElement(create)} /> : <Route path="create/*" element={<AccessDenied />} />) : <Route path="create/*" element={<NotFound />} />}
                {show ? (canAccess(name, ActionRBAC.Show) && canAccess(name, ActionRBAC.Read) ? <Route path=":id/show/*" element={getElement(show)} /> : <Route path=":id/show/*" element={<AccessDenied />} />) : <Route path=":id/show/*" element={<NotFound />} />}
                {edit ? (canAccess(name, ActionRBAC.Edit) && canAccess(name, ActionRBAC.Write) ? <Route path=":id/*" element={getElement(edit)} /> : <Route path=":id/*" element={<AccessDenied />} />) : <Route path=":id/*" element={<NotFound />} />}
                {list ? (canAccess(name, ActionRBAC.List) && canAccess(name, ActionRBAC.Read) ? <Route path="/*" element={getElement(list)} /> : <Route path="/*" element={<AccessDenied />} />) : <Route path="/*" element={<NotFound />} />}
                {props.children}
            </Routes>
        </ResourceContextProvider>
    );
};

const getElement = (ElementOrComponent: ComponentType<any> | ReactElement) => {
    if (isValidElement(ElementOrComponent)) {
        return ElementOrComponent;
    }

    if (isValidElementType(ElementOrComponent)) {
        return Utils.CreateOrCloneElement(ElementOrComponent);
    }

    return null;
};

ResourceRBAC.raName = 'Resource';

ResourceRBAC.registerResource = ({
    create,
    edit,
    icon,
    list,
    name,
    options,
    show,
    recordRepresentation,
    hasCreate,
    hasEdit,
    hasShow,
}: ResourceProps) => ({
    name,
    options,
    hasList: !!list,
    hasCreate: !!create || !!hasCreate,
    hasEdit: !!edit || !!hasEdit,
    hasShow: !!show || !!hasShow,
    icon,
    recordRepresentation,
});
