import { Divider, Grid } from '@mui/material';
import { Invoice } from 'admin/types';

import {
    Show,
    SimpleShowLayout,
    Labeled,
    TextField,
    useRecordContext,
    useResourceContext,
    NumberField,
    ReferenceManyField,
    SingleFieldList,
} from 'react-admin';

const CustomShowExpand = () => {
    const record = useRecordContext<Invoice>();
    const resource = useResourceContext();

    if (!record) return null;
    return (
        <Show
            component="div"
            resource={resource}
            id={record.id}
            title=" "
            actions={false} >
            <SimpleShowLayout>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ReferenceManyField
                            reference="invoicelines"
                            target="invoiceLines"
                            filter={{ parent: "invoices" }}
                        >
                            <SingleFieldList linkType={false}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={7}>
                                        <Labeled label="resources.invoicelines.fields.description">
                                            <TextField source="description" fullWidth />
                                        </Labeled>
                                    </Grid>

                                    <Grid item xs={12} sm={1} container justifyContent="end">
                                        <Labeled label="resources.invoicelines.fields.price">
                                            <NumberField source="price" fullWidth options={{ style: 'currency', currency: 'EUR' }} />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={1} container justifyContent="end">
                                        <Labeled label="resources.invoicelines.fields.quantity">
                                            <NumberField source="quantity" fullWidth />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={1} container justifyContent="end">
                                        <Labeled label="resources.invoicelines.fields.vatRate">
                                            <NumberField source="vatRate" fullWidth />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={1} container justifyContent="end">
                                        <Labeled label="resources.invoicelines.fields.vatAmount">
                                            <NumberField source="vatAmount" fullWidth options={{ style: 'currency', currency: 'EUR' }} />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={1} container justifyContent="end">
                                        <Labeled label="resources.invoicelines.fields.amount">
                                            <NumberField source="amount" fullWidth options={{ style: 'currency', currency: 'EUR' }} />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider flexItem />
                                    </Grid>
                                </Grid>
                            </SingleFieldList>
                        </ReferenceManyField>
                    </Grid>

                    <Grid item xs={12}>
                        <Labeled source="otherDetails">
                            <TextField source="otherDetails" fullWidth multiline={true} />
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShowExpand;