export enum AddressComponentType {
    /**
     * Indicates a precise street address.
     */
    street_address = 'street_address',
    /**
     * Indicates a named route(such as "US 101").
     */
    route = 'route',
    /**
     * Indicates a major intersection, usually of two major roads.
     */
    intersection = 'intersection',
    /**
     * Indicates a political entity.Usually, this type indicates a polygon of some civil administration.
     */
    political = 'political',
    /**
     * Indicates the national political entity, and is typically the highest order type returned by the Geocoder.
     */
    country = 'country',
    /**
     * Indicates a first - order civil entity below the country level.
     * Within the United States, these administrative levels are states.
     * Not all nations exhibit these administrative levels.
     * In most cases, administrative_area_level_1 short names will closely match ISO 3166 - 2 subdivisions and other widely circulated lists; 
     * however this is not guaranteed as our geocoding results are based on a variety of signals and location data.
     */
    administrative_area_level_1 = 'administrative_area_level_1',
    /**
     * Indicates a second - order civil entity below the country level.
     * Within the United States, these administrative levels are counties.
     * Not all nations exhibit these administrative levels.
     */
    administrative_area_level_2 = 'administrative_area_level_2',
    /**
     * Indicates a third - order civil entity below the country level.
     * This type indicates a minor civil division.
     * Not all nations exhibit these administrative levels.
     */
    administrative_area_level_3 = 'administrative_area_level_3',
    /**
     * Indicates a fourth - order civil entity below the country level.
     * This type indicates a minor civil division.
     * Not all nations exhibit these administrative levels.
     */
    administrative_area_level_4 = 'administrative_area_level_4',
    /**
     * Indicates a fifth - order civil entity below the country level.
     * This type indicates a minor civil division.
     * Not all nations exhibit these administrative levels.
     */
    administrative_area_level_5 = 'administrative_area_level_5',
    /**
     * Indicates a sixth - order civil entity below the country level.
     * This type indicates a minor civil division.
     * Not all nations exhibit these administrative levels.
     */
    administrative_area_level_6 = 'administrative_area_level_6',
    /**
     * Indicates a seventh - order civil entity below the country level.
     * This type indicates a minor civil division.
     * Not all nations exhibit these administrative levels.
     */
    administrative_area_level_7 = 'administrative_area_level_7',
    /**
     * Indicates a commonly - used alternative name for the entity.
     */
    colloquial_area = 'colloquial_area',
    /**
     * Indicates an incorporated city or town political entity.
     */
    locality = 'locality',
    /**
     * Indicates a first - order civil entity below a locality.
     * For some locations may receive one of the additional types: sublocality_level_1 to sublocality_level_5.
     * Each sublocality level is a civil entity.
     * Larger numbers indicate a smaller geographic area.
     */
    sublocality = 'sublocality',
    /**
     * Indicates a named neighborhood
     */
    neighborhood = 'neighborhood',
    /**
     * Indicates a named location, usually a building or collection of buildings with a common name
     */
    premise = 'premise',
    /**
     * Indicates a first - order entity below a named location, usually a singular building within a collection of buildings with a common name
     */
    subpremise = 'subpremise',
    /**
     * Indicates an encoded location reference, derived from latitude and longitude.
     * Plus codes can be used as a replacement for street addresses in places where they do not exist(where buildings are not numbered or streets are not named).
     * See https://plus.codes for details.
     */
    plus_code = 'plus_code',
    /**
     * Indicates a postal code as used to address postal mail within the country.
     */
    postal_code = 'postal_code',
    /**
     * Indicates a prominent natural feature.
     */
    natural_feature = 'natural_feature',
    /**
     * Indicates an airport.
     */
    airport = 'airport',
    /**
     * Indicates a named park.
     */
    park = 'park',
    /**
     * Indicates a named point of interest.Typically, these "POI"s are prominent local entities that don't easily fit in another category, such as "Empire State Building" or "Eiffel Tower".
     */
    point_of_interest = 'point_of_interest',
    /**
     * Indicates the floor of a building address.
     */
    floor = 'floor',
    /**
     * Typically indicates a place that has not yet been categorized.
     */
    establishment = 'establishment',
    /**
     * Indicates a nearby place that is used as a reference, to aid navigation.
     */
    landmark = 'landmark',
    /**
     * Indicates a parking lot or parking structure.
     */
    parking = 'parking',
    /**
     * Indicates a specific postal box.
     */
    post_box = 'post_box',
    /**
     * Indicates a grouping of geographic areas, such as locality and sublocality, used for mailing addresses in some countries.
     */
    postal_town = 'postal_town',
    /**
     * Indicates the room of a building address.
     */
    room = 'room',
    /**
     * Indicates the precise street number.
     */
    street_number = 'street_number',
    /**
     * Indicate the location of a bus, train or public transit stop.
     */
    bus_station = 'bus_station',
    /**
     * Indicate the location of a bus, train or public transit stop.
     */
    train_station = 'train_station',
    /**
     * Indicate the location of a bus, train or public transit stop.
     */
    transit_station = 'transit_station',
}