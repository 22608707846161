import { useEffect, useState } from 'react';

import { Divider, Grid, Typography } from '@mui/material';

import {
    Create,
    SimpleForm,
    DateInput,
    FormDataConsumer,
    LoadingPage,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    required,
    useDataProvider,
    useNotify,
    useGetResourceLabel,
} from 'react-admin';

import { CreateActions, CreateToolbar } from 'admin/components';

import { transformData } from './transformData';

import { Invoice } from 'admin/types';
import { BankAccountInput, InvoiceLinesInput } from 'admin/views/Common';
import { iban } from 'admin/core/utils';

const CustomCreate = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const getResourceLabel = useGetResourceLabel();
    const [loading, setLoading] = useState(true);
    const [defaultValues, setDefaultValues] = useState({});

    useEffect(() => {
        initializeValues();
    }, [])

    const initializeValues = () => {
        var newDate = new Date();
        newDate.setHours(0, 0, 0, 0);

        let record = {
            emissionDate: newDate,
            fiscalYear: newDate.getFullYear(),
            stampAmount: 2,
            number: 1,
        };

        dataProvider.getList<Invoice>('invoices', {
            filter: { fiscalYear: newDate.getFullYear() },
            sort: { field: "number", order: "DESC" },
            pagination: { page: 1, perPage: 1 },
        }).then((response) => {
            const { data } = response || { data: [] };
            record.number = data.length > 0 && data[0].number ? data[0].number + 1 : 1;
        }).catch(error => {
            console.log(error);
            notify('Error', { type: 'warning' })
        }).finally(() => {
            setDefaultValues(record);
            setLoading(false);
        });
    }

    if (loading) { return <LoadingPage /> }

    return (
        <Create redirect="list" actions={<CreateActions />} transform={transformData}>
            <SimpleForm toolbar={<CreateToolbar />} defaultValues={defaultValues} >
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                        <ReferenceInput source="companyId" validate={required()} reference="companies" sort={{ field: 'name', order: 'ASC' }} filter={{ userId: null }} >
                            <SelectInput optionText="name" validate={required()} fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                    </Grid>
                    <Grid item xs={12} sm={3} container justifyContent="flex-end">
                        <ReferenceInput source="invoiceTypeId" validate={required()} reference="invoicetypes" sort={{ field: 'order', order: 'ASC' }} >
                            <SelectInput optionText="label" validate={required()} fullWidth />
                        </ReferenceInput>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <FormDataConsumer fullWidth>
                            {({ formData }) => {
                                return (
                                    formData.fiscalYear < new Date().getFullYear() ?
                                        <NumberInput source="number" validate={required()} fullWidth /> :
                                        <NumberInput source="number" disabled InputProps={{ readOnly: true }} fullWidth />
                                )
                            }}
                        </FormDataConsumer>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <DateInput source="emissionDate" validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <NumberInput source="fiscalYear" validate={required()} fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider flexItem textAlign='center'>
                            <Typography variant="button">
                                {getResourceLabel("invoicelines", 2)}
                            </Typography>
                        </Divider>
                    </Grid>

                    <Grid item xs={12}>
                        <InvoiceLinesInput source="invoiceLines" label={false} />
                    </Grid> 

                    <Grid item xs={12}>
                        <Divider flexItem />
                    </Grid>

                    <Grid item xs={12} sm={1}>
                        <NumberInput source="stampAmount" disabled InputProps={{ readOnly: true }} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <ReferenceInput source="paymentTypeId" validate={required()} reference="paymenttypes" sort={{ field: 'order', order: 'ASC' }} >
                            <SelectInput optionText="label" validate={required()} fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormDataConsumer fullWidth>
                            {({ formData }) => formData.paymentTypeId === '4bd75b1d-b33f-490e-a3a9-1a4d98f77544' &&
                                <BankAccountInput source="bankAccount" validate={[iban()]} fullWidth formClassName="col-12" />
                            }
                        </FormDataConsumer>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                    </Grid>

                    <Grid item xs={12}>
                        <TextInput source="otherDetails" fullWidth multiline={true} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default CustomCreate;
