export default {
    name: 'invoicelines',
    resources: {
        name: 'Invoice Line |||| Invoice Lines',
        forcedCaseName: 'Invoice Line |||| Invoice Lines',
        fields: {
            invoiceId: 'Invoice Id',
            description: 'Description',
            price: 'Price',
            quantity: 'Quantity',
            vatRate: 'Vat Rate',
            vatAmount: 'Vat Amount',
            amount: 'Amount',
            updatedAt: 'Updated At',
            invoice: 'Invoice',
        }
    },
    global: {
        menu: {
            name: 'Invoice Lines',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Invoice Line "%{title}"',
        },
        action: {
        },
        others: {
            title: 'Riepilogo',
            net: 'Imponibile',
            vat: 'IVA %{vatRate}% su %{vatAmount}',
            totalVat: 'Totale IVA',
            stamp: 'Bollo',
            totalInvoice: 'Totale Fattura'
        }
    },
};