import odataProvider, { OdataDataProvider } from "./ra-data-odata-server";
import buildRestProvider from './ra-data-simple-rest';
import authProvider from './authProvider';
import jwtProvider from './jwtProvider';
import * as firebase from './firebase';
import { Utils } from '../core';

import { fetchUtils, defaultDataProvider, HttpError } from "ra-core";
import { fetchBlob } from "./ra-data-simple-rest/fetchBlob";
import { ApiException } from "admin/types";

const apiEndpoint = Utils.GetENV('API_ENDPOINT');
const odataEndpoint = Utils.GetENV('ODATA_ENDPOINT');

const decodedToken: any = jwtProvider.getToken(true);
let currentCulture: string = decodedToken ? decodedToken.culture : "it-IT";

const dataProviderApi = buildRestProvider(apiEndpoint, (url: string, options: fetchUtils.Options = {}) => {
    const token = jwtProvider.getToken();

    const customHeaders = (options.headers || new Headers({ Accept: 'application/json' })) as Headers;

    customHeaders.set('Accept-Language', currentCulture);
    customHeaders.set('Authorization', `Bearer ${token}`);

    options.headers = customHeaders;
    return fetchUtils.fetchJson(url, options);
});

let dataProviderOdata: OdataDataProvider;

const dataProvider = new Proxy(defaultDataProvider, {
    get: (target, name) => {
        return async (resource: string, params: { data: { id: any; }; meta: { useFetch: any; }; }) => {

            if (typeof name === 'symbol' || name === 'then') {
                return;
            }

            if (name === "getFile" || (name == "create" && params && params.data && (params.data instanceof FormData || (params.meta && params.meta.useFetch)))) {
                const token = jwtProvider.getToken();

                const customHeaders = new Headers({ Accept: 'application/json' }) as Headers;

                customHeaders.set('Accept-Language', currentCulture);
                customHeaders.set('Authorization', `Bearer ${token}`);

                switch (name) {
                    case "getFile":
                        return fetchBlob(`${apiEndpoint}/${resource.substring(4)}`, {
                            method: 'GET',
                            headers: customHeaders
                        }).then(({ blob }) => (blob))
                    case "getFile":
                        if (params.data instanceof FormData) {
                            return fetchUtils.fetchJson(`${odataEndpoint}/${resource}`, {
                                method: 'POST',
                                body: params.data,
                                headers: customHeaders
                            }).then(({ json }) => ({ data: json }))
                        }
                        else {
                            if (resource && resource.startsWith('api/')) {
                                return fetchUtils.fetchJson(`${apiEndpoint}/${resource.substring(4)}`, {
                                    method: 'POST',
                                    body: JSON.stringify(params.data),
                                    headers: customHeaders
                                }).then(({ json }) => {
                                    if (params.data.id)
                                        return { data: { id: params.data.id, ...json } }
                                    else
                                        return { data: { id: 1, ...json } }
                                })
                            }
                            else {
                                return fetchUtils.fetchJson(`${odataEndpoint}/${resource}`, {
                                    method: 'POST',
                                    body: JSON.stringify(params.data),
                                    headers: customHeaders
                                }).then(({ json }) => ({ data: json }))
                            }
                        }
                    default:
                        break;
                }
            }

            if (resource && resource.startsWith('api/')) {
                return dataProviderApi[name](resource.substring(4), params)
                    .catch((error: HttpError) => Promise.reject(error.body as ApiException));
            }
            else {
                if (!dataProviderOdata) {
                    dataProviderOdata = await odataProvider(odataEndpoint, () => {
                        return authProvider.getAccessToken().then((token: string) => ({
                            commonHeaders: {
                                Authorization: "Bearer " + token,
                                'Accept-Language': currentCulture
                            },
                        }));
                    });
                }

                if (dataProviderOdata)
                    return dataProviderOdata[name](resource, params);
                else
                    throw new Error("dataProviderOdata non created");
            }
        }
    },
});

export {
    dataProvider,
    authProvider,
    jwtProvider,
    firebase
}
