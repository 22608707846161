import { Grid } from '@mui/material';

import { Authenticated, useTranslate } from 'react-admin';

import { RevenuesCard } from './RevenuesCard';

export default () => {
    const translate = useTranslate();

    const reloadDashboard = () => {
        window.location.reload();
    };

    return (
        <Authenticated>
            <Grid container spacing={2} mt={0}>
                <Grid item xs={12}>
                    <RevenuesCard reloadDashboard={reloadDashboard} />
                </Grid>
            </Grid>
        </Authenticated>
    )
};