import * as React from 'react';
import classNames from 'classnames';

import { Grid, Divider } from '@mui/material';

import {
    Show,
    SimpleShowLayout,
    Labeled,
    TextField,
    DateField,
    BooleanField,
    EmailField,
    ImageField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
} from 'react-admin';

import { ShowActions } from 'admin/components';

import CustomTitle from './CustomTitle';

const CustomShow = () => {
    return (
        <Show actions={<ShowActions />} title={<CustomTitle />}>
            <SimpleShowLayout>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={3} sx={{ padding: 0 }}>
                        <ImageField source="profilePhotoUrl" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', '& img': { width: 'auto !important', height: '100px !important', margin: ' 0 !important' } }} />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="firstName">
                            <TextField source="firstName" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="lastName">
                            <TextField source="lastName" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="userName">
                            <TextField source="userName" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="email">
                            <EmailField source="email" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="phoneNumber">
                            <DateField source="phoneNumber" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="providerType">
                            <ReferenceField source="providerType" reference="api/enums/providerTypes" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="changePassword">
                            <BooleanField source="changePassword" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="defaultCulture">
                            <TextField source="defaultCulture" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="status">
                            <ReferenceField source="status" reference="api/enums/userStatus" />
                        </Labeled>
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                        <Labeled source="rolesIds">
                            <ReferenceArrayField source="rolesIds" reference="roles" >
                                <SingleFieldList>
                                    <ChipField source="name" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShow;
