import { Grid, Link, Stack } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

import {
    Show,
    SimpleShowLayout,
    Labeled,
    TextField,
    FunctionField,
    ReferenceField,
    WithRecord,
} from 'react-admin';

import { ShowActions } from 'admin/components';

import CustomTitle from './CustomTitle';

const CustomShow = () => {
    return (
        <Show actions={<ShowActions />} title={<CustomTitle />}>
            <SimpleShowLayout>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={4}>
                        <Labeled source="name">
                            <TextField source="name" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Labeled source="firstName">
                            <TextField source="firstName" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Labeled source="lastName">
                            <TextField source="lastName" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Labeled source="vatNumber">
                            <TextField source="vatNumber" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Labeled source="fiscalCode">
                            <TextField source="fiscalCode" />
                        </Labeled>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Labeled source="companyAddressId">
                            <ReferenceField source="companyAddressId" reference="companyAddresses" >
                                <FunctionField render={record => `[${record.name}] ${record.addressLine1} - ${record.addressLevel3} (${record.addressLevel2})`} />
                            </ReferenceField>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Labeled source="telephone">
                            <FunctionField source="telephone" textAlign='center' render={
                                record => record.phoneNumber && <Link underline='none' href={`tel:${record.telephone}`}>
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <PhoneIcon />&nbsp;&nbsp;{record.telephone}
                                    </Stack>
                                </Link>
                            } />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Labeled source="email">
                            <FunctionField source="email" textAlign='center' render={
                                record => record.email && <Link underline='none' href={`mailto:${record.email}`}>
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <EmailIcon />&nbsp;&nbsp;{record.email}
                                    </Stack>
                                </Link>
                            } />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Labeled source="pec">
                            <FunctionField source="pec" textAlign='center' render={
                                record => record.pec && <Link underline='none' href={`mailto:${record.pec}`}>
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <EmailIcon />&nbsp;&nbsp;{record.pec}
                                    </Stack>
                                </Link>
                            } />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Labeled source="destinationCode">
                            <TextField source="destinationCode" />
                        </Labeled>
                    </Grid>

                    <WithRecord label="" render={record => record.userId &&
                        <>
                            <Grid item xs={12} sm={4}>
                                <Labeled source="destinationCode">
                                    <ReferenceField source="userId" reference="users" >
                                        <TextField source="displayName" />
                                    </ReferenceField>
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Labeled source="destinationCode">
                                    <ReferenceField source="vatRegimeId" reference="vatRegimes" >
                                        <FunctionField render={record => `${record.code} - ${record.label}`} />
                                    </ReferenceField>
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Labeled source="bankAccount">
                                    <TextField source="bankAccount" />
                                </Labeled>
                            </Grid>
                        </>
                    } />

                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShow;
