export default {
    name: 'invoicetypes',
    resources: {
        name: 'Invoice Type |||| Invoice Types',
        forcedCaseName: 'Invoice Type |||| Invoice Types',
        fields: {
            label: 'Label',
            code: 'Code',
            order: 'Order',
            updatedAt: 'Updated At',
        },
    },
    global: {
        menu: {
            name: 'Invoice Types',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Invoice Type "%{title}"',
        },
        action: {
        },
    },
};