import { useState } from 'react';

import { useFormContext } from 'react-hook-form';

import {
    Divider,
    Theme,
} from '@mui/material';

import MuiGrid from '@mui/material/Grid';

import { styled } from "@mui/styles";

import {
    Edit,
    SimpleForm,
    TextInput,
    required,
} from 'react-admin';

import { EditActions, EditToolbar } from 'admin/components';
import CustomTitle from './CustomTitle';
import { transformData } from './transformData';

import { GMapsInput } from 'admin/views/Common';
import { CompanyAddress } from 'admin/types';

const Grid = styled(MuiGrid)<Theme>(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& [role="separator"]': {
        margin: theme.spacing(0, 2),
    },
}));

const CustomEdit = () => {
    const onSelectedPlace = (data: CompanyAddress, setValue: (name: string, value: any) => void) => {
        setValue('addressLine1', data.addressLine1);
        setValue('addressLine2', data.addressLine2);
        setValue('addressLine3', data.addressLine3);
        setValue('country', data.country);
        setValue('postalCode', data.postalCode);
        setValue('addressLevel1', data.addressLevel1);
        setValue('addressLevel2', data.addressLevel2);
        setValue('addressLevel3', data.addressLevel3);
        setValue('lat', data.lat);
        setValue('lng', data.lng);
        setValue('placeId', data.placeId);
    }

    return (
        <Edit redirect="list" actions={<EditActions />} title={<CustomTitle />} transform={transformData}>
            <SimpleForm toolbar={<EditToolbar />}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextInput source="name" placeholder='Sede Legale' validate={required()} fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextInput source="addressLine1" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput source="addressLine2" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput source="addressLine3" fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextInput source="country" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput source="postalCode" fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextInput source="addressLevel1" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput source="addressLevel2" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput source="addressLevel3" fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider orientation='vertical' variant="middle" flexItem />
                    <Grid item xs>
                        <GMapsInput onSelected={onSelectedPlace} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};

export default CustomEdit;
