import Icon from '@mui/icons-material/SettingsApplications';
import CustomEdit from './CustomEdit';
import CustomList from './CustomList';

export default {
    Settings: {
        name: "settings",
        permission: "admin",
        list: CustomList,
        edit: CustomEdit,
        icon: Icon,
    },
};