export default {
    name: 'invoicetypes',
    resources: {
        name: 'Tipo Fattura |||| Tipi Fatture',
        forcedCaseName: 'Tipo Fattura |||| Tipi Fatture',
        fields: {
            label: "Testo",
            code: "Codice",
            order: "Ordine",
            updatedAt: 'Aggiornato il',
        },
    },
    global: {
        menu: {
            name: 'Tipi Fatture',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Tipo Fattura "%{title}"',
        },
        action: {
        },
    },
};