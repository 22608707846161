import Companies from './Companies';
import CompanyAddresses from './CompanyAddresses';
import InvoiceTypes from './InvoiceTypes';
import PaymentTypes from './PaymentTypes';
import VatRates from './VatRates';
import VatRegimes from './VatRegimes';
import Invoices from './Invoices';

export default {
    Invoices: {
        name: "invoices", permission: "admin", ...Invoices
    },

    Companies: {
        name: "companies", permission: "admin", ...Companies
    },

    CompanyAddresses: {
        name: "companyaddresses", permission: "admin", ...CompanyAddresses
    },
    
    InvoiceTypes: {
        name: "invoicetypes", permission: "admin", ...InvoiceTypes
    },

    PaymentTypes: {
        name: "paymenttypes", permission: "admin", ...PaymentTypes
    },

    VatRates: {
        name: "vatrates", permission: "admin", ...VatRates
    },

    VatRegimes: {
        name: "vatregimes", permission: "admin", ...VatRegimes
    },
};