import {
    Chip,
    Paper,
    Tooltip
} from '@mui/material';

import { styled } from '@mui/material/styles';

import { SkillType } from 'publicapp/types';

import { useTranslate } from 'react-admin';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

interface SkillsProps {
    data: SkillType[];
}

const Skills = (props: SkillsProps) => {
    const {
        data
    } = props

    const translate = useTranslate();

    return (
        <Paper
            elevation={0}
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                listStyle: 'none',
                backgroundColor: '#fff',
                p: 0.5,
                m: 0,
            }}
            component="ul"
        >
            {data.map((item, idx) => {
                if (item?.allVersion && item?.allVersion.length > 0) {
                    return (
                        <ListItem key={idx}>
                            <Tooltip
                                title={item?.allVersion.join(' | ')}
                                placement="top"
                            >
                                <Chip
                                    color='primary'
                                    clickable
                                    icon={item?.icon}
                                    label={translate(item.name)}
                                    variant='filled'
                                />
                            </Tooltip>
                        </ListItem>
                    );
                }

                return (
                    <ListItem key={idx}>
                        <Chip
                            color='primary'
                            clickable
                            icon={item?.icon}
                            label={translate(item.name)}
                            variant='filled'
                        />
                    </ListItem>
                );
            })}
        </Paper>
    )
}

export default Skills;