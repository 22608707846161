import companies from './companies';
import companyaddresses from './companyaddresses';
import invoicetypes from './invoicetypes';
import paymenttypes from './paymenttypes';
import vatrates from './vatrates';
import vatregimes from './vatregimes';
import invoices from './invoices';
import invoicelines from './invoicelines';
import invoicesubmissions from './invoicesubmissions';

import system from './system'

export default {
    companies,
    companyaddresses,
    invoicetypes,
    paymenttypes,
    vatrates,
    vatregimes,
    invoices,
    invoicelines,
    invoicesubmissions,
    ...system
};