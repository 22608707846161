import PropTypes from 'prop-types';
import { Utils } from 'admin/core';

export interface ComposedIconProps {
    icon: JSX.Element;
    extraIcon: JSX.Element;
    size?: string;
    color?: string;
    position?: string;
    disabled?: boolean;
}

const ComposedIcon = (props: ComposedIconProps) => {
    const {
        icon,
        extraIcon,
        size = 'small',
        color = 'inherit',
        position = 'bottom-end',
        disabled = false
    } = props;

    return (
        <div style={{
            position: 'relative',
            cursor: 'default',
            marginRight: 4,
        }}>
            <div style={{ lineHeight: '0px' }}>
                {Utils.CreateOrCloneElement(icon, {
                    fontSize: size,
                    color: color,
                    disabled
                })}
            </div>
            <div
                style={{
                    lineHeight: 0,
                    textShadow: 'rgb(255 255 255) 0.75px 0px 0.5px, rgb(255 255 255) 0px 0.75px 0.5px, rgb(255 255 255) - 0.75px 0px 0.5px, rgb(255 255 255) 0px - 0.75px 0.5px',
                    position: 'absolute',
                    bottom: position.includes('bottom') ? '-4px' : 0,
                    top: position.includes('top') ? '-4px' : -4,
                    left: position.includes('start') ? '-4px' : 0,
                    right: position.includes('end') ? '-4px' : -4,
                    backgroundColor: 'var(--dark)',
                    borderRadius: 8,
                    width: '75%',
                    height: '75%',
                    display: 'flex',
                    justifyContent: ' center',
                    alignItems: 'center',
                    transform: 'scale(0.8)',
                }}>
                {Utils.CreateOrCloneElement(extraIcon, {
                    fontSize: size,
                    color: color,
                    style: {
                        color: '#ffffff',
                        padding: '2px',
                    },
                    disabled
                })}
            </div>
        </div>
    )
}

ComposedIcon.propTypes = {
    icon: PropTypes.element.isRequired,
    extraIcon: PropTypes.element.isRequired,
    size: PropTypes.oneOf(['inherit', 'default', 'small', 'large']),
    color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'action', 'error', 'disabled']),
    position: PropTypes.oneOf(['top-start', 'top-end', 'bottom-start', 'bottom-end']),
    disabled: PropTypes.bool,
};

export default ComposedIcon;