import { Card, CardContent, Stack, Typography } from '@mui/material';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { Title, useAuthenticated, useTranslate } from 'react-admin';

export default () => {
    const translate = useTranslate();
    useAuthenticated({ params: { logoutOnFailure: true } }); // redirects to login if not authenticated
    return (
        <Card sx={{ mt: 2 }}>
            <Title title={"401"} />
            <CardContent>
                <Stack justifyContent={"center"} alignItems={"center"} sx={{ p: 5 }} spacing={2}>
                    <DoDisturbIcon sx={{ fontSize: theme => theme.spacing(8), color: theme => theme.palette.secondary.main }} />
                    <Typography variant="h5" textAlign="center" >
                        {translate('pos.access_denied')}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
};