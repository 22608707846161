import PropTypes from 'prop-types';

import {
    CircularProgress,
    Grid,
} from '@mui/material';

import { firebase } from 'admin/providers'

import { GoogleLoginButton, MicrosoftLoginButton } from "react-social-login-buttons";

import { useAuthProvider, useTranslate, useNotify, useSafeSetState } from 'react-admin';
import { useNavigate } from "react-router-dom";
import { ProviderType } from 'admin/types';
import { SpecificSocialLoginButtonProps } from 'react-social-login-buttons';

const socialButtonConfig: SpecificSocialLoginButtonProps = {
    // text: "Log in with Facebook",
    // icon: "facebook",
    // iconFormat: name => `fa fa-${name}`,
    align: 'center',
    style: {
        padding: '6px 16px',
        margin: '4px 0 8px 0',
        fontSize: '0.875rem',
        width: '100%',
        minWidth: 64,
        height: 'initial',
        boxSizing: 'border-box',
        boxShadow: 'rgb(0 0 0 / 50%) 0px 2px 5px 0px',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
        lineHeight: 1.75,
        borderRadius: 4,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
    },
};

const allowedSocials = ['google'];

const SocialLogin = ({ loading, setSocialLoading, ...props }) => {
    const [loadingType, setLoadingType] = useSafeSetState<ProviderType | null>(null);
    const authProvider = useAuthProvider();
    const navigate = useNavigate();
    const notify = useNotify();
    const translate = useTranslate();

    const submitGoogle = async () => {
        if (!loadingType) {
            setSocialLoading(true);
            setLoadingType(ProviderType.Google);

            firebase
                .signInWithGoogle()
                .then(authResult => {
                    let { operationType, providerId, user } = authResult;

                    let loginRequest = {
                        username: user.displayName,
                        email: user.email,
                        providerType: ProviderType.Google,
                        providerId: user.uid,
                        profilePhotoSrc: user.photoURL
                    };

                    return authProvider.login(loginRequest).then(res => {
                        const { redirectTo } = res || { redirectTo: '/admin' };
                        navigate(redirectTo);
                        return Promise.resolve();
                    })
                }).catch((error) => {
                    notify(error.message, { type: 'error' });
                }).finally(() => {
                    setSocialLoading(false);
                    setLoadingType(null);
                });
        }
    };

    const submitMicrosoft = () => {
        if (!loadingType) {
            setSocialLoading(true);
            setLoadingType(ProviderType.Microsoft);
        }
    };

    if (loading && (loadingType === ProviderType.Google || loadingType === ProviderType.Microsoft))
        return <CircularProgress color="inherit" size={18} thickness={5} className='mr-3' />

    return (
        <>
            {
                (allowedSocials.find(x => x === ProviderType.Google) && !loading && (!loadingType || loadingType === ProviderType.Google)) && (
                    <Grid item xs={12} sx={{ paddingLeft: theme => theme.spacing(2), paddingRight: theme => theme.spacing(2) }}>
                        <GoogleLoginButton onClick={submitGoogle} {...socialButtonConfig} text={translate('ra.auth.social_login_button.google')} />
                    </Grid>
                )
            }
            {
                (allowedSocials.find(x => x === ProviderType.Microsoft) && !loading && (!loadingType || loadingType === ProviderType.Microsoft)) && (
                    <Grid item xs={12} sx={{ paddingLeft: theme => theme.spacing(2), paddingRight: theme => theme.spacing(2) }}>
                        <MicrosoftLoginButton onClick={submitMicrosoft} {...socialButtonConfig} text={translate('ra.auth.social_login_button.microsoft')} />
                    </Grid>
                )
            }
        </>
    )
};

SocialLogin.propTypes = {
    redirectTo: PropTypes.string,
};

export default SocialLogin;
