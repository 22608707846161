import { Grid, Divider, Typography } from '@mui/material';
import { Utils } from 'admin/core';
import { Invoice } from 'admin/types';

import { useTranslate } from 'react-admin';

var formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
});

const InvoiceSummaryRow = ({ text, value }) => (
    <Grid container justifyContent="center" >
        <Grid item xs={12} sm={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <Typography variant="subtitle2" gutterBottom>
                        {text}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} container justifyContent="flex-end">
                    <Typography variant="subtitle2" gutterBottom>
                        {formatter.format(parseFloat(value))}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)

const InvoiceSummaryRowDivider = () => (
    <Grid container justifyContent="center" >
        <Grid item xs={12} sm={2}>
            <Divider flexItem />
        </Grid>
    </Grid>
)

interface InvoiceSummaryFieldProps {
    invoice: Invoice;
}

const InvoiceSummaryField = (props: InvoiceSummaryFieldProps) => {
    const {
        invoice,
    } = props;

    const translate = useTranslate();

    if (!invoice) return null;

    const invoiceLines = invoice.invoiceLines?.filter(x => !Utils.IsEmpty(x)) || [];

    if (!invoiceLines || invoiceLines.length === 0) return null;

    const amount = invoiceLines.reduce((a, b) => +a + +(b.amount || 0), 0);
    const vatAmount = invoiceLines.reduce((a, b) => +a + +(b.vatAmount || 0), 0);
    const stampAmount = invoice.stampAmount && invoice.stampAmount > 0 ? invoice.stampAmount : 0;

    const totAmount = amount + vatAmount + stampAmount;
    const vatAmounts: any[] = [];

    const vatRateGroups = Utils.GroupBy(invoiceLines, "vatRate");

    Utils.LoopObject(vatRateGroups, (vatRateGroup, key) => {
        if (parseFloat(key) > 0) {
            vatAmounts.push({
                vatRate: parseFloat(key),
                amount: parseFloat(vatRateGroup.reduce((a, b) => +a + +b.amount, 0)),
                vatAmount: parseFloat(vatRateGroup.reduce((a, b) => +a + +b.vatAmount, 0))
            })
        }
    })

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Divider flexItem textAlign='center'>
                    <Typography variant="button">
                        {translate(`invoicelines.others.title`)}
                    </Typography>
                </Divider>
            </Grid>

            <Grid item xs={12}>
                <InvoiceSummaryRow text={translate('invoicelines.others.net')} value={amount.toFixed(2)} />
            </Grid>

            {
                vatAmounts.map((v, key) => {
                    const vRate = formatter.format(v.vatRate.toFixed(2));
                    const vAmount = formatter.format(v.amount.toFixed(2));

                    return (
                        <Grid item xs={12}>
                            <InvoiceSummaryRow text={translate(`invoicelines.others.vat`, { vatRate: vRate, vatAmount: vAmount })} value={vatAmount.toFixed(2)} />
                        </Grid>
                    )
                })
            }

            <Grid item xs={12}>
                <InvoiceSummaryRow text={translate('invoicelines.others.totalVat')} value={vatAmount.toFixed(2)} />
            </Grid>

            {
                stampAmount > 0 && (
                    <Grid item xs={12}>
                        <InvoiceSummaryRow text={translate('invoicelines.others.stamp')} value={stampAmount.toFixed(2)} />
                    </Grid>
                )
            }

            <Grid item xs={12}>
                <InvoiceSummaryRowDivider />
            </Grid>

            <Grid item xs={12}>
                <InvoiceSummaryRow text={translate('invoicelines.others.totalInvoice')} value={totAmount.toFixed(2)} />
            </Grid>

        </Grid>
    );
}

export default InvoiceSummaryField;