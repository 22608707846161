import GoogleMapReact, { MapOptions, Maps } from 'google-map-react';

import {
    Box, SxProps, Theme,
} from '@mui/material';

import { Utils } from 'admin/core';
import { useState } from 'react';
import AutoCompleteGMaps from './AutoCompleteGMaps';
import { Coords } from 'google-map-react';
import Marker, { MarkerProps } from './Marker';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = Utils.GetENV('GOOGLE_MAPS_API_KEY');

function createMapOptions(maps: Maps) {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"

    let mapOptions: MapOptions = {
        disableDefaultUI: true,
        zoomControl: false,
        mapTypeControl: false,
        mapTypeId: "roadmap",
        mapTypeControlOptions: {
            //mapTypeIds: ["satellite", "roadmap"],
            style: maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: maps.ControlPosition.TOP_LEFT
        },
        fullscreenControlOptions: {
            position: maps.ControlPosition.TOP_RIGHT,
        }
    };

    return mapOptions;
}

export interface GMapsProps {
    onApiHasLoaded?: (map: any, maps: any) => void;
    sx?: SxProps<Theme>;
    height?: any;
    width?: any;
    defaultCenter?: Coords | undefined;
    defaultZoom?: number | undefined;
    hasAutoComplete?: boolean;
    onSelectedPlace?: (place: google.maps.places.PlaceResult | null) => void;
    markers?: MarkerProps[] | null;
}

const GMaps = (props: GMapsProps) => {
    const {
        onApiHasLoaded = () => { },
        sx,
        defaultCenter,
        defaultZoom,
        hasAutoComplete = false,
        onSelectedPlace = () => { },
        markers = []
    } = props;

    const [mapApiLoaded, setMapApiLoaded] = useState(false);
    const [mapInstance, setMapInstance] = useState(null);
    const [mapApi, setMapApi] = useState(null);

    const apiHasLoaded = (map, maps) => {
        setMapInstance(map);
        setMapApi(maps);
        setMapApiLoaded(true);
        onApiHasLoaded(map, maps);
    };

    const selectedPlace = (place: google.maps.places.PlaceResult | null) => {
        onSelectedPlace(place);
    };

    let finalSx = {
        height: '100%',
        width: '100%',
        ...sx
    };

    if (hasAutoComplete) {
        finalSx.height = 'calc(100% - 55px)';
    }

    let finalCenter: Coords = defaultCenter || {} as Coords;

    if (!finalCenter?.lat)
        finalCenter.lat = 45.0734887;

    if (!finalCenter?.lng)
        finalCenter.lng = 7.6756066;

    let finalZoom: number = defaultZoom || 12;

    return (
        <Box sx={finalSx}>
            {
                hasAutoComplete && mapApiLoaded && <AutoCompleteGMaps mapInstance={mapInstance} mapApi={mapApi} onSelectedPlace={selectedPlace} />
            }
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: GOOGLE_MAPS_API_KEY, libraries: ['places', 'geometry']
                }}
                defaultCenter={finalCenter}
                defaultZoom={finalZoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
                options={createMapOptions}
            >
                {
                    markers?.map((marker, index) =>
                        <Marker
                            key={index}
                            text={marker.text}
                            lat={marker.lat}
                            lng={marker.lng}
                        />)
                }
            </GoogleMapReact>
        </Box>
    );
};

export default GMaps;
