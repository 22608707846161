import { ComponentType, ReactElement } from 'react';

export * from './skills';

export type Record = {
    id: string;
    name: string;
}

export enum AppStatusType {
    opened,
    closed,
    minimized,
}

export type AppInfo = {
    order?: number;
    name: string;
    iconUrl: string,
    label: string,
    component?: ComponentType<any> | ReactElement,
    toLink?: string,
    status?: AppStatusType,
    active?: boolean,
}