export default {
    name: 'vatrates',
    resources: {
        name: 'Vat Rate |||| Vat Rates',
        forcedCaseName: 'Vat Rate |||| Vat Rates',
        fields: {
            label: 'Label',
            code: 'Code',
            value: 'Value',
            order: 'Order',
            updatedAt: 'Updated At',
        },
    },
    global: {
        menu: {
            name: 'Vat Rates',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Vat Rate "%{title}"',
        },
        action: {
        },
    },
};