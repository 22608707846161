export default {
    name: 'invoices',
    resources: {
        name: 'Fattura |||| Fatture',
        forcedCaseName: 'Fattura |||| Fatture',
        fields: {
            number: 'Numero',
            emissionDate: 'Data Fattura',
            fiscalYear: 'Anno Fiscale',
            amount: 'Importo Totale',
            companyId: 'Cliente',
            invoiceTypeId: 'Tipo Fattura',
            paymentTypeId: 'Tipo Pagamento',
            bankAccount: 'IBAN',
            stampAmount: 'Importo Bollo',
            otherDetails: 'Note',
            updatedAt: 'Aggiornato il',
            company: 'Cliente',
            invoiceType: 'Tipo Fattura',
            paymentType: 'Tipo Pagamento',
            invoiceLines: 'Righe Fattura',
            invoiceSubmissions: 'Invio della fattura',
        },
    },
    global: {
        menu: {
            name: 'Fatture',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Fattura "%{title}"',
        },
        action: {
            copyBankAccount: 'Copia IBAN',
            pdf: {
                label: 'Genera PDF',
                processing: 'Creazione fattura in corso',
                failed: 'Documento non generato o danneggiato'
            },
            xml: {
                label: 'Invia Fattura Elettronica',
                confirm: 'Conferma invio',
                confirmContent: 'Confermi l\'invio della Fattura Elettronica?',
                confirmPending: 'Conferma ricezione',
                confirmPendingContent: 'Confermi la ricezione della Fattura Elettronica?',
                processing: 'Invio Fattura Elettronica in corso',
                processingPending: 'Conferma ricezione in corso',
                success: 'Invio Fattura Elettronica completato',
                failed: 'Invio Fattura Elettronica fallito'
            },
            sendxml: {
                success: 'Fattura Elettronica inviata',
                pending: 'Fattura Elettronica in elaborazione',
                failed: 'Invia Fattura Elettronica',
                null: 'Invia Fattura Elettronica'
            }
        },
    },
};