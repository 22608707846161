import { ReactElement, } from 'react';

import { SkillType, skills } from 'publicapp/types';

import {
    Typography,
} from '@mui/material';

import DevJobIcon from '@mui/icons-material/Work';
import TeacherJobIcon from '@mui/icons-material/School';

import { Utils } from 'admin/core';

export interface ExperienceProps {
    id: string;
    icon: ReactElement;
    dateFrom: string;
    dateTo?: string;
    location: string;
    role: string;
    company: string;
    skills?: SkillType[];
    activities?: string[];
    preComponent?: ReactElement;
    postComponent?: ReactElement;
    remote?: boolean;
}

export const experiences: ExperienceProps[] = [
    {
        id: Utils.UUIDv4(),
        icon: <DevJobIcon />,
        dateFrom: '01/10/2021',
        dateTo: '31/07/2023',
        location: 'Milano, Italia',
        role: 'CONSULENTE INFORMATICO / ANALISTA PROGRAMMATORE',
        company: 'ITUNA SRL',
        remote: true,
        skills: [
            skills.dotNET.versions.dotNET_7,
            skills.Blazor.versions.Blazor_WASM,
            skills.RadzenBlazor,
            skills.EFCore.versions.EFCore_7,
            skills.CQRS,
            skills.OData,
            skills.WebApi,
            skills.AzureFunctions,
            skills.AzureEventGrid,
            skills.AzureEventGrid,
            skills.AzureBlobStorage,
        ],
        activities: [
            'Gestione di progetti per clienti',
            'Gestione di Azure',
            'Sviluppo FE',
            'Sviluppo BE',
            'Sviluppo DB',
        ]
    },
    {
        id: Utils.UUIDv4(),
        icon: <TeacherJobIcon />,
        dateFrom: '02/2023',
        dateTo: '03/2023',
        location: 'Torino, Italia',
        role: 'DOCENTE NELLA FORMAZIONE PROFESSIONALE',
        company: 'KINETIKON SRL',
        skills: [
            skills.VisualStudio.versions.VisualStudio_2022,
            skills.EFCore,
            skills.LINQ,
            skills.CSharp,
        ],
        activities: [
            'Direttive e Debugging',
            'Polimorfismo',
            'Interfacce in C#',
            'Overloading, Reflection e attributi',
            'Delegati',
            'LINQ Extension Methods',
            'Sintassi di query e sintassi di metodi in LINQ (C#)',
            'Entity framework LINQ include',
            'Serializzazione e Deserializzazione di classi'
        ]
    },
    {
        id: Utils.UUIDv4(),
        icon: <TeacherJobIcon />,
        dateFrom: '01/2023',
        dateTo: '03/2023',
        location: 'Torino, Italia',
        role: 'DOCENTE NELLA FORMAZIONE PROFESSIONALE',
        company: 'FONDAZIONE ITS AEROSPAZIO/MECCATRONICA',
        skills: [
            skills.VisualStudio.versions.VisualStudio_2022,
            skills.CSharp,
        ],
        activities: [
            'Presentazione corso. Introduzione all\'IDE Visual Studio e all\'architettura .NET',
            'Dichiarazioni, variabili. Primo programma “Hello World” e primo sguardo al codice e debugging',
            'Tipi di dati, array, conversioni.',
            'Operatori e istruzioni',
            'Oggetti, classi e interfacce',
            'Classi fondamentali',
            'Gestione eccezioni, delegati ed eventi',
            'Generics'
        ],
        preComponent: (
            <>
                <Typography variant="body1">
                    <strong>Corso:</strong> MAAT02
                </Typography>

                <Typography variant="body1">
                    <strong>Modulo:</strong> PROGRAMMAZIONE A OGGETTI IN C#
                </Typography>
            </>
        )
    },
    {
        id: Utils.UUIDv4(),
        icon: <DevJobIcon />,
        dateFrom: '01/2016',
        dateTo: '31/07/2022',
        location: 'Torino, Italia',
        role: 'CONSULENTE INFORMATICO / ANALISTA PROGRAMMATORE',
        company: 'CWS SRL',
        skills: [
            skills.dotNET_Core,
            skills.dotNET_Framework,
            skills.dotNET.versions.dotNET_5,
            skills.CSharp,
            skills.Ionic,
            skills.Tizen,
            skills.EFCore,
            skills.EF,
            skills.ASPNET,
            skills.ASPNETCore,
            skills.JAVA,
            skills.VBA,
            skills.PHP,
            skills.Laravel5,
            skills.MSSQL,
            skills.Kofax,
            skills.TizenStudio,
            skills.VisualStudio.versions.VisualStudio_2022,
        ],
        activities: [
            'Gestione completa di software interno e per esterni',
            'Gestione completa di IIS',
            'Progetti con prodotto Kofax',
            'Web Application',
            'Console Application',
            'Windows Service',
            'App Tizen'
        ]
    },
    {
        id: Utils.UUIDv4(),
        icon: <TeacherJobIcon />,
        dateFrom: '18/11/2021',
        dateTo: '02/12/2021',
        location: 'Torino, Italia',
        role: 'DOCENTE NELLA FORMAZIONE PROFESSIONALE',
        company: 'KINETIKON SRL',
        skills: [
            skills.VisualStudio.versions.VisualStudio_2022,
            skills.EFCore,
            skills.LINQ,
            skills.CSharp,
        ],
        activities: [
            'Direttive e Debugging',
            'Polimorfismo',
            'Interfacce in C#',
            'Overloading, Reflection e attributi',
            'Delegati',
            'LINQ Extension Methods',
            'Sintassi di query e sintassi di metodi in LINQ (C#)',
            'Entity framework LINQ include',
            'Serializzazione e Deserializzazione di classi'
        ]
    },
    {
        id: Utils.UUIDv4(),
        icon: <TeacherJobIcon />,
        dateFrom: '11/2015',
        dateTo: '06/2019',
        location: 'Torino, Italia',
        role: 'DOCENTE NELLA FORMAZIONE PROFESSIONALE',
        company: 'ASSOCAM SCUOLA CAMERANA - AGENZIA FORMATIVA DELL\' AMMA, UNIONE INDUSTRIALE E CAMERA DI COMMERCIO',
        skills: [
            skills.VisualStudio.versions.VisualStudio_2017,
            skills.HTML5,
            skills.CSS_CSS3,
            skills.Bootstrap.versions.Bootstrap_4,
            skills.JQuery.versions.JQuery_Core,
            skills.ASPNET,
            skills.CSharp,
            skills.MSSQL,
        ],
        activities: [
            'Insegnamento delle nozioni base di FE, BE e Database in ambiente Microsoft',
            'Supporto alla classe nello sviluppo in team di un progetto da presentare al termine del corso'
        ]
    },
    {
        id: Utils.UUIDv4(),
        icon: <DevJobIcon />,
        dateFrom: '06/2014',
        dateTo: '12/2015',
        location: 'Moncalieri, Italia',
        role: 'CONSULENTE INFORMATICO / ANALISTA PROGRAMMATORE',
        company: 'INTESA SAN PAOLO per conto di CONSOFT SISTEMI SPA',
        skills: [
            skills.WindowsServer,
            skills.dotNET_Framework,
            skills.ASPNET,
            skills.ASP,
            skills.PowerShell,
            skills.IIS,
            skills.SQLite,
            skills.MSSQL,
            skills.VisualStudio.versions.VisualStudio_2013,
        ],
        activities: [
            'Analisi di log (W3C ed EventViewer) e risoluzione di problematiche applicative in ambiente .NET (IIS 7.5/8) e ASP (IIS 6)',
            'Creazione e configurazione di siti e applicazioni web in ambiente .NET (Framework 2.0/3.5/4.0/4.5)',
            'Gestione e configurazione di share di rete e relative permission',
            'Creazione e configurazione di Farm ARR e di URLRewrite in base alle necessità applicative',
            'Attività di sviluppo applicazione web, per il gruppo di lavoro, per semplificare le seguenti attività:',
            '- monitoraggio delle applicazioni web in tempo reale su n server di backend',
            '- monitoraggio delle applicazioni web in tempo reale su n server di ARR',
            '- lettura e conversione in tabelle di log W3C in tempo reale di tutte le applicazioni web attive',
            '- catalogazione di tutte le applicazioni web',
            '- gestione delle principali attività del gruppo con possibilità di prenotare le attività da gestire nella giornata',
            'Sviluppo di applicazioni client collegate a schedulazioni giornaliere per la popolazione del db dell\'applicazione web',
            'Sviluppo di scripts in cmd e/o PowerShell per velocizzare l\'esecuzione di varie attività'
        ]
    },
    {
        id: Utils.UUIDv4(),
        icon: <DevJobIcon />,
        dateFrom: '09/2013',
        dateTo: '06/2014',
        location: 'Torino, Italia',
        role: 'RESPONSABILE TECNICO / ANALISTA PROGRAMMATORE',
        company: 'SYSTHEMA SRL',
        skills: [
            skills.WindowsServer,
            skills.dotNET_Framework,
            skills.ASPNET,
            skills.ASP,
            skills.Bootstrap.versions.Bootstrap_3,
            skills.JQuery,
            skills.EXT_JS,
            skills.IIS,
            skills.SQLite,
            skills.MSSQL,
            skills.MySQL,
            skills.VisualStudio.versions.VisualStudio_2010,
        ],
        activities: [
            'Sviluppo applicazioni web e client in ambiente .NET',
            'Creazione di classi e moduli per la gestione di metodi mirati a rendere più dinamica lo sviluppo del progetto',
            'Analisi della struttura dell\'applicazione e del relativo database',
            'Operazioni CRUD in ambiente MSSQL, MYSQL e SQLITE',
            'Comunicazione e gestione diretta del progetto con il cliente',
        ]
    },
    {
        id: Utils.UUIDv4(),
        icon: <DevJobIcon />,
        dateFrom: '07/2012',
        dateTo: '09/2013',
        location: 'Torino, Italia',
        role: 'ANALISTA PROGRAMMATORE',
        company: 'SET SISTEMI E TECNOLOGIE',
        skills: [
            skills.WindowsServer,
            skills.dotNET_Framework,
            skills.ASPNET,
            skills.ASP,
            skills.JQuery,
            skills.EXT_JS,
            skills.IIS,
            skills.SQLite,
            skills.MSSQL,
            skills.MySQL,
            skills.VisualStudio.versions.VisualStudio_2010,
        ],
        activities: [
            'Sviluppo applicazioni web e client in ambiente .NET',
            'Creazione di classi e moduli per la gestione di metodi mirati a rendere più dinamica lo sviluppo del progetto',
            'Analisi della struttura dell\'applicazione e del relativo database',
            'Operazioni CRUD in ambiente MSSQL, MYSQL e SQLITE',
            'Comunicazione e gestione diretta del progetto con il cliente',
        ]
    },
    {
        id: Utils.UUIDv4(),
        icon: <DevJobIcon />,
        dateFrom: '05/2012',
        dateTo: '06/2012',
        location: 'Torino, Italia',
        role: 'PROGRAMMATORE JUNIOR - STAGE',
        company: 'Bolaffi',
        skills: [
            skills.HTML5,
            skills.CSS_CSS3,
            skills.JQuery,
            skills.Javascript,
            skills.Dreamweaver,
        ],
        activities: [
            'Aggiornamento siti',
            'Aggiornamento applicazioni interne',
        ]
    },
]